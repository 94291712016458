import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../reducers';
import { closeModalAction } from '../../actions/modal/modalAction';
import { Form, Button } from 'react-bootstrap';
import InputField from '../textField/InputField';
import PopUp from '.';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import CustomToast from '../CustomToast';
import Loader from '../Loader';
import { addPropertyAction } from '../../actions/propertyList/propertyAction';

const AddOrUpdateProperty = () => {
  const [toast, setToast] = useState({ type: '', message: '' });
  const [error, setError] = useState('');
  const modalType = useSelector((state: RootState) => state.modal.modalType);

  const [newProperty, setNewProperty] = useState({
    name: '',
    websiteUrl: '',
  });
  const dispatch = useDispatch();

  const selectedAccount = useSelector(
    (state: RootState) => state.accountList.selectedAccount
  );
  const { property, selectedProperty } = useSelector(
    (state: RootState) => state.properties
  );

  useEffect(() => {
    if (modalType === 'editProperty') {
      const selectedPropertyDetails = property.find(
        (prop: any) => prop.id === selectedProperty.value
      );
      const { name, websiteUrl } = selectedPropertyDetails;
      setNewProperty({ name, websiteUrl });
    }
    // eslint-disable-next-line
  }, [modalType]);

  const [addProperty, { loading: loadingAddProperty }] = useMutation(
    ADD_PROPERTY_MUTATION,
    {
      variables: {
        accountId: selectedAccount.value,
        websiteUrl: newProperty.websiteUrl,
        name: newProperty.name,
      },
      onError(err) {
        err.graphQLErrors[0] && setError(err.graphQLErrors[0].message);
        err.graphQLErrors[0] &&
          setToast({ type: 'fail', message: err.graphQLErrors[0].message });
      },
      update(_, result) {
        console.log(result.data.addProperty);
        dispatch(addPropertyAction(result.data.addProperty));
        dispatch(closeModalAction());
        setToast({ type: 'success', message: 'Property Added' });
        setNewProperty({ name: '', websiteUrl: '' });
      },
    }
  );

  const [updateProperty, { loading: loadingUpdateProperty }] = useMutation(
    UPDATE_PROPERTY_MUTATION,
    {
      variables: {
        accountId: selectedAccount.value,
        propertyId: selectedProperty,
        websiteUrl: newProperty.websiteUrl,
        name: newProperty.name,
      },
      onError(err) {
        err.graphQLErrors[0] && setError(err.graphQLErrors[0].message);
        err.graphQLErrors[0] &&
          setToast({ type: 'fail', message: err.graphQLErrors[0].message });
      },
      update() {
        dispatch(closeModalAction());
        setToast({ type: 'success', message: 'Property Updated' });
        setNewProperty({ name: '', websiteUrl: '' });
      },
    }
  );

  // view modal submit handler
  const submitHandler = (e: any) => {
    e.preventDefault();
    // console.log('Submited Data: ', property);
    if (modalType === 'addProperty') {
      addProperty();
    }
    if (modalType === 'editProperty') {
      updateProperty();
    }
  };

  const changeHandler = (e: any) => {
    setNewProperty({ ...newProperty, [e.target.name]: e.target.value });
  };

  const closeHandler = () => {
    dispatch(closeModalAction());
    setNewProperty({ name: '', websiteUrl: '' });
  };

  let addOrUpdateProperty;
  const { name, websiteUrl } = newProperty;
  if (modalType === 'addProperty' || modalType === 'editProperty') {
    addOrUpdateProperty = (
      <PopUp
        modalTitle={
          modalType === 'addProperty' ? 'Add Property' : 'Update Property'
        }
        closeHandler={closeHandler}>
        <Form onSubmit={submitHandler}>
          <InputField
            type='text'
            name='name'
            placeholder='Enter Property name'
            value={name}
            onChange={changeHandler}
            label='Property Name'
          />

          <InputField
            type='text'
            name='websiteUrl'
            placeholder='Enter Website URL'
            value={websiteUrl}
            onChange={changeHandler}
            label='Website URL'
          />
          <div className='right-modal-bottom-btn'>
            <Button variant='primary' className='back-btn' onClick={closeHandler}>
              Back
            </Button>
            <Button variant='primary' type='submit'>
              {modalType === 'addProperty' ? 'Add Property' : 'Update Property'}
            </Button>
          </div>
        </Form>
      </PopUp>
    );
  }
  return (
    <>
      {loadingAddProperty || (loadingUpdateProperty && <Loader />)}
      <CustomToast
        header={toast.type}
        message={toast.message}
        setToast={setToast}
      />
      {addOrUpdateProperty}
    </>
  );
};

export default AddOrUpdateProperty;

const ADD_PROPERTY_MUTATION = gql`
  mutation addProperty($accountId: ID!, $name: String, $websiteUrl: String) {
    addProperty(accountId: $accountId, name: $name, websiteUrl: $websiteUrl) {
      id
      name
      accountId
      internalWebPropertyId
      websiteUrl
      profileCount
      level
      defaultProfileId
    }
  }
`;

const UPDATE_PROPERTY_MUTATION = gql`
  mutation updateProperty(
    $accountId: ID!
    $name: String
    $websiteUrl: String
    $propertyId: String!
  ) {
    updateProperty(
      accountId: $accountId
      name: $name
      websiteUrl: $websiteUrl
      propertyId: $propertyId
    ) {
      id
      name
      accountId
      internalWebPropertyId
      websiteUrl
      profileCount
      level
      defaultProfileId
    }
  }
`;
