import gql from 'graphql-tag';

export const FETCH_VIEWS_QUERY = gql`
  query views($accountId: ID!, $propertyId: String!) {
    views(accountId: $accountId, propertyId: $propertyId) {
      id
      name
      accountId
      internalWebPropertyId
      websiteUrl
      timezone
      currency
      eCommerceTracking
      botFilteringEnabled
      type
    }
  }
`;

export const ADD_VIEW_MUTATION = gql`
  mutation addView(
    $accountId: ID!
    $propertyId: String!
    $name: String
    $type: String
    $eCommerceTracking: Boolean
    $timezone: String
    $currency: String
    $botFilteringEnabled: Boolean
    $websiteUrl: String
  ) {
    addView(
      accountId: $accountId
      propertyId: $propertyId
      name: $name
      type: $type
      eCommerceTracking: $eCommerceTracking
      timezone: $timezone
      currency: $currency
      botFilteringEnabled: $botFilteringEnabled
      websiteUrl: $websiteUrl
    ) {
      id
      name
      accountId
      internalWebPropertyId
      websiteUrl
      timezone
      currency
      eCommerceTracking
      botFilteringEnabled
      type
    }
  }
`;

export const UPDATE_VIEW_MUTATION = gql`
  mutation updateView(
    $accountId: ID!
    $propertyId: String!
    $viewId: ID!
    $name: String
    $type: String
    $eCommerceTracking: Boolean
    $timezone: String
    $currency: String
    $botFilteringEnabled: Boolean
    $websiteUrl: String
  ) {
    updateView(
      accountId: $accountId
      propertyId: $propertyId
      viewId: $viewId
      name: $name
      eCommerceTracking: $eCommerceTracking
      timezone: $timezone
      currency: $currency
      type: $type
      botFilteringEnabled: $botFilteringEnabled
      websiteUrl: $websiteUrl
    ) {
      id
      name
      accountId
      internalWebPropertyId
      websiteUrl
      timezone
      currency
      eCommerceTracking
      botFilteringEnabled
      type
    }
  }
`;
export const DELETE_VIEW_MUTATION = gql`
  mutation deleteView($accountId: ID!, $propertyId: String!, $viewId: ID!) {
    deleteView(accountId: $accountId, propertyId: $propertyId, viewId: $viewId)
  }
`;
export const SET_DEFAULT_VIEW_MUTATION = gql`
  mutation updateProperty(
    $accountId: ID!
    $propertyId: String!
    $defaultProfileId: ID!
  ) {
    updateProperty(
      accountId: $accountId
      propertyId: $propertyId
      defaultProfileId: $defaultProfileId
    ) {
      id
      name
      accountId
      internalWebPropertyId
      websiteUrl
      profileCount
      level
      defaultProfileId
    }
  }
`;

export const FETCH_VIEW_FILTERS_QUERY = gql`
  query viewFilters($accountId: ID!, $propertyId: String!, $viewId: ID!) {
    viewFilters(
      accountId: $accountId
      propertyId: $propertyId
      viewId: $viewId
    ) {
      filterRef {
        id
        name
      }
    }
  }
`;
export const FETCH_CATEGORIZE_FILTERS_QUERY = gql`
  query categorizeFilters($accountId: ID!, $propertyId: String!, $viewId: ID!) {
    categorizeFilters(
      accountId: $accountId
      propertyId: $propertyId
      viewId: $viewId
    ) {
      active {
        id
        name
        configuration {
          description
          configuration {
            type
            values
          }
        }
      }
      inactive {
        id
        name
        configuration {
          description
          configuration {
            type
            values
          }
        }
      }
    }
  }
`;
export const FETCH_CURRENT_RECOMMENDED_FILTERS_QUERY = gql`
  query currentRecommended(
    $accountId: ID!
    $propertyId: String!
    $viewId: ID!
  ) {
    currentRecommended(
      accountId: $accountId
      propertyId: $propertyId
      viewId: $viewId
    ) {
      recommended {
        name
        description
        type
        field
        added
        relatedTo
      }
    }
  }
`;
export const ADD_VIEW_FILTER_MUTATION = gql`
  mutation addViewFilter(
    $accountId: ID!
    $propertyId: String!
    $viewId: ID!
    $filterId: ID!
  ) {
    addViewFilter(
      accountId: $accountId
      propertyId: $propertyId
      viewId: $viewId
      filterId: $filterId
    ) {
      id
      filterRef {
        id
        name
      }
    }
  }
`;

export const DELETE_VIEW_FILTER_MUTATION = gql`
  mutation deleteViewFilter(
    $accountId: ID!
    $propertyId: String!
    $viewId: ID!
    $profileFilterLinkId: String!
  ) {
    deleteViewFilter(
      accountId: $accountId
      propertyId: $propertyId
      viewId: $viewId
      profileFilterLinkId: $profileFilterLinkId
    )
  }
`;
