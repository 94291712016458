import gql from 'graphql-tag';

export const FETCH_PROPERTIES_QUERY = gql`
  query properties($accountId: ID!) {
    properties(accountId: $accountId) {
      id
      name
      accountId
      internalWebPropertyId
      websiteUrl
      profileCount
      level
      defaultProfileId
    }
  }
`;

export const PROPERTY_RECOMMENDED_FILTERS_QUERY = gql`
  query propertyRecommendedFilters($accountId: ID!, $propertyId: String!) {
    propertyRecommendedFilters(accountId: $accountId, propertyId: $propertyId) {
      recommended {
        name
        description
        type
        field
        added
        appliedToAll
        relatedTo
      }
    }
  }
`;
