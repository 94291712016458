import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import './style.css';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

interface Props {
  label: string | undefined;
  name: string;
  changeHandler: any;
  options: any;
  values: any;
}

const CustomSelect: FC<Props> = ({
  label,
  name,
  changeHandler,
  options,
  values,
}) => {
  const modalType = useSelector((state: RootState) => state.modal.modalType);
  if (modalType !== 'addView' && !values[name]) return null;
  return (
    <Form.Group>
      <Form.Label className='label'>{label}</Form.Label>
      <Select
        name={name}
        options={options}
        defaultValue={values[name]}
        onChange={changeHandler}
        styles={{
          // Fixes the overlapping problem of the component
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          option: (prev) => ({
            ...prev,
            ':active': {
              ...prev[':active'],
              backgroundColor: 'var(--analytics-theme-color);',
            },
          }),
        }}
      />
    </Form.Group>
  );
};

export default CustomSelect;
