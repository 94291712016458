import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { RootState } from '../../reducers';
import {
  closeModalAction,
  openModalAction,
} from '../../actions/modal/modalAction';
import { Form } from 'react-bootstrap';
import PopUp from '.';
import filterGridSolid from '@iconify/icons-clarity/filter-grid-solid';
import bxsEditAlt from '@iconify/icons-bx/bxs-edit-alt';
import plusIcon from '@iconify/icons-entypo/plus';
import infoCircle from '@iconify/icons-fa-solid/info-circle';
import settingsSolid from '@iconify/icons-clarity/settings-solid';
import Icon from '@iconify/react';
import { setSelectedFilterAction } from '../../actions/filterList/filterListAction';
import {
  ADD_VIEW_FILTER_MUTATION,
  DELETE_VIEW_FILTER_MUTATION,
} from '../../graphql/view';
import { useMutation } from '@apollo/react-hooks';
import {
  disableFilterAction,
  enableFilterAction,
} from '../../actions/viewsList/viewsListAction';
import Loader from '../Loader';
import { checkPrefix } from '../../utils/filterPrefixChecker';

interface Props {
  filter: any;
}
const FilterDetailsModal: FC<Props> = ({ filter }): JSX.Element | null => {
  console.log(filter);
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(filter.isActive);
  const [toast, setToast] = useState({ type: '', message: '' });

  const modalType = useSelector(
    (state: RootState) => state.modal.modalType,
    shallowEqual
  );
  const { selectedAccount } = useSelector(
    (state: RootState) => state.accountList,
    shallowEqual
  );
  const { selectedProperty } = useSelector(
    (state: RootState) => state.properties,
    shallowEqual
  );
  const { selectedView } = useSelector(
    (state: RootState) => state.viewsList,
    shallowEqual
  );

  useEffect(() => {
    setIsActive(filter.isActive);
  }, [filter.name]);

  const submitHandler = (e: any): void => {
    e.preventDefault();
  };

  const editFilterHandler = (): void => {
    const { name } = checkPrefix(filter.name, selectedProperty.label);
    // const recommendedFilters = client.readQuery({
    //   query: gql`
    //     query recommendedFilters {
    //       recommendedFilters {
    //         name
    //         description
    //         type
    //         field
    //         added
    //         relatedTo
    //         appliedToAll
    //       }
    //     }
    //   `,
    // });
    // console.log(recommendedFilters);

    dispatch(
      setSelectedFilterAction({
        ...filter,
        label: name,
        value: name,
      })
    );
    dispatch(openModalAction('editFilter'));
  };

  const closeHandler = (): void => {
    dispatch(closeModalAction());
  };
  const switchHandler = () => {
    if (isActive) {
      deleteViewFilterHandler(filter);
    } else {
      addViewFilterHandler(filter);
    }
  };

  const [
    addViewFilterMutation,
    { loading: addViewFiltersLoading },
  ] = useMutation(ADD_VIEW_FILTER_MUTATION, {
    onError: (err) => {
      err.graphQLErrors[0] &&
        setToast({ type: 'fail', message: err.graphQLErrors[0].message });
    },
  });

  const [
    deleteViewFilterMutation,
    { loading: deleteViewFiltersLoading },
  ] = useMutation(DELETE_VIEW_FILTER_MUTATION, {
    onError: (err) => {
      err.graphQLErrors[0] &&
        setToast({ type: 'fail', message: err.graphQLErrors[0].message });
    },
  });

  const deleteViewFilterHandler = (filter: any): void => {
    deleteViewFilterMutation({
      variables: {
        accountId: selectedAccount.value,
        propertyId: selectedProperty.value,
        viewId: selectedView.value,
        profileFilterLinkId: `${selectedView.value}:${filter.id}`,
      },

      update: (_, result) => {
        setToast({
          type: 'success',
          message: 'View Disabled successfully',
        });
        setIsActive(!isActive);
        dispatch(disableFilterAction(filter));
      },
    });
  };

  const addViewFilterHandler = (filter: any) => {
    addViewFilterMutation({
      variables: {
        accountId: selectedAccount.value,
        propertyId: selectedProperty.value,
        viewId: selectedView.value,
        filterId: filter.id,
      },

      update: (_, result) => {
        setToast({
          type: 'success',
          message: 'Filter Enabled Successfully',
        });
        setIsActive(!isActive);
        dispatch(enableFilterAction(filter));
      },
    });
  };
  const addFilterHandler = (filter: any) => {
    filter.label = filter.name;
    filter.value = filter.name;
    dispatch(setSelectedFilterAction(filter));
    if (!selectedAccount)
      return setToast({ type: 'fail', message: 'Select An Account First' });
    dispatch(openModalAction('addFilter'));
  };

  if (modalType !== 'filterDetails') return null;
  const { name } = checkPrefix(filter.name, selectedProperty.label);
  return (
    <PopUp modalTitle='Filter Details' closeHandler={closeHandler}>
      {(addViewFiltersLoading || deleteViewFiltersLoading) && <Loader />}
      <Form onSubmit={submitHandler}>
        <div className='filter-detail-icon'>
          <div className='icon-wrapper'>
            <Icon icon={filterGridSolid} />
          </div>
          <div className='filter-detail'>
            <h6>Selected Filters</h6>
            <p>{name}</p>
          </div>
          {filter.showFilter !== 'Recommended' && (
            <div className='edit-icon-wrapper' onClick={editFilterHandler}>
              <Icon icon={bxsEditAlt} />
            </div>
          )}
          {filter.showFilter === 'Recommended' && (
            <div
              className='edit-icon-wrapper'
              onClick={() => addFilterHandler(filter)}
            >
              <Icon icon={plusIcon} />
            </div>
          )}
        </div>
        <div className='filter-informatin'>
          {filter.showFilter !== 'Recommended' &&
            selectedAccount &&
            selectedProperty &&
            selectedView && (
              <div className='switch-btn py-3'>
                <h3>Filter Status: {isActive ? 'Active' : 'Inactive'}</h3>
                <button
                  type='button'
                  className={`btn btn-toggle ${isActive ? 'active' : ''}`}
                  onClick={switchHandler}
                >
                  <div className='handle'></div>
                </button>
              </div>
            )}
          <h6 className='filter-label'>
            <Icon icon={infoCircle} />
            Filter Information
          </h6>

          <p className='my-2'>
            {filter.configuration && filter.configuration.description ? (
              filter.configuration.description
            ) : (
              <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Repudiandae officiis maxime odit, explicabo assumenda ad alias
                soluta, quod magni repellat rerum? Porro fugit architecto quidem
                corporis temporibus repellendus laborum alias?
              </span>
            )}
          </p>
          {filter.showFilter !== 'Recommended' && selectedView && (
            <>
              <h6 className='filter-label mt-4'>
                <Icon icon={settingsSolid} />
                Current Configuration
              </h6>
              <div className='correct-configuration'>
                <div className='confg'>
                  <span className='title'>Filter Type</span>
                  <span className='details'>
                    {filter.configuration &&
                      filter.configuration.configuration.type}
                  </span>
                  {name === 'Limit IP Traffic' && (
                    <span className='title'>Single IP</span>
                  )}
                  {name === 'Limit Hostnames(Domains)' && (
                    <span className='title'>Hostnames</span>
                  )}
                  {name === 'Limit Countries' && (
                    <span className='title'>Countries</span>
                  )}
                  {name === 'Exclude Crawler and Referral Spam' && (
                    <span className='title'>Source</span>
                  )}
                  {name === 'Limit Device Traffic' && (
                    <span className='title'>Devices</span>
                  )}
                  <span className='details'>
                    {filter.configuration &&
                      filter.configuration.configuration.values &&
                      filter.configuration.configuration.values.map(
                        (value: string) => (
                          <>
                            <span>{value}</span>
                            <span className='seperator'>, </span>
                          </>
                        )
                      )}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </Form>
    </PopUp>
  );
};

export default FilterDetailsModal;
