import React, { useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../reducers';
import { closeModalAction } from '../../actions/modal/modalAction';
import { Form, Button } from 'react-bootstrap';
import InputField from '../textField/InputField';
import PopUp from '.';
import CustomToast from '../CustomToast';
import CustomSelect from '../textField/CustomSelect';

interface Props {
  item: any;
}
const AuditFixerModal: FC<Props> = ({ item }) => {
  const [toast, setToast] = useState({ type: '', message: '' });
  const modalType = useSelector((state: RootState) => state.modal.modalType);

  const [values, setValues] = useState({});
  const dispatch = useDispatch();

  // view modal submit handler
  const submitHandler = (e: any) => {
    e.preventDefault();
    // console.log('Submited Data: ', property);
    console.log(values);
  };

  const changeHandler = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // string boolean to boolean type ie:'true' => true
  const parseBoolean = (value: string): boolean | string => {
    if (value === 'true' || value === 'false') {
      return JSON.parse(value);
    }
    return value;
  };

  const selectChangeHandler = (newValue: any, actionMeta: any) => {
    const parsedValue = parseBoolean(newValue.label);
    setValues({ ...values, [actionMeta.name]: parsedValue });
  };

  const closeHandler = () => {
    dispatch(closeModalAction());
    setValues({});
  };
  let CustomModal;

  if (item && modalType === item.auditItem) {
    CustomModal = (
      <PopUp modalTitle={modalType} closeHandler={closeHandler}>
        <Form onSubmit={submitHandler}>
          {item.fields &&
            item.fields.map((field: any) => {
              if (field.type === 'text') {
                return (
                  <InputField
                    type='text'
                    name={field.name}
                    placeholder={field.placeholder}
                    value={values[field.name]}
                    onChange={changeHandler}
                    label={modalType}
                  />
                );
              }
              if (field.type === 'select') {
                return (
                  <CustomSelect
                    label={field.label}
                    name={field.name}
                    options={field.options}
                    changeHandler={selectChangeHandler}
                    values={0}
                  />
                );
              }
              return null;
            })}

          <Button variant='primary' type='submit'>
            Submit
          </Button>
        </Form>
      </PopUp>
    );
  }
  return (
    <>
      <CustomToast
        header={toast.type}
        message={toast.message}
        setToast={setToast}
      />
      {CustomModal}
    </>
  );
};

export default AuditFixerModal;
