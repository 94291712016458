export const GET_FILTER_LIST = 'GET_FILTER_LIST';
export const SET_FILTER_LIST = 'SET_FILTER_LIST';
export const ADD_FILTER = 'ADD_FILTER';
export const ADD_FILTER_CHECKED_ITEM = 'ADD_FILTER_CHECKED_ITEM';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER';
export const SET_RECOMMENDED_FILTER = 'SET_RECOMMENDED_FILTER';

interface GetFilterListAction {
  type: typeof GET_FILTER_LIST;
  payload: any;
}

interface SetFilterList {
  type: typeof SET_FILTER_LIST;
  payload: any;
}
interface AddFilterAction {
  type: typeof ADD_FILTER;
  payload: any;
}

interface AddFilterChecked {
  type: typeof ADD_FILTER_CHECKED_ITEM;
  payload: string;
}

interface ClearFilter {
  type: typeof CLEAR_FILTER;
}
interface SetSelectedFilter {
  type: typeof SET_SELECTED_FILTER;
  payload: any;
}
interface SetRecommendedFilter {
  type: typeof SET_RECOMMENDED_FILTER;
  payload: any[];
}
export type AccountListActionTypes =
  | GetFilterListAction
  | SetFilterList
  | AddFilterAction
  | AddFilterChecked
  | ClearFilter
  | SetSelectedFilter
  | SetRecommendedFilter;
