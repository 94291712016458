import React, { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setResultType } from '../../../actions/audit/auditAction';
import { RootState } from '../../../reducers';
import HighChart from './HighChart';

const FailRate: FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const { resultType, failCounter } = useSelector(
    (state: RootState) => state.audit
  );

  return (
    <div className='failed-rate'>
      <div className='failed-chart-container'>
        <div
          className={`failed-chart ${resultType === 'all' && 'active'}`}
          onClick={() => dispatch(setResultType('all'))}>
          <HighChart fail={failCounter.all.fail} total={failCounter.all.total} />
          <span>All</span>
        </div>
        <div
          className={`failed-chart ${resultType === 'access' && 'active'}`}
          onClick={() => dispatch(setResultType('access'))}>
          <HighChart
            fail={failCounter.access.fail}
            total={failCounter.access.total}
          />
          <span>Access</span>
        </div>
        <div
          className={`failed-chart ${resultType === 'property' && 'active'}`}
          onClick={() => dispatch(setResultType('property'))}>
          <HighChart
            fail={failCounter.property.fail}
            total={failCounter.property.total}
          />
          <span>Property</span>
        </div>
        <div
          className={`failed-chart ${resultType === 'view' && 'active'}`}
          onClick={() => dispatch(setResultType('view'))}>
          <HighChart fail={failCounter.view.fail} total={failCounter.view.total} />
          <span>View</span>
        </div>
        <div
          className={`failed-chart ${resultType === 'filter' && 'active'}`}
          onClick={() => dispatch(setResultType('filter'))}>
          <HighChart
            fail={failCounter.filter.fail}
            total={failCounter.filter.total}
          />
          <span>Filter</span>
        </div>
        <div className='failed-chart' style={{ visibility: 'hidden' }}></div>
      </div>
    </div>
  );
};

export default FailRate;
