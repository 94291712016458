export const optionsFormatter = (options: string[]) => {
  let formattedOption: any = [];
  options.map((option) => {
    formattedOption.push({ label: option, value: option });
  });
  return formattedOption;
};

export const formatDate = (date: string) => {
  const ds = date.toString().split('');
  const year = ds[0] + ds[1] + ds[2] + ds[3];
  const month = ds[4] + ds[5];
  const day = ds[6] + ds[7];
  return `${year},${month},${day}`;
};
