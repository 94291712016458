import React, { FC } from 'react';
import { motion } from 'framer-motion';
import Upcoming from './Upcoming';

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const AnalyticsTrash: FC = (): JSX.Element => {
  return (
    <motion.div
      className='analytics-main'
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}>
      <Upcoming />
    </motion.div>
  );
};
export default AnalyticsTrash;
