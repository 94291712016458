import React, { FC, Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';

interface Props {
  state: any;
  setState: any;
  type: string;
  options: [{ label: string; value: string }];
}
const SelectWithCheckbox: FC<Props> = ({
  state,
  setState,
  type,
  options,
}): JSX.Element => {
  const handleCheck = (label: string) => {
    if (state.includes(label)) {
      return setState(state.filter((continent: string) => continent !== label));
    }
    return setState([...state, label]);
  };

  const formatOptionLabel = ({ value, label }: any) => (
    <div className='indi-option'>
      <div className='option-label' onClick={() => handleCheck(label)}>
        <input
          type='checkbox'
          style={{ marginRight: 10 }}
          checked={state.includes(label)}
          readOnly
        />{' '}
        <span className='options-mail-label'>{label}</span>
      </div>

      <Button onClick={() => setState(label)}>Only</Button>
    </div>
  );

  return (
    <div className={type.toLowerCase()}>
      <label htmlFor={type.toLowerCase()}>{type}</label>
      <Select
        value={{ label: type, value: type }}
        options={options}
        name={type.toLowerCase()}
        formatOptionLabel={formatOptionLabel}
        closeMenuOnSelect={false}
        classNamePrefix='selectors'
      />
    </div>
  );
};

export default SelectWithCheckbox;
