import {
  AuditActionTypes,
  START_AUDIT,
  RESULT_TYPE,
  AUDIT_RESULT,
  AUDIT_BASE,
  SET_FAIL_COUNT,
} from '../actions/audit/types';

interface AuditState {
  auditStarted: boolean;
  auditResults: any;
  resultType: string;
  auditBase: any;
  failCounter: any;
}

const initialState: any = {
  auditStarted: false,
  auditResults: [],
  resultType: 'all',
  auditBase: null,
  failCounter: null,
};

export default (state = initialState, action: AuditActionTypes) => {
  switch (action.type) {
    case START_AUDIT:
      return { ...state, auditStarted: action.payload };
    case RESULT_TYPE:
      return { ...state, resultType: action.payload };
    case AUDIT_RESULT:
      return { ...state, auditResults: action.payload };
    case AUDIT_BASE:
      return { ...state, auditBase: action.payload };
    case SET_FAIL_COUNT:
      return { ...state, failCounter: action.payload };
    default:
      return state;
  }
};
