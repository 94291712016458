import React, { FC } from 'react';
import { motion } from 'framer-motion';
import Upcoming from './Upcoming';
const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const SearchConsole: FC = (): JSX.Element => {
  return (
    <motion.div
      className='analytics-main search-console'
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}
    >
      <Upcoming />
    </motion.div>
  );
};
export default SearchConsole;
