import React, { FC, useState } from 'react';
import { motion } from 'framer-motion';
import baselineSettings from '@iconify/icons-ic/baseline-settings';
import Icon from '@iconify/react';
const variants = {
  collapsed: { height: 68, scale: 1, y: 0 },
  expanded: {
    height: 'auto',
    scale: 1.038,
    transition: { duration: 0.5 },
  },
};
const textVariants = {
  collapsed: { opacity: 0, y: 50 },
  expanded: { opacity: 1, transition: { delay: 0.02 }, y: 0 },
};
interface Props {
  status: string;
  fixerFunc: any;
  label: string;
  index: number;
}
const ListItem: FC<Props> = ({ status, fixerFunc, label, index }): JSX.Element => {
  const [open, setOpen] = useState(false);
  const className =
    status === 'Failed'
      ? 'failed'
      : status === 'Not Satisfied'
      ? 'not-satisfied'
      : 'success';
  return (
    <motion.div
      className='audit-list'
      animate={open ? 'expanded' : 'collapsed'}
      initial={open ? 'expanded' : 'collapsed'}
      variants={variants}>
      <div className={`list-main ${open && 'selected'}`}>
        <div className='label-status'>
          <h5 className='audit-item-label'>{index + 1 + '. ' + label}</h5>
          <p>
            Status: <span className={className}>{className}</span>
          </p>
        </div>
        <div className='status-and-fixes'>
          {status === 'Failed' && (
            <button
              className='fix-button'
              name='static-name'
              onClick={() => fixerFunc(label)}>
              <Icon icon={baselineSettings} />
              Fix now
            </button>
          )}

          <div className='detail' onClick={() => setOpen(!open)}>
            <span className={open ? 'collapses' : 'expands'}>
              <svg
                width='30'
                height='30'
                viewBox='0 0 1792 1792'
                xmlns='http://www.w3.org/2000/svg'>
                <path d='M1650 288q0 13-10 23l-332 332 144 144q19 19 19 45t-19 45-45 19h-448q-26 0-45-19t-19-45v-448q0-26 19-45t45-19 45 19l144 144 332-332q10-10 23-10t23 10l114 114q10 10 10 23z' />
                <path d='M896 960v448q0 26-19 45t-45 19-45-19l-144-144-332 332q-10 10-23 10t-23-10l-114-114q-10-10-10-23t10-23l332-332-144-144q-19-19-19-45t19-45 45-19h448q26 0 45 19t19 45z' />
              </svg>
            </span>
          </div>
        </div>
      </div>

      <motion.div
        className={`audit-toggle-text ${className}`}
        variants={textVariants}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        Lorem Ipsum has been the industry's standard dummy text ever since the
        1500s, when an unknown printer took a galley of type and scrambled it to
        make a type specimen book. It has survived not only five centuries, but
        also the leap into electronic typesetting, remaining essentially unchanged.
        It was popularised in the 1960s with the release of Letraset sheets
        containing Lorem Ipsum passages, and more recently with desktop publishing
        software like Aldus PageMaker including versions of Lorem Ipsum.
      </motion.div>
    </motion.div>
  );
};

export default ListItem;
