export const filterFields = {
  label: 'Add Filter',
  name: 'filterName',
  options: [
    {
      label: 'Uppercase/Lowercase',
      options: [
        {
          label: 'Campaign Name',
          value: 'Campaign Name',
          type: 'uppercase or lowercase',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          maxPage: 2,
        },
        {
          label: 'Campaign Source',
          value: 'Campaign Source',
          type: 'uppercase or lowercase',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          maxPage: 2,
        },
        {
          label: 'Campaign Medium',
          value: 'Campaign Medium',
          type: 'uppercase or lowercase',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          maxPage: 2,
        },
        {
          label: 'Campaign Terms',
          value: 'Campaign Terms',
          type: 'uppercase or lowercase',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          maxPage: 2,
        },
        {
          label: 'Campaign Content',
          value: 'Campaign Content',
          type: 'uppercase or lowercase',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          maxPage: 2,
        },
        {
          label: 'Search Terms',
          value: 'Search Terms',
          type: 'uppercase or lowercase',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          maxPage: 2,
        },
        {
          label: 'Page Path',
          value: 'Page Path',
          type: 'uppercase or lowercase',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          maxPage: 2,
        },
      ],
    },
    {
      label: 'Advanced',
      options: [
        {
          label: 'Simplify Long Page Path',
          value: 'Simplify Long Page Path',
          type: 'advanced',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          maxPage: 2,
        },
      ],
    },
    {
      label: 'Include/Exclude',
      options: [
        {
          label: 'Limit IP Address',
          value: 'Limit IP Address',
          type: 'include exclude',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          fields: [{ name: 'expressionValue', label: 'GEO IP Address' }],
          maxPage: 2,
        },
        {
          label: 'Limit Device Traffic',
          value: 'Limit Device Traffic',
          type: 'include exclude',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          fields: [{ name: 'expressionValue', label: 'Device' }],
          maxPage: 2,
        },
        {
          label: 'Restrict Dev/Test Hostnames',
          value: 'Restrict Dev/Test Hostnames',
          type: 'include exclude',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          fields: [{ name: 'expressionValue', label: 'Homename Prefix' }],
          maxPage: 2,
        },
        {
          label: 'Limit hostnames',
          value: 'Limit hostnames',
          type: 'include exclude',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          fields: [{ name: 'expressionValue', label: 'Homename Prefix' }],
          maxPage: 2,
        },
        {
          label: 'Target countries for reporting',
          value: 'Target countries for reporting',
          type: 'include exclude',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          maxPage: 2,
        },
        {
          label: 'Exclude spam sources',
          value: 'Exclude spam sources',
          type: 'include exclude',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          maxPage: 2,
        },
      ],
    },

    {
      label: 'Search and Replace',
      options: [
        {
          label: 'Combine all Facebook sources in one',
          value: 'Combine all Facebook sources in one',
          type: 'search and replace',
          details:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae officiis maxime odit, explicabo assumenda ad alias soluta, quod magni repellat rerum? Porro fugit architecto quidem corporis temporibus repellendus laborum alias?',
          maxPage: 1,
        },
        {
          label: 'Combine all Pinterest sources in one',
          value: 'Combine all Pinterest sources in one',
          type: 'search and replace',
          maxPage: 1,
        },
        {
          label: 'Combine all Twitter sources in one',
          value: 'Combine all Twitter sources in one',
          type: 'search and replace',
          maxPage: 1,
        },
        {
          label: 'Combine all Instagram sources in one',
          value: 'Combine all Instagram sources in one',
          type: 'search and replace',
          maxPage: 1,
        },
        {
          label: 'Combine all LinkedIn sources in one',
          value: 'Combine all LinkedIn sources in one',
          type: 'search and replace',
          maxPage: 1,
        },
        {
          label: 'Remove all Query Parameters from URLs',
          value: 'Remove all Query Parameters from URLs',
          type: 'search and replace',
          maxPage: 1,
        },
        {
          label: 'Consolidate domains with & without www.',
          value: 'Consolidate domains with & without www.',
          type: 'search and replace',
          maxPage: 1,
        },
        {
          label: 'Replace number with names in page paths',
          value: 'Replace number with names in page paths',
          type: 'search and replace',
          maxPage: 1,
        },
        {
          label: 'Remove .html from Page Paths',
          value: 'Remove .html from Page Paths',
          type: 'search and replace',
          maxPage: 1,
        },
        {
          label: 'View full page URL in reports',
          value: 'View full page URL in reports',
          type: 'search and replace',
          maxPage: 1,
        },
        {
          label: 'Rewrite meidum from referel to social',
          value: 'Rewrite meidum from referel to social',
          type: 'search and replace',
          maxPage: 1,
        },
        {
          label: 'Remove text before the tiles in page path',
          value: 'Remove text before the tiles in page path',
          type: 'search and replace',
          maxPage: 1,
        },
        {
          label: 'Append to/Remove from slash from page path',
          value: 'Append to/Remove from slash from page path',
          type: 'search and replace',
          maxPage: 1,
        },
      ],
    },
  ],
};
