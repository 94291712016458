import { AccountList } from '../../reducers/accountListReducer';
export const GET_ACCOUNT_LIST = 'GET_ACCOUNT_LIST';
export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';

interface GetAccountListAction {
  type: typeof GET_ACCOUNT_LIST;
  payload: AccountList;
}

interface SetSelectedAccount {
  type: typeof SET_SELECTED_ACCOUNT;
  payload: string;
}
export type AccountListActionTypes = GetAccountListAction | SetSelectedAccount;
