import gql from 'graphql-tag';

export const USER_BEHAVIOR_QUERY = gql`
  query userBehavior($input: userBehaviorInputTypes) {
    userBehavior(input: $input) {
      sessionData {
        metricName
        totalValue
        maximumValue
      }
      audiencePageData {
        dimensionName
        metricName
        type
        metricValue {
          dimensionName
          value
        }
        totalValue
        maximumValue
      }
      existingVsNewUsersOutput {
        dimensionName
      }
      audienceActionData {
        dimensionName
        metricName
        type
        totalValue
      }
      timelineData {
        dimensionName
        metricName
        type
        metricValue {
          dimensionName
          value
        }
        totalValue
        maximumValue
      }
      languageData {
        users {
          propertyName
          count
        }
        newUsers {
          propertyName
          count
        }
      }
      countryData {
        users {
          propertyName
          count
        }
        newUsers {
          propertyName
          count
        }
      }
      deviceData {
        dimensionName
        metricName
        type
        metricValue {
          dimensionName
          value
        }
        totalValue
      }
    }
  }
`;
