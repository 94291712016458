import React, { FC } from 'react';
import { Toast } from 'react-bootstrap';

interface Props {
  header: string;
  message: string;
  setToast: any;
}

const CustomToast: FC<Props> = ({ setToast, header, message }): JSX.Element => {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 20,
        left: 20,
        minWidth: 318,
        color: header === 'fail' ? 'orangered' : 'green',
        zIndex: 2052,
      }}
    >
      <div
        style={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Toast
          show={Boolean(message)}
          onClose={() => setToast(!Boolean(message))}
          delay={4000}
          autohide
        >
          <Toast.Header>
            {/* <img src='holder.js/20x20?text=%20' className='rounded mr-2' alt='' /> */}
            <strong className='mr-auto'>
              {header === 'fail' && 'Error'}
              {header === 'success' && 'Success'}
            </strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
};

export default CustomToast;
