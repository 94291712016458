import React, { FC, useEffect, useState } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import googleAnalytics from '@iconify/icons-cib/google-analytics';
import './styles.css';
import Icon from '@iconify/react';
import Selectors from './Selectors/index';
import { useLazyQuery } from '@apollo/react-hooks';
import { USER_BEHAVIOR_QUERY } from '../../graphql/reporting';
import { channels, continents, devices, regions } from './selectOptions.json';
import { setReportingResultAction } from '../../actions/reporting/reportingAction';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import Timeline from './Timeline';
import { setLoading } from '../../actions/loader/loaderAction';

const formatDate = (d: Date) => {
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  const month = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
  const date = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  const currentDate = `${year}-${month}-${date}`;
  return currentDate;
};

const previousMonth = () => {
  var d = new Date();
  var m = d.getMonth();
  d.setMonth(d.getMonth() - 12);
  if (d.getMonth() == m) d.setDate(0);
  d.setHours(0, 0, 0);
  d.setMilliseconds(0);
  return formatDate(d);
};

const startDate = previousMonth();
const endDate = formatDate(new Date());

const AudienceOverview: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const [checkedContinent, setCheckedContinent] = useState<string[]>(
    continents
  );
  const [checkedRegion, setCheckedRegion] = useState<string[]>(regions);
  const [checkedChannel, setCheckedChannel] = useState<string[]>(channels);
  const [checkedDevice, setCheckedDevice] = useState<string[]>(devices);

  const {
    userBehavior: {
      sessionData,
      audiencePageData,
      existingVsNewUsersOutput,
      audienceActionData,
      timelineData,
    },
  } = useSelector((state: RootState) => state.reporting, shallowEqual);
  const selectedView = useSelector(
    (state: RootState) => state.viewsList.selectedView,
    shallowEqual
  );

  const doughnutData = {
    labels: ['New Visitor', 'Returning Visitor'],
    datasets: [
      {
        label: 'First dataset',
        data: [
          timelineData[1] ? timelineData[1].totalValue : 0,
          timelineData[0] ? timelineData[0].totalValue : 0,
        ],
        backgroundColor: ['orange', 'yellow'],
      },
    ],
  };

  const doughnutOptions = {
    legend: {
      labels: {
        boxWidth: 10,
      },
    },
  };

  const [state, setState] = useState({
    startDate,
    endDate,
  });

  const [userBehaviorQuery, { loading, data: userBehaviorData }] = useLazyQuery(
    USER_BEHAVIOR_QUERY,
    {
      variables: {
        input: {
          viewId: selectedView.value,
          startDate: state.startDate,
          endDate: state.endDate,
          regionFilter: checkedRegion,
          deviceFilter: checkedDevice,
          continentFilter: checkedContinent,
        },
      },
      onCompleted: () => {
        dispatch(setReportingResultAction(userBehaviorData.userBehavior));
      },
    }
  );
  const { loading: loadingStatus, type: loadingType } = useSelector(
    (state: RootState) => state.loader,
    shallowEqual
  );

  useEffect(() => {
    userBehaviorQuery();
  }, [
    state,
    checkedChannel,
    checkedContinent,
    checkedDevice,
    checkedRegion,
    selectedView,
  ]);

  useEffect(() => {
    if (loading) dispatch(setLoading('reporting-api'));
    if (loadingStatus) dispatch(setLoading(null));
  }, [loading]);

  const secondToTimeFormat = (second: number) => {
    var date = new Date(0);
    date.setSeconds(second); // specify value for SECONDS here
    var timeString = date.toISOString().substr(11, 8);
    return timeString;
  };

  return (
    <div className='search-console'>
      <Icon icon={googleAnalytics} className='analytics-icon' />
      <h1>Google Analytics Audience Overview</h1>

      <Selectors
        checkedContinent={checkedContinent}
        checkedChannel={checkedChannel}
        checkedDevice={checkedDevice}
        checkedRegion={checkedRegion}
        setCheckedChannel={setCheckedChannel}
        setCheckedContinent={setCheckedContinent}
        setCheckedDevice={setCheckedDevice}
        setCheckedRegion={setCheckedRegion}
        state={state}
        setState={setState}
      />
      {loading && <p>Loading Report...</p>}
      {!loading && selectedView.value ? (
        <>
          <h2>Your audience at a glance</h2>
          <div className='chart-area'>
            <div className='line-chart'>
              {/* <Line data={data} options={timelineOptions} height={60} /> */}
              <Timeline startDate={state.startDate} />
              {/* <LineChart /> */}
              <div className='indi-chart'>
                <div>
                  <span>Users</span>
                  <h3 className='count'>
                    {timelineData[0] ? timelineData[0].totalValue : 0}
                  </h3>
                </div>

                <div>
                  <span>New Users</span>
                  <h3 className='count'>
                    {timelineData[1] ? timelineData[1].totalValue : 0}
                  </h3>
                </div>

                <div>
                  <span>Number of Sessions per User</span>
                  <h3 className='count'>
                    {sessionData
                      ? (sessionData[2].totalValue - 0).toFixed(2)
                      : 0}
                  </h3>
                </div>

                <div>
                  <span>Sessions</span>
                  <h3 className='count'>
                    {sessionData ? sessionData[1].totalValue : 0}
                  </h3>
                </div>

                <div>
                  <span>Pageviews</span>
                  <h3 className='count'>
                    {audiencePageData[0] ? audiencePageData[0].totalValue : 0}
                  </h3>
                </div>

                <div>
                  <span>Pages / Session</span>
                  <h3 className='count'>
                    {sessionData
                      ? (sessionData[0].totalValue - 0).toFixed(2)
                      : 0}
                  </h3>
                </div>

                <div>
                  <span>Avg. Session Duration</span>
                  <h3 className='count'>
                    {audiencePageData[3]
                      ? secondToTimeFormat(audiencePageData[3].totalValue)
                      : 0}
                  </h3>
                </div>

                <div>
                  <span>Bounce Rate</span>
                  <h3 className='count'>
                    {audienceActionData[0]
                      ? (audienceActionData[0].totalValue - 0).toFixed(2) + '%'
                      : 0}
                  </h3>
                </div>
              </div>
            </div>
            <div className='doughnut-chart'>
              <Doughnut data={doughnutData} options={doughnutOptions} />
              {/* <Line data={data} /> */}
              <Timeline startDate={state.startDate} />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AudienceOverview;
