import React, { FC } from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
} from 'react-bootstrap';
import InputField from '../../textField/InputField';

import { motion } from 'framer-motion';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import FilterDetails from './FilterDetails';

interface Props {
  checkedPrefix: string[];
  setCheckedPrefix: any;
  devHostnames: any;
  setDevHostnames: any;
  showPageNumber: number;
}

const RestrictDevHostname: FC<Props> = ({
  checkedPrefix,
  setCheckedPrefix,
  devHostnames,
  setDevHostnames,
  showPageNumber,
}): JSX.Element | null => {
  const devices = ['beta', 'dev', 'test', 'prod'];

  const { selectedFilter } = useSelector(
    (state: RootState) => state.filterList,
    shallowEqual
  );
  if (!selectedFilter || selectedFilter.label !== 'Restrict Dev/Test Hostnames')
    return null;
  const handleAddMoreHostname = () => {
    setDevHostnames([...devHostnames, { protocol: 'https', host: '' }]);
  };
  const filterCheckboxChangeHandler = (e: any) => {
    if (checkedPrefix.includes(e.target.id)) {
      const filteredDevice = checkedPrefix.filter(
        (device) => device !== e.target.id
      );
      setCheckedPrefix(filteredDevice);
    } else {
      setCheckedPrefix([...checkedPrefix, e.target.id]);
    }
  };
  let val: string;
  const changeHandler = (e: any, index: number, type: string) => {
    if (typeof e === 'string') {
      val = e;
    }
    if (typeof e === 'object') {
      val = e.target.value;
    }
    devHostnames[index] = { ...devHostnames[index], [type]: val };
    setDevHostnames([...devHostnames]);
  };
  if (showPageNumber === 1)
    return <FilterDetails details={selectedFilter.description} />;

  if (showPageNumber === 2)
    return (
      <motion.div initial={{ x: 400 }} animate={{ x: 0 }} exit={{ x: -400 }}>
        <h6 className='filter-label'>Hostname</h6>
        {devHostnames.map((hostname: any, index: number) => (
          <InputGroup className='mb-3' key={index}>
            <DropdownButton
              onSelect={(e: string) => changeHandler(e, index, 'protocol')}
              as={InputGroup.Prepend}
              variant='outline-primary'
              title={hostname.protocol}
              id='protocol-dropdown'>
              <Dropdown.Item eventKey='https'>https</Dropdown.Item>
              <Dropdown.Item eventKey='http'>http</Dropdown.Item>
              <Dropdown.Item eventKey='ftp'>ftp</Dropdown.Item>
            </DropdownButton>
            <InputField
              type='text'
              name='host'
              placeholder='Enter full hostname'
              value={hostname.host}
              onChange={(e: any) => changeHandler(e, index, 'host')}
            />
            <Button variant='secondary' onClick={handleAddMoreHostname}>
              +
            </Button>
          </InputGroup>
        ))}

        <div className='field-and-button'>
          <h6 className='filter-label'>Hostname prefix</h6>
          <div className='device-checkbox-area'>
            {devices.map((device: string) => (
              <Form.Check
                custom
                type='checkbox'
                label={device}
                id={device}
                key={device}
                onChange={filterCheckboxChangeHandler}
              />
            ))}
          </div>
        </div>
      </motion.div>
    );
  return null;
};

export default RestrictDevHostname;
