import React, { FC } from 'react';
import { Row, Col } from 'react-bootstrap';
import MainContent from '../components/main-content';
import AdminPanel from '../components/filter-views';
import { motion } from 'framer-motion';
const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const AnalyticsSetup: FC = (): JSX.Element => {
  return (
    <motion.div
      id='main-profile'
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}>
      {/* <Profile /> */}
      <Row>
        <Col className=''>
          <MainContent />
        </Col>
        <div className='rightSidebar'>
          <AdminPanel />
        </div>
      </Row>
    </motion.div>
  );
};
export default AnalyticsSetup;
