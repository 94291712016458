import React, { FC, Fragment } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  state: any;
  setState: any;
}
const DateSelector: FC<Props> = ({ state, setState }): JSX.Element => {
  const changeHandler = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <Fragment>
      <div className='start-date'>
        <label htmlFor='start-date'>Start Date</label>
        <Form.Group controlId='startDate'>
          <Form.Control
            type='date'
            name='startDate'
            onChange={changeHandler}
            defaultValue={state.startDate}
          />
        </Form.Group>
      </div>
      <div className='end-datee'>
        <label htmlFor='end-date'>End Date</label>
        <Form.Group controlId='endDate'>
          <Form.Control
            type='date'
            name='endDate'
            onChange={changeHandler}
            defaultValue={state.endDate}
          />
        </Form.Group>
      </div>
    </Fragment>
  );
};

export default DateSelector;
