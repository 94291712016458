import React, { useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../reducers';
import { closeModalAction } from '../../actions/modal/modalAction';
import { Form, Button } from 'react-bootstrap';
import InputField from '../textField/InputField';
import PopUp from '.';
import CustomToast from '../CustomToast';

interface Props {
  idToDelete: string;
  deleteMutation: any;
}
const DeleteModal: FC<Props> = ({ idToDelete, deleteMutation }): JSX.Element => {
  const [toast, setToast] = useState({ type: '', message: '' });
  // const [error, setError] = useState('');
  const modalType = useSelector((state: RootState) => state.modal.modalType);

  const [id, setId] = useState('');
  const dispatch = useDispatch();

  // view modal submit handler
  const submitHandler = (e: any) => {
    e.preventDefault();
    // console.log('Submited Data: ', property);
    if (modalType === 'deleteView') {
      if (idToDelete === id.trim()) {
        deleteMutation();
      }
    }
    if (modalType === 'deleteProperty') {
      alert('DELETE PROPERTY');
    }
  };

  const changeHandler = (e: any) => {
    setId(e.target.value);
  };

  const closeHandler = () => {
    dispatch(closeModalAction());
    setId('');
  };

  let CustomModal;
  if (modalType === 'deleteView' || modalType === 'deleteProperty') {
    CustomModal = (
      <PopUp
        modalTitle={modalType === 'deleteView' ? 'Delete View' : 'Delete Property'}
        closeHandler={closeHandler}>
        <div className='confirm'>
          <p>
            Enter the {modalType === 'deleteView' ? 'View' : 'Property'} id{' '}
            <span className='idToDelete'>{idToDelete}</span> to confirm
          </p>
        </div>
        <Form onSubmit={submitHandler}>
          <InputField
            type='text'
            name='name'
            placeholder={
              modalType === 'deleteView' ? 'Enter View Id' : 'Enter Property Id'
            }
            value={id}
            onChange={changeHandler}
            label={modalType === 'deleteView' ? 'View Id' : 'Property Id'}
          />

          <Button
            variant='primary'
            type='submit'
            className='mt-3'
            disabled={idToDelete !== id.trim()}>
            {modalType === 'deleteView' ? 'Delete View' : 'Delete Property'}
          </Button>
        </Form>
      </PopUp>
    );
  }
  return (
    <>
      <CustomToast
        header={toast.type}
        message={toast.message}
        setToast={setToast}
      />
      {CustomModal}
    </>
  );
};

export default DeleteModal;
