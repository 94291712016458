import React, { useState, useEffect, FC } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import TabItem from './TabItem';
import PropertyDropdown from '../../customDropdown/PropertyDropdown';
import DetailTable from './DetailTable';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from '../../../store';
import { View } from '../../../reducers/viewsListReducer';
import { Property } from '../../../reducers/propertyReducer';
import CustomToast from '../../CustomToast';
import EnableDisableFilters from '../../popUp/Filter/EnableDisableFilters';
import ViewDropdown from '../../customDropdown/ViewDropDown';

const TabContext: FC = (): JSX.Element => {
  const [tab, setTab] = useState('property');
  const [toast, setToast] = useState({ type: '', message: '' });

  const { selectedView, checked, views } = useSelector(
    (state: RootState) => state.viewsList,
    shallowEqual
  );

  const { property, selectedProperty } = useSelector(
    (state: RootState) => state.properties,
    shallowEqual
  );

  // selected property details
  const selectedPropertyDetails = property.find(
    (item: Property) => item.id === selectedProperty.value
  );
  const setPropertyValue = (property: any) => {
    const { id, name, websiteUrl, defaultProfileId } = property;
    const defaultView = views.find((view: any) => view.id === defaultProfileId);
    const propertyDetails = [
      { label: 'Property id', value: id },
      { label: 'Property name', value: name },
      { label: 'Website URL', value: websiteUrl },
      {
        label: 'Default View',
        value: defaultView ? defaultView.name : '',
      },
    ];
    return propertyDetails;
  };
  let propertyDetails;
  if (selectedPropertyDetails) {
    propertyDetails = setPropertyValue(selectedPropertyDetails);
  }

  useEffect(() => {
    // on selecting any view set tab to view
    (selectedView || checked.length > 0) && setTab('views');
  }, [selectedView, checked]);

  const setViewValue = (item: any) => {
    const { id, name, currency, timezone, websiteUrl, type } = item;
    return [
      { label: 'View id', value: id },
      { label: 'View name', value: name },
      { label: 'Website URL', value: websiteUrl },
      { label: 'View Currency', value: currency },
      { label: 'View Timezone', value: timezone },
      { label: 'View Type', value: type },
    ];
  };

  const selectedViewDetail = views.find(
    (view: View) => view.id === selectedView.value
  );

  // selected View Details Property and Values
  let viewDetails;
  if (selectedViewDetail) {
    viewDetails = setViewValue(selectedViewDetail);
  }
  const { loading, type } = useSelector((state: RootState) => state.loader);

  return (
    <>
      {!selectedPropertyDetails ? (
        <div className='not-prop-selected'>
          <h5 className=''>Select a property to see property details</h5>
        </div>
      ) : (
        <Tab.Container activeKey={tab}>
          <div className='property-nav'>
            <Nav
              variant='pills'
              className='flex-row'
              activeKey={tab}
              onSelect={(selectedKey: string) => setTab(selectedKey)}
            >
              <TabItem eventKey='property' title='Property' />
              <TabItem eventKey='views' title='Views' />
            </Nav>
          </div>

          <div className='property-content'>
            <Tab.Content>
              <Tab.Pane eventKey='property'>
                <div className='property-table-heading'>
                  <h3>Property Details</h3>
                  <PropertyDropdown />
                </div>

                {/* {loading && type === 'property' && <SkeletonLoader />} */}

                {/* Render Property Details */}
                {selectedPropertyDetails && propertyDetails && (
                  <DetailTable details={propertyDetails} />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey='views'>
                <div className='views-table-heading'>
                  <h3>View Details</h3>
                  <ViewDropdown />
                </div>
                {/* {loading && type === 'view' && <SkeletonLoader />} */}
                {/* render selected view details */}
                {views.length > 0 && selectedViewDetail && viewDetails && (
                  <DetailTable details={viewDetails} />
                )}

                {!(loading && type === 'view') && !selectedViewDetail && (
                  <h5 className='not-selected'>
                    Select a view to see view details
                  </h5>
                )}
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      )}

      <CustomToast
        header={toast.type}
        message={toast.message}
        setToast={setToast}
      />

      <EnableDisableFilters />
    </>
  );
};

export default TabContext;
