import React, { FC } from 'react';
import { Form } from 'react-bootstrap';

import './style.css';

interface Props {
  label?: string;
  name?: string;
  type: string;
  placeholder?: string;
  error?: string;
  value: string;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField: FC<Props> = ({
  label,
  name,
  type,
  placeholder,
  error,
  onChange,
  value,
  className,
}): JSX.Element => {
  return (
    <Form.Group className={`input-box ${className}`}>
      {label && <Form.Label className='label'> {label} </Form.Label>}
      <Form.Control
        className='input-text'
        type={type}
        name={name ? name : ''}
        placeholder={placeholder ? placeholder : ''}
        onChange={onChange}
        value={value}
      />
      {error && <Form.Text className='error'>{error}</Form.Text>}
    </Form.Group>
  );
};

export default InputField;
