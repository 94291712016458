import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../../store';
import { View } from '../../reducers/viewsListReducer';

import {
  GET_VIEWS,
  GET_VIEW_FILTERS,
  CLEAR_VIEW_FILTERS,
  ADD_VIEW,
  SET_DEFAULT_VIEW,
  SET_SELECTED_VIEW,
  ViewsActionTypes,
  CLEAR_SELECTED_VIEW,
  CLEAR_VIEWS,
  UPDATE_VIEW,
  DELETE_VIEW,
  DISABLE_FILTER,
  ENABLE_FILTER,
} from './types';

// Get view by property id
export const getViewsAction = (
  data: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  // dispatching GET_VIEWS with the filtered view list
  data.views && dispatch({ type: GET_VIEWS, payload: data.views });
};

export const getViewFiltersAction = (viewFilters?: any): ViewsActionTypes => {
  return {
    type: GET_VIEW_FILTERS,
    payload: viewFilters,
  };
};
export const clearViewFiltersAction = (): ViewsActionTypes => {
  return {
    type: CLEAR_VIEW_FILTERS,
  };
};

export const addViewAction = (view: View): ViewsActionTypes => {
  return {
    type: ADD_VIEW,
    payload: view,
  };
};
export const updateViewAction = (view: View): ViewsActionTypes => {
  return {
    type: UPDATE_VIEW,
    payload: view,
  };
};

export const deleteViewAction = (viewId: string): ViewsActionTypes => {
  return {
    type: DELETE_VIEW,
    payload: viewId,
  };
};

export const setSelectedViewAction = (selectedView: any): ViewsActionTypes => {
  return {
    type: SET_SELECTED_VIEW,
    payload: selectedView,
  };
};

export const clearSelectedViewAction = (): ViewsActionTypes => {
  return {
    type: CLEAR_SELECTED_VIEW,
  };
};
export const clearViewsAction = (): ViewsActionTypes => {
  return {
    type: CLEAR_VIEWS,
  };
};

export const setDefaultViewAction = (
  propertyId: string,
  defaultViewId: string
): ViewsActionTypes => {
  return {
    type: SET_DEFAULT_VIEW,
    payload: { propertyId, defaultViewId },
  };
};

export const enableFilterAction = (filter: any) => {
  return {
    type: ENABLE_FILTER,
    payload: filter,
  };
};

export const disableFilterAction = (filter: any) => {
  return {
    type: DISABLE_FILTER,
    payload: filter,
  };
};
