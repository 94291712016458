import React, { FC } from 'react';
import infoCircle from '@iconify/icons-fa-solid/info-circle';
import Icon from '@iconify/react';
import { motion } from 'framer-motion';
import settingsSolid from '@iconify/icons-clarity/settings-solid';

const variants = {
  right: { x: 400 },
  left: { x: -400 },
  animate: { x: 0 },
  exitRight: { x: 400 },
  exitLeft: { x: -400 },
};

interface Props {
  details: string;
}

const FilterDetails: FC<Props> = ({ details }): JSX.Element => {
  return (
    <motion.div
      className='filter-information'
      variants={variants}
      initial='left'
      animate='animate'
      exit='exitRight'>
      <h6 className='filter-label'>
        <Icon icon={infoCircle} />
        Filter Information
      </h6>
      <p className='my-4'>{details}</p>
      <h6 className='filter-label mt-4'>
        <Icon icon={settingsSolid} />
        Current Configuration
      </h6>
      <p className='my-2'>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae
        suscipit blanditiis deleniti, porro dolore ipsum et est iusto velit
        consectetur perspiciatis, commodi nihil ea fugit molestias cumque eveniet
        maiores tempora?
      </p>
    </motion.div>
  );
};

export default FilterDetails;
