import React, { FC } from 'react';

interface deviceDt {
  desktop: {
    users: number;
    newUsers: number;
  };
  mobile: {
    users: number;
    newUsers: number;
  };
  tablet: {
    users: number;
    newUsers: number;
  };
}

interface Props {
  detailsOf: string;
  data: deviceDt;
}
const DeviceDetails: FC<Props> = ({ detailsOf, data }): JSX.Element => {
  return (
    <table>
      <tbody>
        <tr>
          <th></th>
          <th>{detailsOf}</th>
          <th>Users</th>
          <th>New Users</th>
        </tr>
        <tr>
          <td>1</td>
          <td>desktop</td>
          <td>{data.desktop.users ?? 0}</td>
          <td>{data.desktop.newUsers ?? 0}</td>
        </tr>
        <tr>
          <td>2</td>
          <td>mobile</td>
          <td>{data.mobile.users ?? 0}</td>
          <td>{data.mobile.newUsers ?? 0}</td>
        </tr>
        <tr>
          <td>3</td>
          <td>tablet</td>
          <td>{data.tablet.users ?? 0}</td>
          <td>{data.tablet.newUsers ?? 0}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default DeviceDetails;
