import gql from 'graphql-tag';

export const ADD_FILTER_MUTATION = gql`
  mutation addFilter(
    $accountId: ID!
    $filterName: String!
    $type: String
    $expressionValue: String
    $expressionList: [String]
  ) {
    addFilter(
      accountId: $accountId
      filterName: $filterName
      type: $type
      expressionValue: $expressionValue
      expressionList: $expressionList
    ) {
      id
      name
    }
  }
`;
export const FETCH_RECOMMENDED_FILTERS_QUERY = gql`
  query {
    recommendedFilters {
      name
      description
      type
      field
      added
      relatedTo
      appliedToAll
    }
  }
`;
