export const checkPrefix = (name: string, propertyLabel: string) => {
  if (name.startsWith(`Amdeas${propertyLabel}_`)) {
    name = name.slice(`Amdeas${propertyLabel}_`.length);
    return { name, prefix: true, prefixType: 'property' };
  }
  if (name.startsWith('AmdeasAll_')) {
    name = name.slice(10);
    return { name, prefix: true };
  }
  return { name, prefix: false };
};
