import {
  GET_VIEWS,
  GET_VIEW_FILTERS,
  CLEAR_VIEW_FILTERS,
  ADD_VIEW,
  SET_DEFAULT_VIEW,
  SET_SELECTED_VIEW,
  ViewsActionTypes,
  CLEAR_SELECTED_VIEW,
  CLEAR_VIEWS,
  UPDATE_VIEW,
  DELETE_VIEW,
  ENABLE_FILTER,
  DISABLE_FILTER,
} from '../actions/viewsList/types';
import {
  ADD_FILTER,
  SET_RECOMMENDED_FILTER,
  AccountListActionTypes,
} from '../actions/filterList/types';

export interface View {
  id: string;
  kind: string;
  selfLink: string;
  accountId: string;
  webPropertyId: string;
  internalWebPropertyId: string;
  name: string;
  currency: string;
  timezone: string;
  websiteUrl: string;
  type: string;
  permissions: {
    effective: string[];
  };
  created: string;
  updated: string;
  eCommerceTracking: boolean;
  botFilteringEnabled: boolean;
  parentLink: {
    type: string;
    href: string;
  };
  childLink: {
    type: string;
    href: string;
  };
}
interface ViewFilters {
  active: any;
  inactive: any;
  recommended: any;
}

interface ViewState {
  views: View[];
  viewFilters: ViewFilters;
  selectedView: any;
  defaultView: any;
  checked: string[];
}

const initialState: ViewState = {
  views: [],
  viewFilters: {
    active: [],
    inactive: [],
    recommended: [],
  },
  selectedView: '',
  defaultView: {},
  checked: [],
};

export default (
  state = initialState,
  action: ViewsActionTypes | AccountListActionTypes
) => {
  switch (action.type) {
    case GET_VIEWS:
      return {
        ...state,
        views: action.payload,
      };
    case GET_VIEW_FILTERS:
      return {
        ...state,
        viewFilters: {
          ...action.payload,
          recommended:
            action.payload.recommended && action.payload.recommended.length
              ? action.payload.recommended
              : state.viewFilters.recommended,
        },
      };
    case CLEAR_VIEW_FILTERS:
      return {
        ...state,
        viewFilters: { ...state.viewFilters, active: [], inactive: [] },
      };
    case ADD_VIEW:
      return {
        ...state,
        views: [...state.views, action.payload],
      };
    case UPDATE_VIEW:
      return {
        ...state,
        views: state.views.map((view) =>
          view.id === action.payload.id ? action.payload : view
        ),
      };
    case DELETE_VIEW:
      return {
        ...state,
        views: state.views.filter((view) => view.id !== action.payload),
      };
    case SET_DEFAULT_VIEW:
      return {
        ...state,
        defaultView: action.payload,
      };
    case SET_SELECTED_VIEW:
      return {
        ...state,
        selectedView: action.payload,
      };
    case CLEAR_SELECTED_VIEW:
      return {
        ...state,
        selectedView: '',
      };
    case CLEAR_VIEWS:
      return {
        ...state,
        views: [],
      };
    case SET_RECOMMENDED_FILTER:
      return {
        ...state,
        viewFilters: {
          ...state.viewFilters,
          recommended: action.payload,
        },
      };
    case ENABLE_FILTER:
      console.log('payload', action.payload);
      return {
        ...state,
        viewFilters: {
          ...state.viewFilters,
          inactive: state.viewFilters.inactive.filter(
            (filter: any) => filter.id !== action.payload.id
          ),
          recommended: state.viewFilters.recommended.filter(
            (filter: any) => filter.name !== action.payload.name
          ),
          active: [...state.viewFilters.active, action.payload],
        },
      };
    case DISABLE_FILTER:
      return {
        ...state,
        viewFilters: {
          ...state.viewFilters,
          active: state.viewFilters.active.filter(
            (filter: any) => filter.id !== action.payload.id
          ),
          inactive: [...state.viewFilters.inactive, action.payload],
        },
      };
    case ADD_FILTER:
      return {
        ...state,
        viewFilters: {
          ...state.viewFilters,
          recommended: state.viewFilters.recommended.filter(
            (filter: any) => !action.payload.name.includes(filter.name)
          ),
          inactive: [...state.viewFilters.inactive, action.payload],
        },
      };

    default:
      return state;
  }
};
