import React, { FC, useState } from 'react';
import { Form } from 'react-bootstrap';
import './style.css';
import Select from 'react-select';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_RECOMMENDED_FILTERS_QUERY } from '../../graphql/filter';

interface Props {
  changeHandler: any;
  options: any;
}

const FilterSelect: FC<Props> = ({ changeHandler, options }) => {
  const modalType = useSelector((state: RootState) => state.modal.modalType);
  const [option, setOption] = useState([]);
  const selectedFilter = useSelector(
    (state: RootState) => state.filterList.selectedFilter,
    shallowEqual
  );
  const { data } = useQuery(FETCH_RECOMMENDED_FILTERS_QUERY, {
    onCompleted: () => {
      const formattedOptions =
        data &&
        data.recommendedFilters.map((d: any) => {
          d.label = d.name;
          d.value = d.name;
          return d;
        });
      setOption(formattedOptions);
    },
  });

  if (!['addFilter', 'editFilter'].includes(modalType)) return null;
  return (
    <Form.Group style={{ zIndex: 20 }}>
      <Form.Label className='label'>Add Filter</Form.Label>
      <Select
        options={option}
        defaultValue={selectedFilter}
        onChange={changeHandler}
        styles={{
          option: (prev) => ({
            ...prev,
            ':active': {
              ...prev[':active'],
              backgroundColor: 'var(--analytics-theme-color);',
            },
          }),
        }}
      />
    </Form.Group>
  );
};

export default FilterSelect;
