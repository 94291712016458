import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_HOST_URL}/graphql`,
});

const authLink = setContext(() => {
  let tokenParam = window.location.href.split('JWT=')[1];
  // console.log('tokenParam', tokenParam);
  if (tokenParam) {
    localStorage.setItem('jwtToken', tokenParam);
    window.location.href = '/';
  }

  const token = localStorage.getItem('jwtToken');
  // console.log(token);
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false }),
});
