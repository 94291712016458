import React, { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { checkPrefix } from '../../../utils/filterPrefixChecker';

interface Props {
  handleFilterClick: (filter: any) => void;
  loading: boolean;
  searchedFilters?: any;
  searchText?: string;
}
const InactiveFilters: FC<Props> = ({
  handleFilterClick,
  loading,
  searchedFilters,
  searchText,
}) => {
  const {
    properties: { selectedProperty },
    viewsList: { viewFilters, selectedView },
    filterList: { filters },
  } = useSelector((state: RootState) => state);

  return searchText ? (
    <ul>
      {searchedFilters.map((filter: any) => {
        const { name, prefix } = checkPrefix(
          filter.name,
          selectedProperty.label
        );
        if (!prefix) return null;
        return (
          <li
            key={filter.id}
            className={!prefix ? 'not-from-amdeas' : ''}
            onClick={() => handleFilterClick({ ...filter, isActive: true })}
          >
            {name}
          </li>
        );
      })}
    </ul>
  ) : (
    <ul>
      {!selectedProperty &&
        !selectedView &&
        filters.map((filter: any) => {
          const { name, prefix } = checkPrefix(
            filter.name,
            selectedProperty.label
          );
          if (!prefix) return null;
          return (
            <li
              key={filter.id}
              className={!prefix ? 'not-from-amdeas' : ''}
              onClick={() => handleFilterClick({ ...filter, isActive: false })}
            >
              {name}
            </li>
          );
        })}
      {/* selected property and no selected view render only with property prefix */}
      {selectedProperty &&
        !selectedView &&
        filters.map((filter: any) => {
          const { name, prefix, prefixType } = checkPrefix(
            filter.name,
            selectedProperty.label
          );
          if (!prefix || prefixType !== 'property') return null;

          return (
            <li
              key={filter.id}
              className={!prefix ? 'not-from-amdeas' : ''}
              onClick={() => handleFilterClick({ ...filter, isActive: false })}
            >
              {name}
            </li>
          );
        })}

      {selectedView &&
        !loading &&
        viewFilters.inactive.map((filter: any) => {
          const { name, prefix } = checkPrefix(
            filter.name,
            selectedProperty.label
          );
          if (!prefix) return null;
          return (
            <li
              key={filter.id}
              className={!prefix ? 'not-from-amdeas' : ''}
              onClick={() => handleFilterClick({ ...filter, isActive: false })}
            >
              {name}
            </li>
          );
        })}
    </ul>
  );
};

export default InactiveFilters;
