import React, { FC } from 'react';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { variants } from './variants';

interface Props {
  linkTo: string;
  icon?: any;
  title: string;
  onClick?: any;
}

const NavItem: FC<Props> = ({ linkTo, icon, title, onClick }): JSX.Element => {
  const menuExpand = useSelector((state: RootState) => state.menu.expand);
  return (
    <NavLink exact to={linkTo} onClick={onClick}>
      {icon && <Icon icon={icon} style={{ width: 40 }} />}
      <motion.span
        variants={variants}
        initial={menuExpand ? 'expanded' : 'expanded'}
        animate={menuExpand ? 'expanded' : 'collapsed'}>
        {title}
      </motion.span>
    </NavLink>
  );
};

export default NavItem;
