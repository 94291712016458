export const SET_USER = 'SET_USER';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

interface SetUserAction {
  type: typeof SET_USER;
  payload: any;
}
interface UserLoggedOutAction {
  type: typeof USER_LOGGED_OUT;
}
export type UserActionTypes = SetUserAction | UserLoggedOutAction;
