import React, { FC } from 'react';
import './styles.css';

const Loader: FC = (): JSX.Element => {
  return (
    <div className='wrapper'>
      <div className='loader'>
        <div className='loader-inner line-scale-pulse-out-rapid'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default Loader;
