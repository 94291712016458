import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import InputField from '../../textField/InputField';
import { motion } from 'framer-motion';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import FilterDetails from './FilterDetails';
import TypeToggler from './TypeToggler';

interface Props {
  spamList: string[];
  setSpamList: any;
  showPageNumber: number;
  filterType: string;
  setFilterType: any;
}

const ExcludeSpamSource: FC<Props> = ({
  spamList,
  setSpamList,
  showPageNumber,
  filterType,
  setFilterType,
}): JSX.Element | null => {
  const { selectedFilter } = useSelector(
    (state: RootState) => state.filterList,
    shallowEqual
  );
  if (
    !selectedFilter ||
    selectedFilter.label !== 'Exclude Crawler and Referral Spam'
  )
    return null;
  const handleAddSpam = () => {
    setSpamList([...spamList, '']);
  };
  const inputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    spamList[index] = e.target.value;
    setSpamList([...spamList]);
  };

  if (showPageNumber === 1)
    return <FilterDetails details={selectedFilter.description} />;

  if (showPageNumber === 2)
    return (
      <motion.div initial={{ x: 400 }} animate={{ x: 0 }} exit={{ x: -400 }}>
        <TypeToggler
          filterType={filterType}
          setFilterType={setFilterType}
          fieldTypeLabel='Filter Type'
          leftButtonLabel='Include'
          rightButtonLabel='Exclude'
        />
        <div className='field-and-button ExcludeSpamSource'>
          <h6 className='filter-label'>Add spam source</h6>
          {spamList.map((directoryPagePath: string, index: number) => {
            return (
              <InputField
                type='text'
                value={directoryPagePath}
                onChange={(e: any) => inputHandler(e, index)}
                key={index}
                className='ip-field'
              />
            );
          })}
          <Button variant='secondary' onClick={handleAddSpam}>
            +
          </Button>
          <h6 className='filter-label'>Restricted spam sources</h6>
          <div className='spam-list'>
            {/* {spamList.map((spam) => {
            if (spam !== '') {
              return (
                <Form.Check
                  custom
                  type='checkbox'
                  label={spam}
                  id={spam}
                  key={spam}
                  onChange={checkboxChangeHandler}
                />
              );
            }
          })} */}
          </div>
        </div>
      </motion.div>
    );
  return null;
};

export default ExcludeSpamSource;
