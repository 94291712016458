import React, { FC } from 'react';
import { Dropdown } from 'react-bootstrap';
import { userLoggedOutAction } from '../../actions/user/userAction';
import { RootState } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import './styles.css';
import { Icon } from '@iconify/react';
import notificationSolidBadged from '@iconify/icons-clarity/notification-solid-badged';
import googleAnalytics from '@iconify/icons-cib/google-analytics';
import { motion } from 'framer-motion';
import arrowsExchange from '@iconify/icons-gg/arrows-exchange';
import signOut from '@iconify/icons-octicon/sign-out';

const Profile: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.removeItem('jwtToken');
    dispatch(userLoggedOutAction());
  };
  const user = useSelector((state: RootState) => state.user.user);
  return (
    <div className='profile-wrapper'>
      <div className='profile flex'>
        <div className='heading'>
          <div className='analytics-icon-container'>
            <Icon icon={googleAnalytics} className='nav-toggle-icon' />
          </div>
          <h1>Analytics</h1>
        </div>
        {user && (
          <div className='profile-and-notification'>
            <Icon icon={notificationSolidBadged} className='notification-icon' />
            <Dropdown className='profile-dropdown'>
              <Dropdown.Toggle id='dropdown-basic' as='span'>
                <div className='account-settings flex'>
                  <div className='profile-picture-container'>
                    <img src={user.picture} alt='user profile' />
                  </div>
                  {/* <div className='profile-details'>
                    <h2>{user.username}</h2>
                    <h6>{user.email}</h6>
                  </div> */}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className='account-menu'>
                <motion.div>
                  <div className='account-settings'>
                    <div className='profile-picture-container'>
                      <img src={user.picture} alt='user profile' />
                    </div>
                    <div className='profile-details'>
                      <h2>{user.username}</h2>
                      <h6>{user.email}</h6>
                    </div>
                  </div>
                  <div className='change-account'>
                    <a
                      href={`${process.env.REACT_APP_API_HOST_URL}/api/auth/google`}>
                      <Icon icon={arrowsExchange} />
                      Change Account
                    </a>
                  </div>
                  <div className='logout-link'>
                    <div onClick={logout}>
                      <Icon icon={signOut} />
                      Sign Out
                    </div>
                  </div>
                </motion.div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
