import React from 'react';
const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

const Upcoming = () => {
  return (
    <div style={styles}>
      <img src='/image/upcoming.svg' alt='upcoming' />
    </div>
  );
};

export default Upcoming;
