import React, { FC } from 'react';

interface Props {
  detailsOf: string;
  data: any;
}
const DetailsTable: FC<Props> = ({ detailsOf, data }): JSX.Element => {
  return (
    <table>
      <tbody>
        <tr>
          <th></th>
          <th>{detailsOf}</th>
          <th>Users</th>
          <th>New Users</th>
        </tr>

        {data &&
          data.users &&
          data.users.map((user: any, i: number) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{user.propertyName}</td>
              <td>{user.count}</td>
              <td>{data.newUsers[i].count}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default DetailsTable;
