import React, { useState, useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../reducers';
import { closeModalAction } from '../../actions/modal/modalAction';
import { Form, Button } from 'react-bootstrap';
import InputField from '../textField/InputField';
import PopUp from '.';
import { useMutation } from '@apollo/react-hooks';
import CustomToast from '../CustomToast';
import Loader from '../Loader';
import CustomSelect from '../textField/CustomSelect';
import {
  addViewAction,
  updateViewAction,
} from '../../actions/viewsList/viewsListAction';
import { ADD_VIEW_MUTATION, UPDATE_VIEW_MUTATION } from '../../graphql/view';
interface View {
  name: string;
  websiteUrl?: string;
  timezone: any;
  eCommerceTracking: any;
  currency: any;
  type: any;
  botFilteringEnabled: any;
}

const AddOrUpdateView: FC = (): JSX.Element => {
  const [toast, setToast] = useState({ type: '', message: '' });
  const modalType = useSelector((state: RootState) => state.modal.modalType);

  const [newView, setNewView] = useState<View>({
    timezone: '',
    eCommerceTracking: '',
    currency: '',
    type: '',
    botFilteringEnabled: '',
    name: '',
    websiteUrl: '',
  });
  const dispatch = useDispatch();

  const [customSelectList] = useState<any>([
    {
      label: 'Type',
      name: 'type',
      value: newView.type,
      options: [
        { value: 'WEB', label: 'WEB' },
        { value: 'APP', label: 'APP' },
      ],
    },
    {
      label: 'Timezone',
      name: 'timezone',
      value: newView.timezone,
      options: [
        { value: 'Asia/Dhaka', label: 'Asia/Dhaka' },
        { value: 'America/Los_Angeles', label: 'America/Los_Angeles' },
      ],
    },
    {
      label: 'eCommerce Tracking',
      name: 'eCommerceTracking',
      value: newView.eCommerceTracking,
      options: [
        { value: true, label: 'true' },
        { value: false, label: 'false' },
      ],
    },
    {
      label: 'Currency',
      name: 'currency',
      value: newView.currency,
      options: [
        { value: 'USD', label: 'USD' },
        { value: 'GBP', label: 'GBP' },
        { value: 'EUR', label: 'EUR' },
      ],
    },
    {
      label: 'Bot Filtering Enable',
      name: 'botFilteringEnabled',
      value: newView.botFilteringEnabled,
      options: [
        { value: true, label: 'true' },
        { value: false, label: 'false' },
      ],
    },
  ]);

  const selectedAccount = useSelector(
    (state: RootState) => state.accountList.selectedAccount
  );
  const { selectedProperty } = useSelector(
    (state: RootState) => state.properties
  );
  const { selectedView, views } = useSelector(
    (state: RootState) => state.viewsList
  );

  useEffect(() => {
    if (modalType === 'editView') {
      const selectedViewDetails = views.find(
        (prop: any) => prop.id === selectedView.value
      );
      let options: any = [];
      Object.keys(selectedViewDetails).map((key) => {
        if (key === 'name' || key === 'websiteUrl') {
          const formattedData = { [key]: selectedViewDetails[key] };
          options = { ...options, ...formattedData };
          return setNewView(options);
        }
        const listToIgnore: string[] = [
          'accountId',
          'websiteUrl',
          'name',
          'id',
          'internalWebPropertyId',
        ];
        if (listToIgnore.includes(key)) return;
        const formattedData = {
          [key]: {
            label: selectedViewDetails[key] + '',
            value: selectedViewDetails[key],
          },
        };
        options = { ...options, ...formattedData };
        return setNewView(options);
      });
    }
    // eslint-disable-next-line
  }, [modalType]);

  const [addView, { loading: loadingAddView }] = useMutation(
    ADD_VIEW_MUTATION,
    {
      onError(err) {
        err.graphQLErrors[0] &&
          setToast({ type: 'fail', message: err.graphQLErrors[0].message });
      },
    }
  );

  const [updateView, { loading: loadingUpdateView }] = useMutation(
    UPDATE_VIEW_MUTATION,
    {
      variables: {
        accountId: selectedAccount.value,
        propertyId: selectedProperty.value,
        viewId: selectedView.value,
        ...newView,
        timezone: newView.timezone.value,
        eCommerceTracking: newView.eCommerceTracking.value,
        currency: newView.currency.value,
        type: newView.type.value,
        botFilteringEnabled: newView.botFilteringEnabled.value,
      },
      onError(err) {
        err.graphQLErrors[0] &&
          setToast({ type: 'fail', message: err.graphQLErrors[0].message });
      },
      update(_, result) {
        dispatch(closeModalAction());
        setToast({ type: 'success', message: 'View Updated' });
        setNewView({
          name: '',
          websiteUrl: '',
          timezone: '',
          eCommerceTracking: '',
          currency: '',
          type: '',
          botFilteringEnabled: '',
        });
        dispatch(updateViewAction(result.data.updateView));
      },
    }
  );

  // view modal submit handler
  const submitHandler = (e: any) => {
    e.preventDefault();
    if (newView.type.value === 'APP') {
      delete newView.websiteUrl;
    }
    if (modalType === 'addView')
      addView({
        variables: {
          accountId: selectedAccount.value,
          propertyId: selectedProperty.value,
          ...newView,
          timezone: newView.timezone.value,
          eCommerceTracking: newView.eCommerceTracking.value,
          currency: newView.currency.value,
          type: newView.type.value,
          botFilteringEnabled: newView.botFilteringEnabled.value,
        },

        update(_, result) {
          dispatch(closeModalAction());
          setToast({ type: 'success', message: 'View Added' });
          setNewView({
            name: '',
            websiteUrl: '',
            timezone: '',
            eCommerceTracking: '',
            currency: '',
            type: '',
            botFilteringEnabled: '',
          });
          dispatch(addViewAction(result.data.addView));
        },
      });

    if (modalType === 'editView') updateView();
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setNewView({ ...newView, [e.target.name]: e.target.value });
  };
  const selectChangeHandler = (newValue: any, actionMeta: any) => {
    setNewView({ ...newView, [actionMeta.name]: newValue });
  };

  const closeHandler = () => {
    dispatch(closeModalAction());
    setNewView({
      name: '',
      websiteUrl: '',
      timezone: '',
      eCommerceTracking: '',
      currency: '',
      type: '',
      botFilteringEnabled: '',
    });
  };

  let addOrUpdateView;
  const { name, websiteUrl } = newView;
  if (modalType === 'addView' || modalType === 'editView') {
    addOrUpdateView = (
      <PopUp
        modalTitle={modalType === 'addView' ? 'Add View' : 'Update View'}
        closeHandler={closeHandler}
      >
        <Form onSubmit={submitHandler}>
          <InputField
            type='text'
            name='name'
            placeholder='Enter View name'
            value={name}
            onChange={changeHandler}
            label='View Name'
          />

          <InputField
            type='text'
            name='websiteUrl'
            placeholder='Enter Website URL'
            value={websiteUrl ? websiteUrl : ''}
            onChange={changeHandler}
            label='Website URL'
          />
          {customSelectList.map((customSelect: any) => (
            <CustomSelect
              key={customSelect.name}
              label={customSelect.label}
              name={customSelect.name}
              options={customSelect.options}
              changeHandler={selectChangeHandler}
              values={newView}
            />
          ))}
          <div className='right-modal-bottom-btn'>
            <Button
              variant='primary'
              className='back-btn'
              onClick={closeHandler}
            >
              Back
            </Button>
            <Button variant='primary' type='submit'>
              {modalType === 'addView' ? 'Add View' : 'Update View'}
            </Button>
          </div>
        </Form>
      </PopUp>
    );
  }

  return (
    <>
      {(loadingAddView || loadingUpdateView) && <Loader />}
      <CustomToast
        header={toast.type}
        message={toast.message}
        setToast={setToast}
      />
      {addOrUpdateView}
    </>
  );
};

export default AddOrUpdateView;
