import { SET_USER, UserActionTypes } from '../actions/user/types';

interface UserState {
  user: any;
}

const initialState: UserState = {
  user: null,
};

export default (state = initialState, action: UserActionTypes) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };

    default:
      return state;
  }
};
