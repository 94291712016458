import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { RootState } from '../../../store';
import { openModalAction } from '../../../actions/modal/modalAction';
import { setSelectedViewAction } from '../../../actions/viewsList/viewsListAction';
import FilterSearch from '../FilterSearch';
import CustomToast from '../../CustomToast';
import AddOrUpdateView from '../../popUp/AddOrUpdateView';

const AdminViews: FC = (): JSX.Element => {
  const [toast, setToast] = useState({ type: '', message: '' });
  const [isFilterSearch, setIsFilterSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedViews, setSearchedViews] = useState([]);
  const dispatch = useDispatch();

  const {
    accountList: { selectedAccount },
    properties: { selectedProperty },
    viewsList: { selectedView, views, defaultView },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    const filteredResult = views.filter((filter: any) =>
      filter.name.toLowerCase().includes(searchText.toLowerCase())
    );
    if (searchText) return setSearchedViews(filteredResult);
    return setSearchedViews([]);
    // eslint-disable-next-line
  }, [searchText, selectedAccount, selectedProperty, selectedView]);

  // Modal
  const addButtonHandler = () => {
    if (!selectedAccount || !selectedProperty)
      return setToast({
        type: 'fail',
        message: 'Select An Account & Property First',
      });
    dispatch(openModalAction('addView'));
  };
  // Modal

  // searchFilter
  const showSearchInputHandler = (): void => setIsFilterSearch(true);
  const closeFilterHandler = (): void => {
    setIsFilterSearch(false);
    setSearchText('');
    setSearchedViews([]);
  };
  // searchFilter

  const viewListHandler = (viewItem: any): void => {
    dispatch(
      setSelectedViewAction({ label: viewItem.name, value: viewItem.id })
    );
  };

  return (
    <>
      <CustomToast
        header={toast.type}
        message={toast.message}
        setToast={setToast}
      />
      <div className='views-area'>
        <div className='views-heading'>
          <FilterSearch
            title='Views'
            placeholder='Search Views...'
            closeFilterHandler={closeFilterHandler}
            isFilterSearch={isFilterSearch}
            showSearchInputHandler={showSearchInputHandler}
            addButtonHandler={addButtonHandler}
            changeHandler={setSearchText}
          />
        </div>
        <div className='views-filtering'>
          {!selectedProperty && views.length <= 0 && (
            <h6 className='not-selected'>Select a property first</h6>
          )}
          <ul>
            {searchText
              ? searchedViews.map((viewItem: any) => {
                  return (
                    <li
                      key={viewItem.id}
                      className={
                        selectedView.value === viewItem.id ? 'active' : ''
                      }
                      onClick={() => viewListHandler(viewItem)}
                      id={viewItem.id}
                    >
                      {viewItem.name}
                      {/* {selectedView.value === viewItem.id && (
                        <Icon icon={checkCircleFill} align='right' />
                      )} */}
                      {defaultView.defaultViewId === viewItem.id && (
                        <span className='default-view'>Default</span>
                      )}
                    </li>
                  );
                })
              : selectedProperty &&
                views.map((viewItem: any) => {
                  return (
                    <li
                      key={viewItem.id}
                      className={
                        selectedView.value === viewItem.id ? 'active' : ''
                      }
                      onClick={() => viewListHandler(viewItem)}
                      id={viewItem.id}
                    >
                      {viewItem.name}
                      {/* {selectedView.value === viewItem.id && (
                        <Icon icon={checkCircleFill} align='right' />
                      )} */}
                      {defaultView.defaultViewId === viewItem.id && (
                        <span className='default-view'>Default</span>
                      )}
                    </li>
                  );
                })}
          </ul>
        </div>
      </div>
      <AddOrUpdateView />
    </>
  );
};

export default AdminViews;
