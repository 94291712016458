import { SET_USER, USER_LOGGED_OUT, UserActionTypes } from './types';

export const setUserAction = (user: any): UserActionTypes => {
  return {
    type: SET_USER,
    payload: user,
  };
};
export const userLoggedOutAction = (): UserActionTypes => {
  return {
    type: USER_LOGGED_OUT,
  };
};
