export const GET_PROPERTY = 'GET_PROPERTY';
export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
export const CLEAR_SELECTED_PROPERTY = 'CLEAR_SELECTED_PROPERTY';
export const CLEAR_PROPERTIES = 'CLEAR_PROPERTIES';
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
interface GetPropertyAction {
  type: typeof GET_PROPERTY;
  payload: any;
}
interface AddPropertyAction {
  type: typeof ADD_PROPERTY;
  payload: any;
}

interface UpdatePropertyAction {
  type: typeof UPDATE_PROPERTY;
  payload: any;
}

interface SetSelectedPropertyAction {
  type: typeof SET_SELECTED_PROPERTY;
  payload: string;
}

interface ClearSelectedPropertyAction {
  type: typeof CLEAR_SELECTED_PROPERTY;
}
interface ClearPropertiesAction {
  type: typeof CLEAR_PROPERTIES;
}
export type PropertyActionTypes =
  | GetPropertyAction
  | SetSelectedPropertyAction
  | ClearSelectedPropertyAction
  | ClearPropertiesAction
  | AddPropertyAction
  | UpdatePropertyAction;
