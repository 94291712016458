import {
  GET_PROPERTY,
  PropertyActionTypes,
  SET_SELECTED_PROPERTY,
  CLEAR_SELECTED_PROPERTY,
  CLEAR_PROPERTIES,
  ADD_PROPERTY,
  UPDATE_PROPERTY,
} from '../actions/propertyList/types';
import { SET_DEFAULT_VIEW, ViewsActionTypes } from '../actions/viewsList/types';

export interface Property {
  id: string;
  kind: string;
  selfLink: string;
  accountId: string;
  internalWebPropertyId: string;
  name: string;
  websiteUrl: string;
  level: string;
  profileCount: null | number;
  industryVertical: string;
  defaultProfileId: string;
  dataRetentionTtl: string;
  dataRetentionResetOnNewActivity: null | boolean;
  permissions: {
    effective: string[];
  };
  created: string;
  updated: string;
  parentLink: {
    type: string;
    href: string;
  };
  childLink: {
    type: string;
    href: string;
  };
}

interface PropertyState {
  property: Property[];
  selectedProperty: any;
}

const initialState: PropertyState = {
  property: [],
  selectedProperty: '',
};

export default (
  state = initialState,
  action: PropertyActionTypes | ViewsActionTypes
) => {
  switch (action.type) {
    case GET_PROPERTY:
      return {
        ...state,
        property: action.payload,
      };
    case ADD_PROPERTY:
      return {
        ...state,
        property: [...state.property, action.payload],
      };
    case UPDATE_PROPERTY:
      return {
        ...state,
        property: state.property.map((prop) =>
          prop.id === action.payload.id ? action.payload : prop
        ),
      };
    case SET_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.payload,
      };
    case SET_DEFAULT_VIEW:
      const modifiedDefaultProp = state.property.filter((prop) => {
        if (prop.id === action.payload.propertyId) {
          prop.defaultProfileId = action.payload.defaultViewId;
          return prop;
        }
        return prop;
      });
      return {
        ...state,
        property: modifiedDefaultProp,
      };
    case CLEAR_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: '',
      };
    case CLEAR_PROPERTIES:
      return {
        ...state,
        property: [],
      };
    default:
      return state;
  }
};
