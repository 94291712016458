import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../../store';
// import fetchData from '../../utils/fetchData';

import {
  AccountListActionTypes,
  GET_ACCOUNT_LIST,
  SET_SELECTED_ACCOUNT,
} from './types';

// const ACCOUNT_LIST_API_ENDPOINT = '/accountList';

// get account list
export const getAccountAction = (
  accounts: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  // dispatching GET_ACCOUNT_LIST with fetched account List from api
  accounts && dispatch({ type: GET_ACCOUNT_LIST, payload: accounts });
};

export const setSelectedAccountAction = (account: any): AccountListActionTypes => {
  return {
    type: SET_SELECTED_ACCOUNT,
    payload: account,
  };
};
