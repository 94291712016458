import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { RootState } from '../../../store';
import { openModalAction } from '../../../actions/modal/modalAction';
import FilterSearch from '../FilterSearch';
import './style.css';
import CustomToast from '../../CustomToast';
import AddOrUpdateFilter from '../../popUp/Filter/AddOrUpdateFilter';
import FilterDetailsModal from '../../popUp/FilterDetailsModal';
import { Button } from 'react-bootstrap';
import {
  FETCH_CATEGORIZE_FILTERS_QUERY,
  FETCH_CURRENT_RECOMMENDED_FILTERS_QUERY,
} from '../../../graphql/view';
import { PROPERTY_RECOMMENDED_FILTERS_QUERY } from '../../../graphql/property';
import SkeletonLoader from '../../Loader/SkeletonLoader';
import { getViewFiltersAction } from '../../../actions/viewsList/viewsListAction';
import { checkPrefix } from '../../../utils/filterPrefixChecker';
import ActiveFilters from './ActiveFilters';
import InactiveFilters from './InactiveFilters';
import Icon from '@iconify/react';
import plusIcon from '@iconify/icons-entypo/plus';
import {
  setRecommendedFilterAction,
  setSelectedFilterAction,
} from '../../../actions/filterList/filterListAction';
import { useQuery } from '@apollo/react-hooks';
import { ACCOUNT_RECOMMENDED_FILTERS_QUERY } from '../../../graphql/account';

const AdminFilters: FC = () => {
  const dispatch = useDispatch();
  const [toast, setToast] = useState({ type: '', message: '' });
  const [searchText, setSearchText] = useState('');
  const [clickedFilter, setClickedFilter] = useState({});
  const [showFilter, setShowFilter] = useState('');
  const [isFilterSearch, setIsFilterSearch] = useState<boolean>(false);
  const [searchedFilters, setSearchedFilters] = useState([]);

  const {
    accountList: { selectedAccount },
    properties: { selectedProperty },
    viewsList: { selectedView, viewFilters },
    filterList: { filters },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    viewFilters.active.length > 0 && setShowFilter('Active');
  }, [viewFilters.active]);

  useEffect(() => {
    selectedAccount && setShowFilter('Inactive');
  }, [selectedAccount, viewFilters.inactive.length]);

  useEffect(() => {
    if (!selectedView && showFilter !== 'Recommended') {
      // console.log('searching on account filters');
      const filteredResult = filters.filter((filter: any) =>
        filter.name.toLowerCase().includes(searchText.toLowerCase())
      );
      if (filteredResult.length) return setSearchedFilters(filteredResult);
      setSearchedFilters([]);
    }
    if (showFilter === 'Active') {
      // console.log('searching on active');
      const filteredResult = viewFilters.active.filter((filter: any) =>
        filter.name.toLowerCase().includes(searchText.toLowerCase())
      );
      if (filteredResult.length) return setSearchedFilters(filteredResult);
      setSearchedFilters([]);
    }

    if (showFilter === 'Inactive') {
      // console.log('searching on inactive');
      const filteredResult = viewFilters.inactive.filter((filter: any) =>
        filter.name.toLowerCase().includes(searchText.toLowerCase())
      );
      if (filteredResult.length) return setSearchedFilters(filteredResult);
      setSearchedFilters([]);
    }

    if (showFilter === 'Recommended') {
      // console.log('searching on recommended');
      const filteredResult = viewFilters.recommended.filter((filter: any) =>
        filter.name.toLowerCase().includes(searchText.toLowerCase())
      );
      if (filteredResult.length) return setSearchedFilters(filteredResult);
      setSearchedFilters([]);
    }

    setSearchedFilters([]);
  }, [searchText, showFilter, selectedAccount, selectedProperty, selectedView]);

  // Modal
  const addFilterHandler = () => {
    if (!selectedAccount)
      return setToast({ type: 'fail', message: 'Select An Account First' });
    dispatch(openModalAction('addFilter'));
  };
  // Modal

  // searchFilter
  const showSearchInputHandler = () => setIsFilterSearch(true);
  const closeFilterHandler = (): void => {
    setIsFilterSearch(false);
    setSearchText('');
    setSearchedFilters([]);
  };
  // searchFilter

  const handleFilterClick = (filter: any): void => {
    setClickedFilter({ ...filter, showFilter });
    dispatch(openModalAction('filterDetails'));
  };

  const {
    loading: loadingCategorizeFilters,
    data: categorizeFiltersData,
  } = useQuery(FETCH_CATEGORIZE_FILTERS_QUERY, {
    variables: {
      accountId: selectedAccount.value,
      propertyId: selectedProperty.value,
      viewId: selectedView.value,
    },
    skip: !selectedAccount || !selectedProperty || !selectedView,
    onCompleted: () => {
      categorizeFiltersData &&
        dispatch(getViewFiltersAction(categorizeFiltersData.categorizeFilters));
    },
  });
  const {
    loading: loadingAccountRecommendedFilters,
    data: accountRecommendedFilters,
  } = useQuery(ACCOUNT_RECOMMENDED_FILTERS_QUERY, {
    variables: { accountId: selectedAccount.value },
    skip: !selectedAccount,
    onCompleted() {
      if (accountRecommendedFilters) {
        dispatch(
          setRecommendedFilterAction(
            accountRecommendedFilters.accountRecommendedFilters.recommended
          )
        );
      }
    },
  });
  const {
    loading: loadingPropertyRecommendedFilters,
    data: propertyRecommendedFilters,
  } = useQuery(PROPERTY_RECOMMENDED_FILTERS_QUERY, {
    variables: {
      accountId: selectedAccount.value,
      propertyId: selectedProperty.value,
    },
    skip: !selectedAccount || !selectedProperty,
    onCompleted() {
      if (propertyRecommendedFilters) {
        dispatch(
          setRecommendedFilterAction(
            propertyRecommendedFilters.propertyRecommendedFilters.recommended
          )
        );
      }
    },
  });

  const {
    loading: loadingCurrentRecommended,
    data: currentRecommended,
  } = useQuery(FETCH_CURRENT_RECOMMENDED_FILTERS_QUERY, {
    variables: {
      accountId: selectedAccount.value,
      propertyId: selectedProperty.value,
      viewId: selectedView.value,
    },
    skip: !selectedAccount || !selectedProperty || !selectedView,
    onCompleted: () => {
      if (
        currentRecommended &&
        currentRecommended.currentRecommended.recommended
      ) {
        dispatch(
          setRecommendedFilterAction(
            currentRecommended.currentRecommended.recommended
          )
        );
      }
    },
  });

  const addViewFilterHandler = (filter: any) => {
    filter.label = filter.name;
    filter.value = filter.name;
    dispatch(setSelectedFilterAction(filter));
    addFilterHandler();
  };

  return (
    <>
      <CustomToast
        header={toast.type}
        message={toast.message}
        setToast={setToast}
      />
      <div className='filter-area'>
        <div className='filter-heading'>
          <FilterSearch
            title='Filters'
            placeholder='Search Filters...'
            closeFilterHandler={closeFilterHandler}
            isFilterSearch={isFilterSearch}
            showSearchInputHandler={showSearchInputHandler}
            addButtonHandler={addFilterHandler}
            changeHandler={setSearchText}
          />
        </div>
        <div className='filter-cat-btn'>
          {selectedView && (
            <Button
              onClick={(e: any) => setShowFilter('Active')}
              className={showFilter === 'Active' ? 'active' : ''}
            >
              Active
            </Button>
          )}
          <Button
            onClick={(e: any) => setShowFilter('Inactive')}
            className={showFilter === 'Inactive' ? 'active' : ''}
          >
            {selectedProperty && !selectedView
              ? 'Property Filters'
              : selectedProperty || selectedView
              ? 'Inactive'
              : 'All Filters'}
          </Button>
          <Button
            onClick={(e: any) => setShowFilter('Recommended')}
            className={
              showFilter === 'Recommended'
                ? 'recommended active'
                : 'recommended'
            }
          >
            recommended
          </Button>
        </div>
        <div className='filter-filtering'>
          {/* selected account only then show all account filters */}
          {/* selected Property then show only those filter which is start with property prefix */}
          {/* selected view then show view filters */}
          {showFilter === 'Active' && (
            <ActiveFilters
              handleFilterClick={handleFilterClick}
              searchedFilters={searchedFilters}
              searchText={searchText}
            />
          )}

          {showFilter === 'Inactive' && (
            // no selected property and no selected view then render account filters
            <InactiveFilters
              handleFilterClick={handleFilterClick}
              loading={loadingCategorizeFilters}
              searchedFilters={searchedFilters}
              searchText={searchText}
            />
          )}
          {showFilter === 'Recommended' && (
            <>
              {loadingCurrentRecommended ? (
                <SkeletonLoader />
              ) : (
                <ul>
                  {searchText
                    ? searchedFilters.map((filter: any) => {
                        const { name } = checkPrefix(
                          filter.name,
                          selectedProperty.label
                        );
                        return (
                          <li key={filter.name} className='recommended-filter'>
                            <span onClick={() => addViewFilterHandler(filter)}>
                              <Icon
                                icon={plusIcon}
                                className='recommended-plus-icon'
                              />
                            </span>
                            <span onClick={() => handleFilterClick(filter)}>
                              {name}
                            </span>
                          </li>
                        );
                      })
                    : viewFilters.recommended.map((filter: any) => {
                        const { name } = checkPrefix(
                          filter.name,
                          selectedProperty.label
                        );
                        return (
                          <li key={filter.name} className='recommended-filter'>
                            <span onClick={() => addViewFilterHandler(filter)}>
                              <Icon
                                icon={plusIcon}
                                className='recommended-plus-icon'
                              />
                            </span>
                            <span onClick={() => handleFilterClick(filter)}>
                              {name}
                            </span>
                          </li>
                        );
                      })}
                </ul>
              )}
            </>
          )}
        </div>
      </div>

      <FilterDetailsModal filter={clickedFilter} />
      {/* AddOrUpdateFilter Modal */}
      <AddOrUpdateFilter />
    </>
  );
};

export default AdminFilters;
