import React, { FC, Fragment } from "react";
import { Table } from "react-bootstrap";

interface details {
	label: string;
	value: string | undefined;
}
interface Props {
	details: details[];
}

const DetailTable: FC<Props> = ({ details }): JSX.Element => {
	return (
		<Table className="item-table">
			<tbody>
				{details.map((detail, index) => (
					<Fragment key={index}>
						<tr>
							<td className="table-heading">{detail.label}</td>
							<td>{detail.value}</td>
						</tr>
						<tr className="row-space"></tr>
					</Fragment>
				))}
			</tbody>
		</Table>
	);
};

export default DetailTable;
