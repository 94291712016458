import React, { FC } from 'react';
import Highcharts from 'highcharts/highstock';
import PieChart from 'highcharts-react-official';

interface Props {
  fail: number;
  total: number;
}

const HighChart: FC<Props> = ({ fail, total }) => {
  const options = {
    chart: {
      type: 'pie',
      height: 45,
      width: 45,
      backgroundColor: 'transparent',
      spacing: 0,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      yAxis: {
        stackLabels: {
          enabled: true,
        },
      },
    },
    colors: ['#917BD9', '#FF596A'],
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        slicedOffset: 2,
      },
    },
    series: [
      {
        data: [
          {
            y: total - fail,
            sliced: true,
          },
          {
            y: fail,
            // sliced: true,
          },
        ],
      },
    ],
  };
  return (
    <div className='highChart'>
      <PieChart highcharts={Highcharts} options={options} />
    </div>
  );
};

export default HighChart;
