import React, { FC } from 'react';
import DateSelector from './DateSelector';
import SelectWithCheckbox from './SelectWithCheckbox';
import { optionsFormatter } from './optionsFormatter';
import { channels, continents, devices, regions } from '../selectOptions.json';
import AccountSelector from '../../main-content/accountSelector';
import PropertySelector from '../../main-content/propertySelector';
import ViewSelector from '../../main-content/viewSelector';

interface Props {
  state: any;
  setState: any;
  checkedContinent: string[];
  checkedRegion: string[];
  checkedChannel: string[];
  checkedDevice: string[];
  setCheckedContinent: any;
  setCheckedRegion: any;
  setCheckedChannel: any;
  setCheckedDevice: any;
}
const Selectors: FC<Props> = ({
  state,
  setState,
  checkedChannel,
  checkedContinent,
  checkedDevice,
  checkedRegion,
  setCheckedChannel,
  setCheckedContinent,
  setCheckedDevice,
  setCheckedRegion,
}): JSX.Element => {
  const continentOptions = optionsFormatter(continents);
  const regionOptions = optionsFormatter(regions);
  const channelOptions = optionsFormatter(channels);
  const deviceOptions = optionsFormatter(devices);

  return (
    <div className='selectors'>
      <AccountSelector />
      <PropertySelector />
      <ViewSelector />
      <SelectWithCheckbox
        state={checkedContinent}
        setState={setCheckedContinent}
        type='Continent'
        options={continentOptions}
      />
      <SelectWithCheckbox
        state={checkedRegion}
        setState={setCheckedRegion}
        type='Region'
        options={regionOptions}
      />
      <SelectWithCheckbox
        state={checkedChannel}
        setState={setCheckedChannel}
        type='Channel'
        options={channelOptions}
      />
      <SelectWithCheckbox
        state={checkedDevice}
        setState={setCheckedDevice}
        type='Device'
        options={deviceOptions}
      />

      <DateSelector state={state} setState={setState} />
    </div>
  );
};

export default Selectors;
