import React, { FC } from 'react';
import { Nav } from 'react-bootstrap';

interface Props {
  eventKey: string;
  title: string;
}

const TabItem: FC<Props> = ({ eventKey, title }): JSX.Element => {
  return (
    <Nav.Item>
      <Nav.Link eventKey={eventKey} as='button'>
        {title}
      </Nav.Link>
    </Nav.Item>
  );
};

export default TabItem;
