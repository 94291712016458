const filters = [
  {
    auditItem: 'Property ID',
    isSet: '',
    name: 'propertyId',
    details: 'blah blah property id',
    type: 'property',
  },
  {
    auditItem: 'Property Name',
    isSet: '',
    name: 'propertyName',
    details: 'blah blah property name',
    type: 'property',
  },
  {
    auditItem: 'Website URL',
    isSet: '',
    name: 'websiteUrl',
    details: 'blah blah website url',
    type: 'property',
  },
  {
    auditItem: 'Default View',
    isSet: '',
    name: 'defaultProfileId',
    details: 'blah blah default view',
    type: 'property',
  },
  {
    auditItem: 'dataRetentionTtl',
    isSet: '',
    name: 'dataRetentionTtl',
    details: 'blah blah dataRetentionTtl',
    type: 'property',
  },
  {
    auditItem: 'Audiences',
    isSet: '',
    name: 'audiences',
    details: 'blah blah audiences',
    type: 'property',
  },
  {
    auditItem: 'Google Ads Linking',
    isSet: '',
    name: 'googleAdsLinking',
    details: 'blah blah googleAdsLinking',
    type: 'property',
  },

  {
    auditItem: 'View ID',
    isSet: '',
    name: 'id',
    details: 'blah blah view id',
    type: 'view',
  },
  {
    auditItem: 'View Name',
    isSet: '',
    name: 'name',
    details: 'blah blah view name',
    type: 'view',
  },
  {
    auditItem: 'Time Zone',
    isSet: '',
    name: 'timezone',
    details: 'blah blah view timezone',
    type: 'view',
  },
  {
    auditItem: 'Currency',
    isSet: '',
    name: 'currency',
    details: 'blah blah view currency',
    type: 'view',
  },
  {
    auditItem: 'Bot Filtering',
    isSet: '',
    name: 'botFilteringEnabled',
    details: 'blah blah bot filtering',
    type: 'view',
  },
  {
    auditItem: 'Have Goals Been Set Up',
    isSet: '',
    name: 'goals',
    details: 'blah blah goals been set up',
    type: 'view',
  },
  {
    auditItem: 'Have Filters Been Set Up',
    isSet: '',
    name: 'viewFilters',
    details: 'blah blah filter been set up',
    type: 'view',
  },
  {
    auditItem: 'excludeIpAddress',
    isSet: '',
    name: 'excludeIpAddress',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'excludeHostname',
    isSet: '',
    name: 'excludeHostname',
    details: 'blah blah filter been set up',
    type: 'filter',
  },

  {
    auditItem: 'excludeCampaignSource',
    isSet: '',
    name: 'excludeCampaignSource',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'includeHostname',
    isSet: '',
    name: 'includeHostname',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'includeCountry',
    isSet: '',
    name: 'includeCountry',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'includeMobileTraffic',
    isSet: '',
    name: 'includeMobileTraffic',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'includeTabletTraffic',
    isSet: '',
    name: 'includeTabletTraffic',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'includeDesktopTraffic',
    isSet: '',
    name: 'includeDesktopTraffic',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'lowercaseCampaignName',
    isSet: '',
    name: 'lowercaseCampaignName',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'lowercaseCampaignSource',
    isSet: '',
    name: 'lowercaseCampaignSource',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'lowercaseCampaignMedium',
    isSet: '',
    name: 'lowercaseCampaignMedium',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'lowercaseCampaignTerm',
    isSet: '',
    name: 'lowercaseCampaignTerm',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'lowercaseCampaignContent',
    isSet: '',
    name: 'lowercaseCampaignContent',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'lowercaseSearchTerm',
    isSet: '',
    name: 'lowercaseSearchTerm',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'lowercasePagePath',
    isSet: '',
    name: 'lowercasePagePath',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'combineFacebookSources',
    isSet: '',
    name: 'combineFacebookSources',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'combineInstagramSources',
    isSet: '',
    name: 'combineInstagramSources',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'combinePinterestSources',
    isSet: '',
    name: 'combinePinterestSources',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'combineTwitterSources',
    isSet: '',
    name: 'combineTwitterSourcescombineLinkedSources',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'combineLinkedSources',
    isSet: '',
    name: 'combineLinkedSources',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'removeQueryParams',
    isSet: '',
    name: 'removeQueryParams',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'simplifyLongPagePath',
    isSet: '',
    name: 'simplifyLongPagePath',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'consolidateHostnames',
    isSet: '',
    name: 'consolidateHostnames',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'numberWithNamesInPaths',
    isSet: '',
    name: 'numberWithNamesInPaths',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'removeHtmlEXTFromPath',
    isSet: '',
    name: 'removeHtmlEXTFromPath',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'viewFullPageURL',
    isSet: '',
    name: 'viewFullPageURL',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'mediumReferralToSocial',
    isSet: '',
    name: 'mediumReferralToSocial',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'removeTextBeforePipe',
    isSet: '',
    name: 'removeTextBeforePipe',
    details: 'blah blah filter been set up',
    type: 'filter',
  },

  {
    auditItem: 'appendSlashToPagePath',
    isSet: '',
    name: 'appendSlashToPagePath',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
  {
    auditItem: 'removeSlashFromPaths',
    isSet: '',
    name: 'removeSlashFromPaths',
    details: 'blah blah filter been set up',
    type: 'filter',
  },
];
export default filters;
