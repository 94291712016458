import React, { FC, useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { formatDate } from './Selectors/optionsFormatter';
interface Props {
  startDate: string;
}
const Timeline: FC<Props> = ({ startDate }): JSX.Element => {
  const { timelineData } = useSelector(
    (state: RootState) => state.reporting.userBehavior
  );
  const [data, setData] = useState([
    ['Date', 'User'],
    [new Date(startDate.replaceAll('-', ',')), 0],
  ]);
  useEffect(() => {
    const tempData =
      timelineData.length &&
      timelineData[0].metricValue.map((value: any) => {
        return [new Date(formatDate(value.dimensionName[0])), value.value];
      });
    tempData &&
      setData([
        ['Date', 'User'],
        [new Date(startDate.replaceAll('-', ',')), 0],
        ...tempData,
      ]);
  }, [timelineData]);
  return (
    <div>
      <Chart
        width={'100%'}
        height={'200px'}
        chartType='AreaChart'
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          showRowNumber: true,
        }}
        rootProps={{ 'data-testid': '1' }}
      />
    </div>
  );
};

export default Timeline;
