import React, { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { RootState } from '../../../reducers';
import { useSelector, shallowEqual } from 'react-redux';

const FullDoughnutChart: FC = (): JSX.Element => {
  const { failCounter, resultType } = useSelector(
    (state: RootState) => state.audit,
    shallowEqual
  );

  const overviewData = {
    labels: ['Failed', 'Success', 'Not satisfied'],
    datasets: [
      {
        data: [
          failCounter[resultType].fail,
          failCounter[resultType].total - failCounter[resultType].fail,
          0,
        ],
        backgroundColor: ['#FF596A', '#21BF73', '#FFD271'],
        hoverBackgroundColor: ['#FF6384', '#11d878', '#FFCE56'],
      },
    ],
  };
  const options = {
    legend: {
      display: false,
    },
  };

  return (
    <div className='overview-wrapper'>
      <Doughnut data={overviewData} options={options} />
      <div className='overview-chart-text'>
        <div className='success-overview'>
          <span className=''>Success</span>
          <span className='overview-percent success'>
            {(
              (100 / failCounter[resultType].total) *
              (failCounter[resultType].total - failCounter[resultType].fail)
            ).toFixed(2)}{' '}
            %
          </span>
        </div>
        <div className='not-satisfied-overview'>
          <span className=''>Not satisfied</span>
          <span className='overview-percent not-satisfied'>0%</span>
        </div>
        <div className='failed-overview'>
          <span className=''>Failed</span>
          <span className='overview-percent failed'>
            {(
              (100 / failCounter[resultType].total) *
              failCounter[resultType].fail
            ).toFixed(2)}{' '}
            %
          </span>
        </div>
      </div>
    </div>
  );
};

export default FullDoughnutChart;
