import { OPEN_MODAL, CLOSE_MODAL, ModalActionTypes } from '../actions/modal/types';

interface ModalState {
  isModalOpen: boolean;
  modalType: string;
}

const initialState: ModalState = {
  isModalOpen: false,
  modalType: '',
};

export default (state = initialState, action: ModalActionTypes) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
        modalType: action.payload,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        modalType: '',
      };
    default:
      return state;
  }
};
