import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { faPlus, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';

interface Props {
  title: string;
  placeholder: string;
  isFilterSearch: boolean;
  addButtonHandler: any;
  showSearchInputHandler: any;
  closeFilterHandler: any;
  changeHandler: any;
}

const FilterSearch: FC<Props> = ({
  title,
  placeholder,
  isFilterSearch,
  addButtonHandler,
  closeFilterHandler,
  showSearchInputHandler,
  changeHandler,
}) => {
  if (isFilterSearch) {
    return (
      <Form className='searchFilter'>
        <Form.Control
          type='text'
          placeholder={placeholder}
          onChange={(e) => changeHandler(e.target.value)}
        />
        <button className='searchBtn' onClick={closeFilterHandler}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Form>
    );
  } else {
    return (
      <>
        <div>
          <h3>{title}</h3>
        </div>
        <div className='btns'>
          <button onClick={showSearchInputHandler}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
          <button onClick={addButtonHandler}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </>
    );
  }
};

export default FilterSearch;
