import React, { FC } from 'react';
import { motion } from 'framer-motion';
import TypeToggler from './TypeToggler';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import Select from 'react-select';
import { formatCountry } from '../../../utils/countryList';
import FilterDetails from './FilterDetails';

interface Props {
  accessType: string;
  setAccessType: (filterType: string) => void;
  countries: any;
  setCountries: any;
  showPageNumber: number;
}
const countryList = formatCountry();
const TargetCountriesReporting: FC<Props> = ({
  accessType,
  setAccessType,
  countries,
  setCountries,
  showPageNumber,
}): JSX.Element | null => {
  const { selectedFilter } = useSelector(
    (state: RootState) => state.filterList,
    shallowEqual
  );
  if (!selectedFilter || selectedFilter.label !== 'Limit Countries') return null;

  const countryChangeHandler = (country: any, actionMeta: any) => {
    setCountries(country);
  };
  if (showPageNumber === 1)
    return <FilterDetails details={selectedFilter.description} />;

  if (showPageNumber === 2)
    return (
      <motion.div initial={{ x: 400 }} animate={{ x: 0 }} exit={{ x: -400 }}>
        <TypeToggler
          filterType={accessType}
          setFilterType={setAccessType}
          fieldTypeLabel='Access Type'
          leftButtonLabel='Allow'
          leftButtonValue='Include'
          rightButtonLabel='Restrict'
          rightButtonValue='Exclude'
        />

        <div className='field-and-button'>
          <h6 className='filter-label text-uppercase'>select countries</h6>
          <Select
            options={countryList}
            isMulti
            onChange={countryChangeHandler}
            value={countries}
          />
        </div>
      </motion.div>
    );
  return null;
};

export default TargetCountriesReporting;
