import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../../store';
import './style.css';

interface Props {
  modalTitle: string;
  children: React.ReactNode;
  closeHandler: () => void;
}

const PopUp: FC<Props> = ({ modalTitle, children, closeHandler }) => {
  const isModalOpen = useSelector((state: RootState) => state.modal.isModalOpen);

  return (
    <Modal
      dialogClassName='modal-dialog'
      show={isModalOpen}
      onHide={closeHandler}
      className='modalContent right'>
      <Modal.Header>
        <Modal.Title className='modalHeader'>
          {modalTitle}
          <button className='float-right' onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default PopUp;
