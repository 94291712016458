export const GET_VIEWS = 'GET_VIEWS';
export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';
export const CLEAR_SELECTED_VIEW = 'CLEAR_SELECTED_VIEW';
export const SET_DEFAULT_VIEW = 'SET_DEFAULT_VIEW';
export const ADD_VIEW = 'ADD_VIEW';
export const UPDATE_VIEW = 'UPDATE_VIEW';
export const DELETE_VIEW = 'DELETE_VIEW';
export const CLEAR_VIEWS = 'CLEAR_VIEWS';
export const GET_VIEW_FILTERS = 'GET_VIEW_FILTERS';
export const CLEAR_VIEW_FILTERS = 'CLEAR_VIEW_FILTERS';
export const ENABLE_FILTER = 'ENABLE_FILTER';
export const DISABLE_FILTER = 'DISABLE_FILTER';

interface EnableFilter {
  type: typeof ENABLE_FILTER;
  payload: any;
}
interface DisableFilter {
  type: typeof DISABLE_FILTER;
  payload: any;
}

interface GetViews {
  type: typeof GET_VIEWS;
  payload: any;
}
interface GetViewFilters {
  type: typeof GET_VIEW_FILTERS;
  payload: any;
}
interface ClearViewFilters {
  type: typeof CLEAR_VIEW_FILTERS;
}
interface AddView {
  type: typeof ADD_VIEW;
  payload: any;
}
interface UpdateView {
  type: typeof UPDATE_VIEW;
  payload: any;
}

interface DeleteView {
  type: typeof DELETE_VIEW;
  payload: string;
}
interface SetSelectedView {
  type: typeof SET_SELECTED_VIEW;
  payload: string;
}
interface ClearSelectedView {
  type: typeof CLEAR_SELECTED_VIEW;
}
interface ClearViews {
  type: typeof CLEAR_VIEWS;
}

interface SetDefaultView {
  type: typeof SET_DEFAULT_VIEW;
  payload: any;
}

export type ViewsActionTypes =
  | GetViews
  | GetViewFilters
  | ClearViewFilters
  | AddView
  | UpdateView
  | DeleteView
  | SetSelectedView
  | ClearSelectedView
  | ClearViews
  | SetDefaultView
  | EnableFilter
  | DisableFilter;
