import gql from 'graphql-tag';

export const FETCH_ACCOUNTS_QUERY = gql`
  query {
    accounts {
      id
      kind
      selfLink
      name
      filters {
        id
        name
      }
    }
  }
`;
export const FETCH_ACCOUNTS_ONLY_QUERY = gql`
  query {
    accounts {
      id
      name
    }
  }
`;
export const ACCOUNT_RECOMMENDED_FILTERS_QUERY = gql`
  query accountRecommendedFilters($accountId: ID!) {
    accountRecommendedFilters(accountId: $accountId) {
      recommended {
        name
        description
        type
        field
        added
        appliedToAll
        relatedTo
      }
    }
  }
`;
