import {
  AuditActionTypes,
  START_AUDIT,
  RESULT_TYPE,
  AUDIT_RESULT,
  AUDIT_BASE,
  SET_FAIL_COUNT,
} from './types';

export const setAuditStart = (status: any): AuditActionTypes => {
  return {
    type: START_AUDIT,
    payload: status,
  };
};

export const setResultType = (type: string): AuditActionTypes => {
  return {
    type: RESULT_TYPE,
    payload: type,
  };
};
export const setAuditResult = (result: any): AuditActionTypes => {
  return {
    type: AUDIT_RESULT,
    payload: result,
  };
};
export const setAuditBase = (auditAccPropView: any): AuditActionTypes => {
  return {
    type: AUDIT_BASE,
    payload: auditAccPropView,
  };
};
export const setFailCount = (failCount: any): AuditActionTypes => {
  return {
    type: SET_FAIL_COUNT,
    payload: failCount,
  };
};
