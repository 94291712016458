import {
  GET_FILTER_LIST,
  SET_FILTER_LIST,
  ADD_FILTER,
  ADD_FILTER_CHECKED_ITEM,
  AccountListActionTypes,
  CLEAR_FILTER,
  SET_SELECTED_FILTER,
} from '../actions/filterList/types';

export interface Item {
  id: string;
  kind: string;
  selfLink: string;
  accountId: string;
  name: string;
  type: string;
  created: string;
  updated: string;
  parentLink: {
    type: string;
    href: string;
  };
  lowercaseDetails: {
    field: string;
  };
}
export interface FilterState {
  filters: Item[];
  enabled: string[];
  selectedFilter: any;
}

const initialState: FilterState = {
  filters: [],
  enabled: [],
  selectedFilter: null,
};

export default (state = initialState, action: AccountListActionTypes) => {
  switch (action.type) {
    case GET_FILTER_LIST:
      return {
        ...state,
        filters: action.payload,
      };
    case SET_FILTER_LIST:
      return {
        ...state,
        filters: action.payload,
      };
    case ADD_FILTER:
      return {
        ...state,
        filters: [...state.filters, action.payload],
      };
    case ADD_FILTER_CHECKED_ITEM:
      return {
        ...state,
        enabled: [...state.enabled, action.payload],
      };

    case CLEAR_FILTER:
      return {
        ...state,
        filters: [],
      };
    case SET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter: action.payload,
      };
    default:
      return state;
  }
};
