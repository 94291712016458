import React, { FC } from 'react';
import { Form } from 'react-bootstrap';
import { motion } from 'framer-motion';
import TypeToggler from './TypeToggler';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import FilterDetails from './FilterDetails';

interface Props {
  filterType: string;
  setFilterType: (filterType: string) => void;
  checkedDevices: string[];
  setCheckedDevices: any;
  showPageNumber: number;
}

const LimitDeviceTraffic: FC<Props> = ({
  filterType,
  setFilterType,
  checkedDevices,
  setCheckedDevices,
  showPageNumber,
}): JSX.Element | null => {
  const devices = ['Desktop', 'Tablet', 'Mobile'];
  const { selectedFilter } = useSelector(
    (state: RootState) => state.filterList,
    shallowEqual
  );
  if (!selectedFilter || selectedFilter.label !== 'Limit Device Traffic')
    return null;
  const filterCheckboxChangeHandler = (e: any) => {
    if (checkedDevices.includes(e.target.id)) {
      const filteredDevice = checkedDevices.filter(
        (device) => device !== e.target.id
      );
      setCheckedDevices(filteredDevice);
    } else {
      setCheckedDevices([...checkedDevices, e.target.id]);
    }
  };
  if (showPageNumber === 1)
    return <FilterDetails details={selectedFilter.description} />;

  if (showPageNumber === 2)
    return (
      <motion.div initial={{ x: 400 }} animate={{ x: 0 }} exit={{ x: -400 }}>
        <TypeToggler
          filterType={filterType}
          setFilterType={setFilterType}
          fieldTypeLabel='Filter Type'
          leftButtonLabel='Include'
          rightButtonLabel='Exclude'
        />

        <div className='field-and-button'>
          <h6 className='filter-label'>Device</h6>
          <div className='device-checkbox-area'>
            {devices.map((device: string) => (
              <Form.Check
                custom
                type='checkbox'
                label={device}
                id={device}
                key={device}
                onChange={filterCheckboxChangeHandler}
              />
            ))}
          </div>
        </div>
      </motion.div>
    );
  return null;
};

export default LimitDeviceTraffic;
