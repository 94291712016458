import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SkeletonLoader = () => {
  return (
    <SkeletonTheme color='lightblue' highlightColor='grey'>
      <div className='flex'>
        <div className='sk-prop'>
          <Skeleton height={32} width='64%' />
          <Skeleton height={32} width='78%' />
          <Skeleton height={32} width='47%' />
          <Skeleton height={32} width='59%' />
          <Skeleton height={32} width='89%' />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SkeletonLoader;
