import { LOADING, LoadingActionTypes } from '../actions/loader/types';

interface LoaderState {
  loading: boolean;
  type: string | null;
}

const initialState: LoaderState = {
  loading: false,
  type: null,
};

export default (state = initialState, action: LoadingActionTypes) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload ? true : false,
        type: action.payload ? action.payload : null,
      };

    default:
      return state;
  }
};
