import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../../store';

import {
  GET_PROPERTY,
  ADD_PROPERTY,
  UPDATE_PROPERTY,
  PropertyActionTypes,
  SET_SELECTED_PROPERTY,
  CLEAR_SELECTED_PROPERTY,
  CLEAR_PROPERTIES,
} from './types';

// getting properties by account id
export const getPropertyAction = (
  data: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  // dispatching GET_PROPERTY with the filtered property list
  data &&
    data.properties &&
    dispatch({ type: GET_PROPERTY, payload: data.properties });
};

export const addPropertyAction = (property: any): PropertyActionTypes => {
  return {
    type: ADD_PROPERTY,
    payload: property,
  };
};

export const updatePropertyAction = (property: any): PropertyActionTypes => {
  return {
    type: UPDATE_PROPERTY,
    payload: property,
  };
};

export const setSelectedPropertyAction = (id: string): PropertyActionTypes => {
  return {
    type: SET_SELECTED_PROPERTY,
    payload: id,
  };
};

export const clearSelectedPropertyAction = (): PropertyActionTypes => {
  return {
    type: CLEAR_SELECTED_PROPERTY,
  };
};
export const clearPropertiesAction = (): PropertyActionTypes => {
  return { type: CLEAR_PROPERTIES };
};
