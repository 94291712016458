import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import InputField from '../../textField/InputField';
import { motion } from 'framer-motion';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import FilterDetails from './FilterDetails';

interface Props {
  directoryPagePaths: string[];
  setDirectoryPagePaths: any;
  showPageNumber: number;
}

const SimplifyLongPagePath: FC<Props> = ({
  directoryPagePaths,
  setDirectoryPagePaths,
  showPageNumber,
}): JSX.Element | null => {
  const { selectedFilter } = useSelector(
    (state: RootState) => state.filterList,
    shallowEqual
  );
  if (!selectedFilter || selectedFilter.label !== 'Simplify Long Page Path')
    return null;

  const handleAddMoreIps = () => {
    setDirectoryPagePaths([...directoryPagePaths, '']);
  };
  const inputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    directoryPagePaths[index] = e.target.value;
    setDirectoryPagePaths([...directoryPagePaths]);
  };
  if (showPageNumber === 1)
    return <FilterDetails details={selectedFilter.description} />;

  if (showPageNumber === 2)
    return (
      <motion.div
        initial={{ x: 400 }}
        animate={{ x: 0 }}
        exit={{ x: -400 }}
        transition={{ type: 'spring' }}>
        <div className='field-and-button SimplifyLongPagePath'>
          <h6 className='filter-label'>Directory page path</h6>
          {directoryPagePaths.map((directoryPagePath: string, index: number) => {
            return (
              <InputField
                type='text'
                value={directoryPagePath}
                onChange={(e: any) => inputChangeHandler(e, index)}
                key={index}
                className='ip-field'
              />
            );
          })}

          <Button variant='secondary' onClick={handleAddMoreIps}>
            +
          </Button>
          <p className='text-uppercase'>
            you can not use slash before directory page path
          </p>
        </div>
      </motion.div>
    );
  return null;
};

export default SimplifyLongPagePath;
