import React, { FC, Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import InputField from '../../textField/InputField';
import { motion } from 'framer-motion';
import TypeToggler from './TypeToggler';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import FilterDetails from './FilterDetails';

interface Props {
  filterType: string;
  setFilterType: (filterType: string) => void;
  ipType: string;
  setIpType: (ipType: string) => void;
  singleIps: any;
  setSingleIps: any;
  ipRanges: any;
  setIpRanges: any;
  showPageNumber: number;
}

const LimitIpAddress: FC<Props> = ({
  filterType,
  setFilterType,
  ipType,
  setIpType,
  singleIps,
  setSingleIps,
  ipRanges,
  setIpRanges,
  showPageNumber,
}): JSX.Element | null => {
  const [error, setError] = useState('');
  const { selectedFilter } = useSelector(
    (state: RootState) => state.filterList,
    shallowEqual
  );
  if (!selectedFilter || selectedFilter.label !== 'Limit IP Traffic') return null;
  const handleAddMoreIps = () => {
    if (ipType === 'Single IP') return setSingleIps([...singleIps, '']);
    if (ipType === 'IP Range')
      return setIpRanges([...ipRanges, { from: '', to: '' }]);
  };
  function validateIPaddress(ipaddress: string) {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipaddress
      )
    ) {
      return true;
    }

    return false;
  }
  const singleIpInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    let value = e.target.value;
    singleIps[index] = value;
    setSingleIps([...singleIps]);
    if (value.length >= 6 && !validateIPaddress(value)) {
      setError('Incomplete Or Invalid IP address!');
    } else {
      setError('');
    }
  };
  const ipRangeInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value;
    ipRanges[index] = { ...ipRanges[index], [e.target.name]: value };
    setIpRanges([...ipRanges]);
    if (value.length >= 6 && !validateIPaddress(value)) {
      setError('Incomplete Or Invalid IP address!');
    } else {
      setError('');
    }
  };

  let result: any;
  if (showPageNumber === 1) {
    result = <FilterDetails details={selectedFilter.description} />;
  }

  if (showPageNumber === 2) {
    result = (
      <motion.div initial={{ x: 400 }} animate={{ x: 0 }} exit={{ x: -400 }}>
        <TypeToggler
          filterType={filterType}
          setFilterType={setFilterType}
          fieldTypeLabel='Filter Type'
          leftButtonLabel='Include'
          rightButtonLabel='Exclude'
        />
        <TypeToggler
          filterType={ipType}
          setFilterType={setIpType}
          fieldTypeLabel='Add IP'
          leftButtonLabel='Single IP'
          rightButtonLabel='IP Range'
        />

        <div className='field-and-button'>
          {ipType === 'Single IP' &&
            singleIps.map((ip: string, index: number) => {
              return (
                <InputField
                  type='text'
                  placeholder='Enter IP Address'
                  value={ip}
                  onChange={(e: any) => singleIpInputHandler(e, index)}
                  key={index}
                  className='ip-field'
                />
              );
            })}
          {ipType === 'IP Range' &&
            ipRanges.map((ip: any, index: number) => {
              return (
                <Fragment key={index}>
                  <InputField
                    type='text'
                    name='from'
                    placeholder='IP Range From'
                    value={ip.from}
                    onChange={(e: any) => ipRangeInputHandler(e, index)}
                    className='ip-field'
                  />
                  <InputField
                    type='text'
                    name='to'
                    placeholder='IP Range To'
                    value={ip.to}
                    onChange={(e: any) => ipRangeInputHandler(e, index)}
                    className='ip-field'
                  />
                </Fragment>
              );
            })}

          <Button variant='secondary' onClick={handleAddMoreIps}>
            +
          </Button>
          <p style={{ fontSize: 14, color: '#ff0000cf' }}>{error}</p>
        </div>
      </motion.div>
    );
  }
  return (
    <>
      {result}
      {/* <div className='right-modal-bottom-btn'>
        <Button variant='primary' className='back-btn' onClick={backButtonHandler}>
          Back
        </Button>
        <SaveOrNextButton />
      </div> */}
    </>
  );
};

export default LimitIpAddress;
