import { combineReducers } from 'redux';
import propertyReducer from './propertyReducer';
import modalReducer from './modalReducer';
import accountListReducer from './accountListReducer';
import viewsListReducer from './viewsListReducer';
import filterListReducer from './filterListReducer';
import userReducer from './userReducer';
import { USER_LOGGED_OUT } from '../actions/user/types';
import loaderReducer from './loaderReducer';
import menuReducer from './menuReducer';
import auditReducer from './auditReducer';
import reportingReducer from './reporting';

const appReducer = combineReducers({
  properties: propertyReducer,
  modal: modalReducer,
  accountList: accountListReducer,
  viewsList: viewsListReducer,
  filterList: filterListReducer,
  user: userReducer,
  loader: loaderReducer,
  menu: menuReducer,
  audit: auditReducer,
  reporting: reportingReducer,
});

const rootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === USER_LOGGED_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
