import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from '../../store';

import {
  GET_FILTER_LIST,
  SET_FILTER_LIST,
  ADD_FILTER,
  AccountListActionTypes,
  CLEAR_FILTER,
  SET_SELECTED_FILTER,
  SET_RECOMMENDED_FILTER,
} from './types';

// get filter list by account id
export const getFilterListAction = (
  acccountId: string,
  filters: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  const accountFilter =
    filters && filters.find((filter: any) => filter.id === acccountId);

  // dispatching GET_FILTER_LIST with the filtered filter list
  accountFilter
    ? dispatch({
        type: GET_FILTER_LIST,
        payload: accountFilter.filters,
      })
    : dispatch({ type: CLEAR_FILTER });
};
export const setFilterListAction = (filters: object): AccountListActionTypes => {
  return {
    type: SET_FILTER_LIST,
    payload: filters,
  };
};
export const addFilterAction = (filter: object): AccountListActionTypes => {
  return {
    type: ADD_FILTER,
    payload: filter,
  };
};

export const clearFilterAction = () => {
  return {
    type: CLEAR_FILTER,
  };
};
export const setSelectedFilterAction = (selectedFilter: any) => {
  return {
    type: SET_SELECTED_FILTER,
    payload: selectedFilter,
  };
};
export const setRecommendedFilterAction = (filters: any[]) => {
  return {
    type: SET_RECOMMENDED_FILTER,
    payload: filters,
  };
};
