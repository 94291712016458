import React, { FC } from 'react';
import AuditList from '../components/analytics/Audit/AuditList';
import AuditChart from '../components/analytics/Audit/AuditChart';
import { motion } from 'framer-motion';
import BeforeAuditReport from '../components/analytics/Audit/BeforeAuditReport';

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const Analytics: FC = (): JSX.Element => {
  return (
    <motion.div
      className='analytics-main'
      initial='initial'
      animate='in'
      exit='out'
      variants={pageVariants}>
      <div className='auditResult'>
        <AuditChart />
        <AuditList />
        <BeforeAuditReport />
      </div>
    </motion.div>
  );
};
export default Analytics;
