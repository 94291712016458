import React, { FC } from 'react';
import { openModalAction } from '../../../actions/modal/modalAction';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col } from 'react-bootstrap';
import './styles.css';
import GenerateReportModal from '../../popUp/GenerateReportModal';
import { RootState } from '../../../reducers';

const BeforeAuditReport: FC = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const handleAuditStart = () => {
    dispatch(openModalAction('generateReport'));
  };
  const { auditResults } = useSelector((state: RootState) => state.audit);
  return auditResults.length === 0 ? (
    <>
      <GenerateReportModal />
      <div className='row'>
        <div className='inner-wrapper'>
          <div className='audit-heading-btn'>
            <h2 className='audit-heading'>Audit Report</h2>
            <Button className='audit-report' onClick={handleAuditStart}>
              Generate Audit report
            </Button>
          </div>
          <div className='row'>
            <Col sm={5}>
              <div className='audit-img-container pr-0'>
                <img
                  src='/image/audit-status.png'
                  alt=''
                  style={{ width: '100%' }}
                />
              </div>
            </Col>
            <Col sm={7} className='p-0'>
              <div className='details-container'>
                <h3 className='audit-status-heading'>Audit Status</h3>
                <p className='audit-status-text'>
                  Ultrices ultrices in at netus blandit est rhoncus. Venenatis, non
                  pretium viverra consectetur tincidunt. Penatibus consectetur
                  tempus sem ut nibh sit. Cursus a, ultrices vel dolor aenean enim
                  quis amet. Quis placerat facilisis pharetra felis id feugiat
                  eleifend pretium sed. Cras etiam tincidunt ac, egestas volutpat
                  nisl. Nunc at venenatis orci id a sed tortor quis orci mattis vel
                  egestas sit.
                </p>
              </div>
            </Col>
          </div>

          <div className='row'>
            <Col sm={7}>
              <div className='details-container'>
                <h3 className='audit-status-heading'>Audit Details</h3>
                <p className='audit-status-text p-0'>
                  Ultrices ultrices in at netus blandit est rhoncus. Venenatis, non
                  pretium viverra consectetur tincidunt. Penatibus consectetur
                  tempus sem ut nibh sit. Cursus a, ultrices vel dolor aenean enim
                  quis amet. Quis placerat facilisis pharetra felis id feugiat
                  eleifend pretium sed. Cras etiam tincidunt ac, egestas volutpat
                  nisl. Nunc at venenatis orci id a sed tortor quis orci mattis vel
                  egestas sit.
                </p>
              </div>
            </Col>
            <Col sm={5}>
              <div className='audit-img-container pl-0'>
                <img
                  src='/image/audit-details.png'
                  alt=''
                  style={{ width: '100%' }}
                />
              </div>
            </Col>
          </div>

          <p className='audit-details-bottom-text'>
            Duis est, convallis augue vestibulum nec urna scelerisque. Tellus amet
            mattis molestie pulvinar consectetur enim. Iaculis sed phasellus a
            viverra non eu nulla. Cursus nisl quis quis sodales ipsum. Quam risus
            massa, amet maecenas risus. Dictumst vitae tristique cursus augue dolor
            nunc pellentesque.
          </p>
          <div className='audit-list-img-container'>
            <img src='/image/audit-list.png' alt='' />
          </div>
          <p className='audit-details-bottom-text'>
            Duis est, convallis augue vestibulum nec urna scelerisque. Tellus amet
            mattis molestie pulvinar consectetur enim. Iaculis sed phasellus a
            viverra non eu nulla. Cursus nisl quis quis sodales ipsum. Quam risus
            massa, amet maecenas risus. Dictumst vitae tristique cursus augue dolor
            nunc pellentesque.
          </p>
        </div>
      </div>
    </>
  ) : null;
};

export default BeforeAuditReport;
