import React, { FC, useEffect } from 'react';
import './style.css';
import { useDispatch, useSelector, shallowEqual, batch } from 'react-redux';
import {
  getAccountAction,
  setSelectedAccountAction,
} from '../../../actions/accountList/accountListAction';
import { RootState } from '../../../store';
import { useQuery } from '@apollo/react-hooks';
import { getFilterListAction } from '../../../actions/filterList/filterListAction';
import {
  clearPropertiesAction,
  clearSelectedPropertyAction,
} from '../../../actions/propertyList/propertyAction';
import {
  clearSelectedViewAction,
  clearViewFiltersAction,
  clearViewsAction,
} from '../../../actions/viewsList/viewsListAction';
import { FETCH_ACCOUNTS_QUERY } from '../../../graphql/account';
import Loader from '../../Loader';
import { setLoading } from '../../../actions/loader/loaderAction';
import Select from 'react-select';

const AccountSelector: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { loading, data } = useQuery(FETCH_ACCOUNTS_QUERY);
  const loadingStatus = useSelector(
    (state: RootState) => state.loader.loading,
    shallowEqual
  );
  useEffect(() => {
    if (loading) dispatch(setLoading('account'));
    if (loadingStatus) dispatch(setLoading(null));
    // eslint-disable-next-line
  }, [loading]);
  // getting accounts on load
  useEffect(() => {
    if (!loading) dispatch(getAccountAction(data.accounts));
    // eslint-disable-next-line
  }, [data]);

  // get all account from redux which is grabbed from api
  const accounts = useSelector(
    (state: RootState) => state.accountList.accountList,
    shallowEqual
  );

  const {
    accountList: { selectedAccount },
    properties: { selectedProperty },
    viewsList: { selectedView },
    user: { user },
  } = useSelector((state: RootState) => state, shallowEqual);

  useEffect(() => {
    if (!loading)
      dispatch(getFilterListAction(selectedAccount.value, data.accounts));
    // eslint-disable-next-line
  }, [selectedAccount]);

  // select account input change handler
  const accountChangeHandler = (newValue: any, actionMeta: any) => {
    // setFilter({ ...filter, [actionMeta.name]: newValue.label });
    dispatch(setSelectedAccountAction(newValue));
    if (selectedProperty) dispatch(clearSelectedPropertyAction());
    if (selectedView) dispatch(clearSelectedViewAction());
    batch(() => {
      dispatch(clearPropertiesAction());
      dispatch(clearViewsAction());
      dispatch(clearViewFiltersAction());
    });
  };

  const options: any = [];
  accounts &&
    accounts.forEach((account: any) => {
      options.push({ label: account.name, value: account.id });
    });

  return (
    <div className='account-top'>
      {user && loading && <Loader />}
      <h2 className='selectorTitle'>Account</h2>

      {accounts ? (
        <Select
          options={options}
          onChange={accountChangeHandler}
          defaultValue={selectedAccount}
          styles={{
            // Fixes the overlapping problem of the component
            menu: (provided) => ({ ...provided, zIndex: 23 }),
            option: (prev) => ({
              ...prev,
              ':active': {
                ...prev[':active'],
                backgroundColor: 'var(--analytics-theme-color);',
              },
            }),
          }}
        />
      ) : (
        <Select
          isDisabled={true}
          isLoading={loading}
          classNamePrefix='react-select'
        />
      )}
    </div>
  );
};
export default AccountSelector;
