import { ModalActionTypes, OPEN_MODAL, CLOSE_MODAL } from './types';

export const openModalAction = (type: string): ModalActionTypes => {
  return {
    type: OPEN_MODAL,
    payload: type,
  };
};

export const closeModalAction = (): ModalActionTypes => {
  return {
    type: CLOSE_MODAL,
  };
};
