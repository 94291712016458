import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { openModalAction } from '../../../actions/modal/modalAction';
import { useDispatch, useSelector } from 'react-redux';
import FullDoughnutChart from './FullDoughnutChart';
import { RootState } from '../../../reducers';
import GenerateReportModal from '../../popUp/GenerateReportModal';

const AuditChart: FC = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { auditResults, auditBase } = useSelector(
    (state: RootState) => state.audit
  );

  const handleAuditStart = () => {
    dispatch(openModalAction('generateReport'));
  };
  return auditResults.length > 0 ? (
    <>
      <GenerateReportModal />
      <div className='row'>
        <div className='audit-status-details'>
          <div className='overview-chart col-sm-6'>
            <div className='heading-and-indicator'>
              <h2 className='card-heading'>Audit Status</h2>
              <div className='indicator'>
                <span className='success'>Success</span>
                <span className='not-satisfied'>Not Satisfied</span>
                <span className='failed'>Failed</span>
              </div>
            </div>
            <FullDoughnutChart />
          </div>
          <div className='col-sm-6'>
            <div className='audit-details'>
              <div className='heading-and-button'>
                <h2 className='card-heading'>Audit Details</h2>
                <Button className='change-report' onClick={handleAuditStart}>
                  Change Report
                </Button>
              </div>
              <div className='audit-main-details'>
                <div className='single-details'>
                  <h2>Account Name</h2>
                  <h3>{auditBase && auditBase.account.label}</h3>
                </div>
                <div className='single-details'>
                  <h2>Property Name</h2>
                  <h3>{auditBase && auditBase.property.label}</h3>
                </div>
                <div className='single-details'>
                  <h2>View Name</h2>
                  <h3>{auditBase && auditBase.view.label}</h3>
                </div>
                <div className='single-details'>
                  <h2>Region</h2>
                </div>
                <div className='single-details'>
                  <h2>URL</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default AuditChart;
