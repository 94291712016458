import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual, batch } from 'react-redux';
import { RootState } from '../../../store';
import { useQuery } from '@apollo/react-hooks';

import {
  clearViewFiltersAction,
  getViewsAction,
  setSelectedViewAction,
} from '../../../actions/viewsList/viewsListAction';

import { FETCH_VIEWS_QUERY } from '../../../graphql/view';
import { setLoading } from '../../../actions/loader/loaderAction';
import Select from 'react-select';

const ViewSelector: FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const {
    accountList: { selectedAccount },
    properties: { selectedProperty },
    viewsList: { selectedView, views },
    loader: { loading },
  } = useSelector((state: RootState) => state, shallowEqual);

  // gql query to fetch views
  const { loading: loadingViews, data: viewsData } = useQuery(
    FETCH_VIEWS_QUERY,
    {
      variables: {
        accountId: selectedAccount.value,
        propertyId: selectedProperty.value,
      },
      skip: !selectedAccount || !selectedProperty,
      onCompleted: () => {
        viewsData && dispatch(getViewsAction(viewsData));
      },
    }
  );

  useEffect(() => {
    if (loadingViews) {
      dispatch(setLoading('view'));
    }
    if (loading) {
      dispatch(setLoading(null));
    }
    // eslint-disable-next-line
  }, [loadingViews]);

  const options: any = [];
  views &&
    views.forEach((view: any) => {
      options.push({ label: view.name, value: view.id });
    });
  // view select input change handler
  const changeHandler = (newValue: any) => {
    batch(() => {
      dispatch(setSelectedViewAction(newValue));
      dispatch(clearViewFiltersAction());
    });
  };
  return (
    <div className='view-selector'>
      {/* {(loadingProperties || loadingViews) && <Loader />} */}

      <h2 className='selectorTitle'>Views</h2>
      {!loading && views.length > 0 ? (
        <Select
          options={options}
          onChange={changeHandler}
          defaultValue={selectedView}
          value={selectedView}
        />
      ) : (
        <Select
          isDisabled={true}
          isLoading={loadingViews}
          classNamePrefix='react-select'
        />
      )}
    </div>
  );
};

export default ViewSelector;
