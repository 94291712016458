export const START_AUDIT = 'START_AUDIT';
export const RESULT_TYPE = 'RESULT_TYPE';
export const AUDIT_RESULT = 'AUDIT_RESULT';
export const AUDIT_BASE = 'AUDIT_BASE';
export const SET_FAIL_COUNT = 'SET_FAIL_COUNT';
interface SetAuditStart {
  type: typeof START_AUDIT;
  payload: string;
}
interface SetResultType {
  type: typeof RESULT_TYPE;
  payload: string;
}

interface SetAuditResult {
  type: typeof AUDIT_RESULT;
  payload: any;
}
interface SetAuditBase {
  type: typeof AUDIT_BASE;
  payload: any;
}
interface SetFailCount {
  type: typeof SET_FAIL_COUNT;
  payload: any;
}
export type AuditActionTypes =
  | SetAuditStart
  | SetResultType
  | SetAuditResult
  | SetAuditBase
  | SetFailCount;
