import React, { FC } from 'react';
import { motion } from 'framer-motion';
import TypeToggler from './TypeToggler';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import FilterDetails from './FilterDetails';

interface Props {
  letterCase: string;
  setLetterCase: any;
  showPageNumber: number;
}

const CampaignTypeFilter: FC<Props> = ({
  letterCase,
  setLetterCase,
  showPageNumber,
}): JSX.Element | null => {
  const { selectedFilter } = useSelector(
    (state: RootState) => state.filterList,
    shallowEqual
  );
  if (!selectedFilter || !selectedFilter.name.includes('Case Convert'))
    return null;
  if (showPageNumber === 1)
    return <FilterDetails details={selectedFilter.description} />;
  if (showPageNumber === 2)
    return (
      <motion.div initial={{ x: 400 }} animate={{ x: 0 }} exit={{ x: -400 }}>
        <TypeToggler
          filterType={letterCase}
          setFilterType={setLetterCase}
          fieldTypeLabel='Case convert'
          leftButtonLabel='Uppercase'
          rightButtonLabel='Lowercase'
        />
      </motion.div>
    );
  return null;
};

export default CampaignTypeFilter;
