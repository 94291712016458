import React, { FC } from 'react';
import './style.css';
import PropViewTabContent from './PropViewTabContent';

const AccountProperty: FC = (): JSX.Element => {
  return (
    <div className='account-property'>
      <PropViewTabContent />
    </div>
  );
};

export default AccountProperty;
