import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { RootState } from '../../reducers';
import { closeModalAction } from '../../actions/modal/modalAction';
import { Form, Button } from 'react-bootstrap';
import PopUp from '.';
import CustomToast from '../CustomToast';
import { FETCH_ACCOUNTS_ONLY_QUERY } from '../../graphql/account';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_PROPERTIES_QUERY } from '../../graphql/property';
import AccountTop from '../main-content/accountSelector';
import ViewSelector from '../main-content/viewSelector';
import PropertySelector from '../main-content/propertySelector';
import { setAuditStart, setAuditBase } from '../../actions/audit/auditAction';
import Loader from '../Loader';

const GenerateReportModal = () => {
  const [toast, setToast] = useState({ type: '', message: '' });
  const modalType = useSelector((state: RootState) => state.modal.modalType);

  const [newProperty, setNewProperty] = useState({
    account: '',
    property: '',
    view: '',
    websiteUrl: '',
  });
  const dispatch = useDispatch();
  let viewId: any;
  const { auditStarted } = useSelector((state: RootState) => state.audit);

  const {
    accountList: { selectedAccount },
    properties: { selectedProperty },
    viewsList: { selectedView, defaultView, views },
    loader: { loading },
  } = useSelector((state: RootState) => state, shallowEqual);

  viewId = selectedView || defaultView;

  // view modal submit handler
  const submitHandler = (e: any) => {
    e.preventDefault();
    // console.log('Submited Data: ', property);
  };

  // const changeHandler = (e: any) => {
  //   setNewProperty({ ...newProperty, [e.target.name]: e.target.value });
  // };

  const closeHandler = () => {
    dispatch(closeModalAction());
    setNewProperty({ account: '', property: '', view: '', websiteUrl: '' });
  };
  const { data: accountData } = useQuery(FETCH_ACCOUNTS_ONLY_QUERY);
  // gql query to fetch properties
  const { data: propertiesData } = useQuery(FETCH_PROPERTIES_QUERY, {
    variables: { accountId: selectedAccount.value },
    skip: !selectedAccount,
  });
  const [customSelectList, setCustomSelectList] = useState([
    {
      label: 'Account',
      name: 'account',
      options: [],
    },
    {
      label: 'Property',
      name: 'property',
      options: [],
    },
  ]);

  const formatAndSetOption = (fetchedDatas: any, dataType: string) => {
    const formattedList: any = [];

    fetchedDatas.forEach((fetchedData: any) => {
      formattedList.push({ label: fetchedData.name, value: fetchedData.id });
    });
    const newList = customSelectList.filter((item) => {
      if (item.name === dataType) {
        return (item.options = formattedList);
      }
    });
    setCustomSelectList(newList);
  };
  // when account resolveed, formatting the list options
  // so it can be use with customSelect Component
  // Then setting as state
  useEffect(() => {
    accountData && formatAndSetOption(accountData.accounts, 'account');
    propertiesData && formatAndSetOption(propertiesData.properties, 'property');
    // eslint-disable-next-line
  }, [accountData]);

  // string boolean to boolean type ie:'true' => true
  // const parseBoolean = (value: string): boolean | string => {
  //   if (value === 'true' || value === 'false') {
  //     return JSON.parse(value);
  //   }
  //   return value;
  // };
  // const [reportState, setReportState] = useState({});

  // const selectChangeHandler = (newValue: any, actionMeta: any) => {
  //   const parsedValue = parseBoolean(newValue.label);
  //   setReportState({ ...reportState, [actionMeta.name]: parsedValue });
  // };
  const handleGenerateAuditReport = () => {
    if (selectedAccount && selectedProperty && viewId) {
      if (typeof viewId === 'string') {
        const foundView = views.find((view: any) => view.id === viewId);
        viewId = { label: foundView.name, value: foundView.id };
      }
      dispatch(setAuditStart(true));
      dispatch(
        setAuditBase({
          account: selectedAccount,
          property: selectedProperty,
          view: viewId,
        })
      );
    }
  };

  let generatedReport;
  // const { websiteUrl } = newProperty;
  if (modalType === 'generateReport') {
    generatedReport = (
      <PopUp modalTitle='Generate Report' closeHandler={closeHandler}>
        <Form onSubmit={submitHandler}>
          <AccountTop />
          <PropertySelector />
          <ViewSelector />
          {/* {customSelectList.map((customSelect) => (
            <CustomSelect
              key={customSelect.name}
              label={customSelect.label}
              name={customSelect.name}
              options={customSelect.options}
              error={false}
              changeHandler={selectChangeHandler}
              values={reportState}
            />
          ))} */}

          {/* <InputField
            type='text'
            name='websiteUrl'
            placeholder='Enter Website URL'
            value={websiteUrl}
            onChange={changeHandler}
            label='Website URL'
          /> */}
          <div className='right-modal-bottom-btn'>
            <Button
              variant='primary'
              className='back-btn'
              onClick={closeHandler}
            >
              Back
            </Button>
            <Button
              disabled={loading}
              variant='primary'
              type='submit'
              onClick={handleGenerateAuditReport}
            >
              Generate
            </Button>
          </div>
          {auditStarted && <Loader />}
        </Form>
      </PopUp>
    );
  }
  return (
    <>
      <CustomToast
        header={toast.type}
        message={toast.message}
        setToast={setToast}
      />
      {generatedReport}
    </>
  );
};

export default GenerateReportModal;
