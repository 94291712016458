import React, { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import FilterDetails from './FilterDetails';

const SearchAndReplaceType: FC = (): JSX.Element | null => {
  const { selectedFilter } = useSelector(
    (state: RootState) => state.filterList,
    shallowEqual
  );
  if (
    !selectedFilter ||
    !['SEARCH_AND_REPLACE', 'ADVANCED'].includes(selectedFilter.type)
  )
    return null;

  return <FilterDetails details={selectedFilter.description} />;
};

export default SearchAndReplaceType;
