import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual, batch } from 'react-redux';
import { RootState } from '../../../reducers';
import { closeModalAction } from '../../../actions/modal/modalAction';
import { Form, Button } from 'react-bootstrap';
import PopUp from '..';
import { useMutation } from '@apollo/react-hooks';
import CustomToast from '../../CustomToast';
import Loader from '../../Loader';
import { ADD_FILTER_MUTATION } from '../../../graphql/filter';
import {
  addFilterAction,
  setSelectedFilterAction,
} from '../../../actions/filterList/filterListAction';
import FilterSelect from '../../textField/FilterSelect';
import { filterFields } from './filterFields';
import { AnimatePresence } from 'framer-motion';
import LimitIpAddress from './LimitIpAddress';
import LimitDeviceTraffic from './LimitDeviceTraffic';
import RestrictDevHostname from './RestrictDevHostname';
import CampaignTypeFilter from './CampaignTypeFilter';
import SimplifyLongPagePath from './SimplyLongPagePath';
import TargetCountriesReporting from './TargetCountriesReporting';
import ExcludeSpamSource from './ExcludeSpamSource';
import LimitHostnames from './LimitHostnames';
import SearchAndReplaceType from './SearchAndReplaceType';
import PropertySelector from '../../main-content/propertySelector';

const AddOrUpdateFilter = () => {
  const [toast, setToast] = useState({ type: '', message: '' });
  const [showPageNumber, setShowPageNumber] = useState(1);
  const [filterType, setFilterType] = useState<string>('');
  const [ipType, setIpType] = useState<string>('Single IP');
  const [letterCase, setLetterCase] = useState<string>('');
  const [singleIps, setSingleIps] = useState<string[]>(['']);
  const [ipRanges, setIpRanges] = useState([{ from: '', to: '' }]);
  const [hostnames, setHostnames] = useState<string[]>(['']);
  const [devHostnames, setDevHostnames] = useState([
    { protocol: 'https', host: '' },
  ]);
  const [directoryPagePaths, setDirectoryPagePaths] = useState<string[]>(['']);
  const [accessType, setAccessType] = useState<string>('');
  const [countries, setCountries] = useState([]);
  const [spamList, setSpamList] = useState<string[]>(['']);
  const [checkedDevices, setCheckedDevices] = useState<string[]>([]);
  const [checkedPrefix, setCheckedPrefix] = useState<string[]>([]);

  const resetState = () => {
    setShowPageNumber(1);
    setSingleIps(['']);
    setIpRanges([{ from: '', to: '' }]);
    setHostnames(['']);
    setDevHostnames([{ protocol: 'https', host: '' }]);
    setDirectoryPagePaths(['']);
    setCountries([]);
    setSpamList(['']);
    setCheckedDevices([]);
    setCheckedPrefix([]);
    setLetterCase('');
    setFilterType('');
  };
  const dispatch = useDispatch();

  const {
    accountList: { selectedAccount },
    properties: { selectedProperty },
    filterList: { selectedFilter },
    modal: { modalType },
  } = useSelector((state: RootState) => state, shallowEqual);

  const [maxPage, setMaxPage] = useState(2);
  useEffect(() => {
    console.log(selectedFilter);
    resetState();
    if (
      selectedFilter &&
      (selectedFilter.type === 'SEARCH_AND_REPLACE' ||
        selectedFilter.type === 'ADVANCED')
    ) {
      setMaxPage(1);
    }
  }, [selectedFilter]);
  let expressionValue: any;
  let expressionList: any;

  const [addFilterMutation, { loading: loadingAddFilter }] = useMutation(
    ADD_FILTER_MUTATION,
    {
      onError(err) {
        err.graphQLErrors[0] &&
          setToast({ type: 'fail', message: err.graphQLErrors[0].message });
      },
    }
  );

  const showNext = () => setShowPageNumber(showPageNumber + 1);
  const showPrev = () => setShowPageNumber(showPageNumber - 1);

  // Filter modal submit handler
  const submitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (maxPage > showPageNumber) return showNext();
    if (selectedFilter.label === 'Limit Hostnames(Domains)') {
      expressionList = hostnames;
    }
    if (selectedFilter.label === 'Limit IP Traffic') {
      if (ipType === 'Single IP') {
        expressionValue = singleIps[0].replaceAll('.', '\\.');
      }
      if (ipType === 'IP Range') {
        const findLast = (ip: string) => ip.slice(ip.lastIndexOf('.') + 1);
        const findRest = (ip: string) => ip.slice(0, ip.lastIndexOf('.'));

        const formatted =
          findRest(ipRanges[0].from) +
          '.[' +
          findLast(ipRanges[0].from) +
          '-' +
          findLast(ipRanges[0].to) +
          ']';
        expressionValue = formatted.replaceAll('.', '\\.');
      }
    }
    if (selectedFilter.label === 'Limit Countries') {
      expressionList = countries.map(
        (country: { label: string; value: string }) => country.label
      );
    }
    if (selectedFilter.label === 'Limit Device Traffic') {
      expressionList = checkedDevices.map((device: string) =>
        device.toLowerCase()
      );
    }
    if (selectedFilter.label === 'Exclude Crawler and Referral Spam') {
      expressionList = spamList;
    }
    let filterName: string = selectedFilter.value;
    if (modalType === 'addFilter') {
      if (selectedFilter.appliedToAll) {
        filterName = `AmdeasAll_${selectedFilter.value}`;
      } else {
        filterName = `Amdeas${selectedProperty.label}_${selectedFilter.value}`;
      }
      addFilterMutation({
        variables: {
          accountId: selectedAccount.value,
          filterName,
          type:
            filterType.toUpperCase() ||
            letterCase.toUpperCase() ||
            accessType.toUpperCase(),
          expressionValue,
          expressionList,
        },
        update(_, result) {
          setToast({ type: 'success', message: 'Filter Added' });
          dispatch(addFilterAction(result.data.addFilter));
          dispatch(closeModalAction());
          resetState();
          dispatch(setSelectedFilterAction(null));
        },
      });
    }
  };

  const selectChangeHandler = (newValue: any, actionMeta: any) => {
    dispatch(setSelectedFilterAction(newValue));
  };

  const closeHandler = () => {
    batch(() => {
      dispatch(setSelectedFilterAction(null));
      dispatch(closeModalAction());
    });
    resetState();
  };
  const backButtonHandler = () => {
    if (showPageNumber > 1) return showPrev();
    batch(() => {
      dispatch(setSelectedFilterAction(null));
      dispatch(closeModalAction());
    });

    resetState();
  };
  const SaveOrNextButton = () => {
    if (selectedFilter && maxPage <= showPageNumber) {
      return (
        <Button
          variant='primary'
          type='submit'
          disabled={selectedFilter.label === ''}
        >
          Save
        </Button>
      );
    }
    return (
      <Button
        variant='primary'
        type='button'
        onClick={showNext}
        disabled={
          selectedFilter &&
          !selectedFilter.appliedToAll &&
          !selectedProperty.label
        }
      >
        Next
      </Button>
    );
  };

  if (!['addFilter', 'editFilter'].includes(modalType)) return null;
  const modalTitle = modalType === 'addFilter' ? 'Add Filter' : 'Edit Filter';
  return (
    <Fragment>
      <CustomToast
        header={toast.type}
        message={toast.message}
        setToast={setToast}
      />
      {loadingAddFilter && <Loader />}
      <PopUp modalTitle={modalTitle} closeHandler={closeHandler}>
        <Form onSubmit={submitHandler}>
          <FilterSelect
            options={filterFields.options}
            changeHandler={selectChangeHandler}
          />
          {selectedFilter && !selectedFilter.appliedToAll && (
            <PropertySelector />
          )}

          <AnimatePresence>
            <div className='option-and-fields-area'>
              <LimitIpAddress
                filterType={filterType}
                setFilterType={setFilterType}
                ipType={ipType}
                setIpType={setIpType}
                singleIps={singleIps}
                setSingleIps={setSingleIps}
                ipRanges={ipRanges}
                setIpRanges={setIpRanges}
                showPageNumber={showPageNumber}
              />

              <LimitDeviceTraffic
                filterType={filterType}
                setFilterType={setFilterType}
                checkedDevices={checkedDevices}
                setCheckedDevices={setCheckedDevices}
                showPageNumber={showPageNumber}
              />
              <RestrictDevHostname
                checkedPrefix={checkedPrefix}
                setCheckedPrefix={setCheckedPrefix}
                devHostnames={devHostnames}
                setDevHostnames={setDevHostnames}
                showPageNumber={showPageNumber}
              />
              <LimitHostnames
                setAccessType={setAccessType}
                setHostnames={setHostnames}
                accessType={accessType}
                hostnames={hostnames}
                showPageNumber={showPageNumber}
              />
              <CampaignTypeFilter
                letterCase={letterCase}
                setLetterCase={setLetterCase}
                showPageNumber={showPageNumber}
              />
              <SimplifyLongPagePath
                directoryPagePaths={directoryPagePaths}
                setDirectoryPagePaths={setDirectoryPagePaths}
                showPageNumber={showPageNumber}
              />
              <TargetCountriesReporting
                accessType={accessType}
                setAccessType={setAccessType}
                countries={countries}
                setCountries={setCountries}
                showPageNumber={showPageNumber}
              />
              <ExcludeSpamSource
                spamList={spamList}
                setSpamList={setSpamList}
                showPageNumber={showPageNumber}
                filterType={filterType}
                setFilterType={setFilterType}
              />
              <SearchAndReplaceType />
            </div>
          </AnimatePresence>
          <div className='right-modal-bottom-btn'>
            <Button
              variant='primary'
              className='back-btn'
              onClick={backButtonHandler}
            >
              Back
            </Button>
            <SaveOrNextButton />
          </div>
        </Form>
      </PopUp>
    </Fragment>
  );
};

export default AddOrUpdateFilter;
