import React, { FC } from 'react';
import AccountProperty from './account-peoperty';
import AccountSelector from './accountSelector';
import PropertySelector from './propertySelector';
import ViewSelector from './viewSelector';
import { Col, Row } from 'react-bootstrap';

const MainContent: FC = (): JSX.Element => (
  <>
    <Row className='siteAccount'>
      <Col sm={4}>
        <AccountSelector />
      </Col>
      <Col sm={4}>
        <PropertySelector />
      </Col>
      <Col sm={4}>
        <ViewSelector />
      </Col>
    </Row>

    <AccountProperty />
  </>
);

export default MainContent;
