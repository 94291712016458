import { ReportingActionTypes, SET_RESULT } from '../actions/reporting/types';

const initialState: any = {
  userBehavior: {
    audiencePageData: {},
    existingVsNewUsersOutput: {},
    audienceActionData: {},
    timelineData: {},
    languageData: {},
  },
};

export default (state = initialState, action: ReportingActionTypes) => {
  switch (action.type) {
    case SET_RESULT:
      return {
        ...state,
        userBehavior: action.payload,
      };
    default:
      return state;
  }
};
