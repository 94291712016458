import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import './style.css';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  openModalAction,
  closeModalAction,
} from '../../actions/modal/modalAction';

import CustomToast from '../CustomToast';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Loader from '../Loader';
import {
  deleteViewAction,
  setDefaultViewAction,
} from '../../actions/viewsList/viewsListAction';
import DeleteModal from '../popUp/DeleteModal';
import { SET_DEFAULT_VIEW_MUTATION } from '../../graphql/view';

const ViewDropdown = () => {
  const dispatch = useDispatch();
  const [toast, setToast] = useState({ type: '', message: '' });

  const {
    accountList: { selectedAccount },
    properties: { selectedProperty },
    viewsList: { selectedView, defaultView },
  } = useSelector((state) => state, shallowEqual);

  // Modal
  const showHandler = (type) => {
    if (!selectedAccount)
      return setToast({
        type: 'fail',
        message: 'Select An Account First',
      });
    if (type === 'add') {
      dispatch(openModalAction('addView'));
    }

    if (!selectedProperty && type === 'edit')
      return setToast({ type: 'fail', message: 'Select A Property First' });

    if (!selectedView && type === 'edit')
      return setToast({ type: 'fail', message: 'Select A View First' });

    if (type === 'edit') {
      dispatch(openModalAction('editView'));
    }
    if (type === 'enableDisableFilter') {
      dispatch(openModalAction('enableDisableFilter'));
    }
  };
  const [setDefaultView, { loading: setDefaultViewLoading }] = useMutation(
    SET_DEFAULT_VIEW_MUTATION,
    {
      variables: {
        accountId: selectedAccount.value,
        propertyId: selectedProperty.value,
        defaultProfileId: selectedView.value,
      },
      onError(err) {
        err.graphQLErrors[0] &&
          setToast({ type: 'fail', message: err.graphQLErrors[0].message });
      },
      update(_, result) {
        setToast({
          type: 'success',
          message: `set default view successfull`,
        });
        dispatch(
          setDefaultViewAction(
            selectedProperty.value,
            result.data.updateProperty.defaultProfileId
          )
        );
      },
    }
  );
  const setDefaultViewHandler = () => {
    if (!selectedView)
      return setToast({
        type: 'fail',
        message: 'No Selected View',
      });
    setDefaultView();
  };
  const [deleteView, { loading: deleteLoading }] = useMutation(
    DELETE_VIEW_MUTATION,
    {
      variables: {
        accountId: selectedAccount.value,
        propertyId: selectedProperty.value,
        viewId: selectedView.value,
      },
      onError(err) {
        err.graphQLErrors[0] &&
          setToast({ type: 'fail', message: err.graphQLErrors[0].message });
      },
      update(_, result) {
        setToast({ type: 'success', message: result.data.deleteView });
        dispatch(deleteViewAction(selectedView.value));
        dispatch(closeModalAction());
      },
    }
  );

  const deleteHandler = () => {
    if (!selectedAccount || !selectedProperty)
      return setToast({
        type: 'fail',
        message: 'No View Selected',
      });
    dispatch(openModalAction('deleteView'));
  };

  return (
    <>
      {(deleteLoading || setDefaultViewLoading) && <Loader />}

      <CustomToast
        header={toast.type}
        message={toast.message}
        setToast={setToast}
      />
      <Dropdown>
        <Dropdown.Toggle alignright='true' className='viewDetailDropdown'>
          <FontAwesomeIcon icon={faEllipsisV} />
        </Dropdown.Toggle>

        <Dropdown.Menu className='dropdownMenu'>
          {selectedView.value !== defaultView.defaultViewId && (
            <Dropdown.Item onClick={setDefaultViewHandler}>
              Make the view as default
            </Dropdown.Item>
          )}
          <Dropdown.Item onClick={() => showHandler('enableDisableFilter')}>
            Enable / Disable filters
          </Dropdown.Item>
          <Dropdown.Item onClick={() => showHandler('add')}>
            Add View
          </Dropdown.Item>
          <Dropdown.Item onClick={() => showHandler('edit')}>
            Edit View
          </Dropdown.Item>
          <Dropdown.Item onClick={deleteHandler}>Delete View</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <DeleteModal
        idToDelete={selectedView.value}
        deleteMutation={deleteView}
      />
    </>
  );
};

export default ViewDropdown;

const DELETE_VIEW_MUTATION = gql`
  mutation deleteView($accountId: ID!, $propertyId: String!, $viewId: ID!) {
    deleteView(accountId: $accountId, propertyId: $propertyId, viewId: $viewId)
  }
`;
