import React, { FC } from 'react';
import { Col } from 'react-bootstrap';
import SidePanel from './components/sidePanel';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './ApolloProvider';
import { Route, Switch, useLocation } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import SearchConsole from './pages/SearchConsole';
import Analytics from './pages/Analytics';
import Login from './components/Login';
import { AnimatePresence } from 'framer-motion';
import Adword from './pages/Adword';
import UserManagement from './pages/UserManagement';
import Profile from './components/Profile';
import AnalyticsTrash from './pages/AnalyticsTrash';
import AnalyticsSetup from './pages/AnalyticsSetup';
import NotFoundPage from './pages/404';
const App: FC = (): JSX.Element => {
  const location = useLocation();

  return (
    <ApolloProvider client={client}>
      <div className='flex'>
        <Login />
        <SidePanel />
        <Col className='content-area'>
          <Profile />
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.key}>
              <Route exact path='/' component={Dashboard} />
              <Route exact path='/analytics/trash' component={AnalyticsTrash} />
              <Route path='/setup' component={AnalyticsSetup} />
              <Route path='/audit' component={Analytics} />
              <Route path='/search-console' component={SearchConsole} />
              <Route path='/google-ads' component={Adword} />
              <Route path='/user-management' component={UserManagement} />
              <Route component={NotFoundPage} />
            </Switch>
          </AnimatePresence>
        </Col>
      </div>
    </ApolloProvider>
  );
};
export default App;
