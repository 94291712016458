import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual, batch } from 'react-redux';
import './styles.css';
import { RootState } from '../../../store';
import { useQuery } from '@apollo/react-hooks';
import {
  getPropertyAction,
  setSelectedPropertyAction,
} from '../../../actions/propertyList/propertyAction';
import {
  clearSelectedViewAction,
  setDefaultViewAction,
  clearViewsAction,
  clearViewFiltersAction,
} from '../../../actions/viewsList/viewsListAction';
import { Property } from '../../../reducers/propertyReducer';
import { FETCH_PROPERTIES_QUERY } from '../../../graphql/property';
import { setLoading } from '../../../actions/loader/loaderAction';
import Select from 'react-select';

const PropertySelector: FC = (): JSX.Element => {
  const dispatch = useDispatch();

  const {
    accountList: { selectedAccount },
    properties: { selectedProperty, property },
    viewsList: { selectedView },
    loader: { loading },
  } = useSelector((state: RootState) => state, shallowEqual);

  // gql query to fetch properties
  const { loading: loadingProperties, data: propertiesData } = useQuery(
    FETCH_PROPERTIES_QUERY,
    {
      variables: { accountId: selectedAccount.value },
      skip: !selectedAccount,
    }
  );

  useEffect(() => {
    // dispatching get property action with the seleced account

    if (loadingProperties) {
      dispatch(setLoading('property'));
    }
    if (loading) {
      dispatch(setLoading(null));
    }
    if (!loadingProperties) dispatch(getPropertyAction(propertiesData));
    // eslint-disable-next-line
  }, [selectedAccount, propertiesData]);

  useEffect(() => {
    // finding and dispatching default view action
    if (selectedProperty) {
      const selectedPropertyDetails = property.find(
        (prop: Property) => prop.id === selectedProperty.value
      );
      selectedPropertyDetails.defaultProfileId
        ? dispatch(
            setDefaultViewAction(
              selectedProperty.value,
              selectedPropertyDetails.defaultProfileId
            )
          )
        : dispatch(setDefaultViewAction(selectedProperty.value, ''));
    }
    // eslint-disable-next-line
  }, [selectedProperty.value]);
  const options: any = [];
  property &&
    property.forEach((prop: any) => {
      options.push({ label: prop.name, value: prop.id });
    });
  const prevProp = useSelector(
    (state: RootState) => state.properties.selectedProperty
  );

  // property select input change handler
  const propertyChangeHandler = (newValue: any, actionMeta: any) => {
    if (prevProp.value !== newValue.value) {
      batch(() => {
        dispatch(setSelectedPropertyAction(newValue));
        dispatch(clearViewsAction());
        dispatch(clearViewFiltersAction());
      });
      if (selectedView) dispatch(clearSelectedViewAction());
    }
  };

  return (
    <div className='property-selector'>
      {/* {(loadingProperties || loadingViews) && <Loader />} */}

      <h2 className='selectorTitle'>Property</h2>
      {!loading && property.length > 0 ? (
        <Select
          options={options}
          onChange={propertyChangeHandler}
          defaultValue={selectedProperty}
          value={selectedProperty}
          isClearable={false}
        />
      ) : (
        // <Select isDisabled={true} isLoading={loading && type === 'property'} />
        <Select
          isDisabled={true}
          isLoading={loadingProperties}
          classNamePrefix='react-select'
        />
      )}
    </div>
  );
};

export default PropertySelector;
