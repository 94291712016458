import React, { useState, FC, useEffect } from 'react';
import { Dropdown, Col } from 'react-bootstrap';
import { useSelector, useDispatch, batch, shallowEqual } from 'react-redux';
import { RootState } from '../../../store';
import {
  openModalAction,
  closeModalAction,
} from '../../../actions/modal/modalAction';
import AuditFixerModal from '../../popUp/AuditFixerModal';
import Axios from 'axios';
import { motion } from 'framer-motion';
import {
  setAuditStart,
  setAuditResult,
  setFailCount,
} from '../../../actions/audit/auditAction';
import FailRate from './FaileRate';
import ListItem from './ListItem';
import filters from './filterList';

const AuditList: FC = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState('Sort By Priority');
  const [clickedItem, setClickedItem] = useState<any>(null);
  const [auditData, setAuditData] = useState<any>(null);
  const [filteredResult, setFilteredResult] = useState<any>([]);

  const {
    accountList: { selectedAccount },
    properties: { selectedProperty },
    viewsList: { selectedView, defaultView },
    audit: { auditStarted, auditResults, resultType, failCounter },
  } = useSelector((state: RootState) => state, shallowEqual);

  const viewId = selectedView.value || defaultView;

  const fetchAuditResult = async () => {
    const res = await Axios.get(
      `${process.env.REACT_APP_API_HOST_URL}/api/analytics/audit/${selectedAccount.value}/${selectedProperty.value}/${viewId}`,
      { headers: { Authorization: localStorage.getItem('jwtToken') } }
    );
    console.log(res.data);
    return res.data;
  };

  const fixerFunction = (name: string): void => {
    dispatch(openModalAction(name));
    const foundItem = filters.find((content) => content.auditItem === name);
    foundItem && setClickedItem(foundItem);
  };

  useEffect(() => {
    const auditResultFetcher = async () => {
      const auditResult = await fetchAuditResult();
      setAuditData(auditResult);
      batch(() => {
        dispatch(setAuditStart(false));
        dispatch(closeModalAction());
      });
    };
    if (auditStarted) auditResultFetcher();
    // eslint-disable-next-line
  }, [auditStarted]);

  // when audit data resolved setting audit results
  useEffect(() => {
    const audits: any = [];
    auditData &&
      filters.forEach((content) => {
        const auditResults = Object.keys(auditData);
        auditResults.forEach((auditResults) => {
          Object.keys(auditData[auditResults]).forEach((auditItem) => {
            if (auditItem === content.name) {
              content.isSet = auditData[auditResults][auditItem].status;
              audits.push(content);
            }
          });
        });
      });
    // remove all duplicate audit results
    const auditSet: any = new Set();
    audits.filter((el: any) => {
      const duplicate = auditSet.has(el.name);
      auditSet.add(el);
      return !duplicate;
    });
    // set to array
    const auditSetToArr: any[] = Array.from(auditSet);

    if (auditSetToArr.length > 0) {
      sortByPriority(auditSetToArr);
      console.log('auditSetToArr', auditSetToArr);
      dispatch(setAuditResult(auditSetToArr));
    }
    // Calculating Failed count and storing to redux store
    let viewCount = { fail: 0, total: 0 };
    let accessCount = { fail: 0, total: 0 };
    let filterCount = { fail: 0, total: 0 };
    let propertyCount = { fail: 0, total: 0 };
    let totalCount = { fail: 0, total: 0 };
    auditSetToArr.forEach((audit) => {
      if (audit.type === 'property') {
        propertyCount = { ...propertyCount, total: propertyCount.total + 1 };
        if (audit.isSet === 'Failed') {
          propertyCount = { ...propertyCount, fail: propertyCount.fail + 1 };
        }
      }
      if (audit.type === 'view') {
        viewCount = { ...viewCount, total: viewCount.total + 1 };
        if (audit.isSet === 'Failed') {
          viewCount = { ...viewCount, fail: viewCount.fail + 1 };
        }
      }
      if (audit.type === 'filter') {
        filterCount = { ...filterCount, total: filterCount.total + 1 };
        if (audit.isSet === 'Failed') {
          filterCount = { ...filterCount, fail: filterCount.fail + 1 };
        }
      }
    });
    totalCount = {
      fail: propertyCount.fail + viewCount.fail + filterCount.fail,
      total: propertyCount.total + viewCount.total + filterCount.total,
    };
    totalCount.total &&
      dispatch(
        setFailCount({
          access: accessCount,
          property: propertyCount,
          view: viewCount,
          filter: filterCount,
          all: totalCount,
        })
      );
    // eslint-disable-next-line
  }, [auditData]);

  const sortByPriority = (data: any): void => {
    data.sort((a: any, b: any) => {
      if (a.isSet.length > b.isSet.length) {
        return 1;
      }
      if (b.isSet.length > a.isSet.length) {
        return -1;
      }
      return 0;
    });
    setFilteredResult(data);
  };
  const sortBySuccess = (data: any): void => {
    data.sort((a: any, b: any) => {
      if (a.isSet.length > b.isSet.length) {
        return -1;
      }
      if (b.isSet.length > a.isSet.length) {
        return 1;
      }
      return 0;
    });
    setFilteredResult(data);
  };
  const filterResult = (filterType: string): void => {
    let filteredData = auditResults.filter(
      (filter: any) => filter.type === filterType
    );
    if (sortBy === 'Sort By Priority') {
      return sortByPriority(filteredData);
    }
    if (sortBy === 'Sort By Success') {
      return sortBySuccess(filteredData);
    }
  };
  useEffect(() => {
    filterResult(resultType);
    // eslint-disable-next-line
  }, [resultType, sortBy]);

  return auditResults.length > 0 ? (
    <Col sm={12} className='auditList-wrapper'>
      <motion.div className='audit-lists'>
        <FailRate />
        <div className='list-head'>
          <Dropdown onSelect={(e: any) => setSortBy(e)}>
            <Dropdown.Toggle as='div' id='sort-by-priority'>
              {sortBy}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                eventKey='Sort By Priority'
                onClick={() => sortByPriority(auditResults)}
              >
                Sort By Priority
              </Dropdown.Item>
              <Dropdown.Item
                eventKey='Sort By Success'
                onClick={() => sortBySuccess(auditResults)}
              >
                Sort By Success
              </Dropdown.Item>
              <Dropdown.Item eventKey='Something else'>
                Something else
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <h6>Total item: {failCounter[resultType].total}</h6>
        </div>

        {filteredResult.length === 0
          ? auditResults.map((result: any, index: number) => {
              if (!result.isSet) return null;

              return (
                <ListItem
                  key={index}
                  status={result.isSet}
                  fixerFunc={fixerFunction}
                  label={result.auditItem}
                  index={index}
                />
              );
            })
          : filteredResult.map((content: any, index: number) => {
              if (!content.isSet) return null;
              return (
                <ListItem
                  key={index}
                  status={content.isSet}
                  fixerFunc={fixerFunction}
                  label={content.auditItem}
                  index={index}
                />
              );
            })}
      </motion.div>

      <AuditFixerModal item={clickedItem} />
    </Col>
  ) : null;
};

export default AuditList;
