import React, { FC } from 'react';
import { motion } from 'framer-motion';
import TypeToggler from './TypeToggler';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import InputField from '../../textField/InputField';
import { Button } from 'react-bootstrap';
import FilterDetails from './FilterDetails';

interface Props {
  hostnames: any;
  setHostnames: any;
  accessType: string;
  setAccessType: any;
  showPageNumber: number;
}
const LimitHostnames: FC<Props> = ({
  hostnames,
  setHostnames,
  accessType,
  setAccessType,
  showPageNumber,
}): JSX.Element | null => {
  const { selectedFilter } = useSelector(
    (state: RootState) => state.filterList,
    shallowEqual
  );
  if (!selectedFilter || selectedFilter.label !== 'Limit Hostnames(Domains)')
    return null;

  const handleAddHostname = () => {
    setHostnames([...hostnames, '']);
  };

  const inputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    hostnames[index] = e.target.value;
    setHostnames([...hostnames]);
  };
  if (showPageNumber === 1)
    return <FilterDetails details={selectedFilter.description} />;

  if (showPageNumber === 2)
    return (
      <motion.div initial={{ x: 400 }} animate={{ x: 0 }} exit={{ x: -400 }}>
        <TypeToggler
          filterType={accessType}
          setFilterType={setAccessType}
          fieldTypeLabel='Access Type'
          leftButtonLabel='Allow'
          leftButtonValue='Include'
          rightButtonLabel='Restrict'
          rightButtonValue='Exclude'
        />
        <div className='field-and-button LimitHostnames'>
          <h6 className='filter-label'>Hostname</h6>
          {hostnames.map((hostname: any, index: number) => {
            return (
              <InputField
                type='text'
                value={hostname}
                onChange={(e: any) => inputHandler(e, index)}
                key={index}
                className='ip-field'
              />
            );
          })}
          <Button variant='secondary' onClick={handleAddHostname}>
            +
          </Button>
        </div>
      </motion.div>
    );
  return null;
};

export default LimitHostnames;
