import React, { FC, Fragment, useEffect, useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import Chart from 'react-google-charts';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import DetailsTable from './DetailsTable';
import DeviceDetails from './DeviceDetails';
import GeoChart from './GeoChart';

const MoreDetailsUser: FC = (): JSX.Element | null => {
  const {
    userBehavior: {
      deviceData: deviceDatas,
      languageData: languageDatas,
      countryData,
    },
  } = useSelector((state: RootState) => state.reporting, shallowEqual);
  const {
    viewsList: { selectedView },
    loader: { loading, type: loadingType },
  } = useSelector((state: RootState) => state, shallowEqual);

  const [deviceDt, setDeviceDt] = useState({
    desktop: { users: 0, newUsers: 0 },
    mobile: { users: 0, newUsers: 0 },
    tablet: { users: 0, newUsers: 0 },
  });
  const [langChart, setLangChart] = useState([
    ['Language', 'Users', 'New Users'],
  ]);
  const [notEnoughData, setNotEnoughData] = useState(true);

  useEffect(() => {
    deviceDatas &&
      setDeviceDt({
        desktop: {
          users: parseInt(deviceDatas[0].metricValue[0]?.value ?? 0),
          newUsers: parseInt(deviceDatas[1].metricValue[0]?.value ?? 0),
        },
        mobile: {
          users: parseInt(deviceDatas[0].metricValue[1]?.value ?? 0),
          newUsers: parseInt(deviceDatas[1].metricValue[1]?.value ?? 0),
        },
        tablet: {
          users: deviceDatas[0].metricValue[2]?.value ?? 0,
          newUsers: deviceDatas[1].metricValue[2]?.value ?? 0,
        },
      });
  }, [deviceDatas]);

  const deviceData = {
    labels: ['desktop', 'mobile', 'tablet'],
    datasets: [
      {
        label: 'First dataset',
        data: [
          deviceDt.desktop.users + deviceDt.desktop.newUsers,
          deviceDt.mobile.users + deviceDt.mobile.newUsers,
          deviceDt.tablet.users + deviceDt.tablet.newUsers,
        ],
        backgroundColor: ['green', 'yellow', 'orange'],
      },
    ],
  };

  const reducer = (accumulator: any, currentValue: any) =>
    accumulator.count + currentValue.count;

  useEffect(() => {
    const tempLangData =
      languageDatas.users &&
      languageDatas.users.map((user: any, i: number) => {
        return [user.propertyName, user.count, languageDatas.newUsers[i].count];
      });
    console.log(tempLangData);
    if (tempLangData && tempLangData.length) {
      setLangChart([['Language', 'Users', 'New Users'], ...tempLangData]);
      setNotEnoughData(false);
    } else {
      setNotEnoughData(true);
    }
  }, [languageDatas]);

  const deviceOptions = {
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 10,
      },
    },
  };

  return selectedView.value && !loading && loadingType !== 'reporting-api' ? (
    <Fragment>
      <h2>Let's learn a bit more about your users!</h2>
      <div className='user-details'>
        <div className='langauge'>
          <h6>Language breakdown</h6>
          {/* <Bar data={languageData} options={languageOptions} /> */}
          {notEnoughData ? (
            <div style={{ height: 300 }} className='notEnoughData-lang'>
              Not Enoguh Data
            </div>
          ) : (
            <Chart
              width={'100%'}
              height={'300px'}
              chartType='BarChart'
              loader={<div>Loading Chart</div>}
              data={langChart}
              options={{
                chartArea: { width: '50%' },
                isStacked: true,
                orientation: 'vertical',
              }}
              // For tests
              rootProps={{ 'data-testid': '3' }}
            />
          )}

          <DetailsTable detailsOf='Language' data={languageDatas} />
        </div>

        <div className='country'>
          <h6>Country breakdown</h6>
          <GeoChart data={countryData} />
          <DetailsTable detailsOf='Country' data={countryData} />
        </div>

        <div className='device'>
          <h6>What device are people using?</h6>
          <div className='doughnut-chart'>
            <Doughnut data={deviceData} options={deviceOptions} />
            <DeviceDetails detailsOf='Device' data={deviceDt} />
          </div>
        </div>
      </div>
    </Fragment>
  ) : null;
};

export default MoreDetailsUser;
