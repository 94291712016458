import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
interface Props {
  filterType: string;
  setFilterType: (filterType: string) => void;
  fieldTypeLabel: string;
  leftButtonLabel: string;
  leftButtonValue?: string;
  rightButtonLabel: string;
  rightButtonValue?: string;
}
const TypeToggler: FC<Props> = ({
  filterType,
  setFilterType,
  fieldTypeLabel,
  leftButtonLabel,
  rightButtonLabel,
  leftButtonValue,
  rightButtonValue,
}): JSX.Element => {
  return (
    <div className='filter-type'>
      <h6 className='filter-label'>{fieldTypeLabel}</h6>
      <div className='filter-type-btn'>
        <Button
          className={`include-btn filter-type-btn ${
            (filterType === leftButtonLabel && 'active') ||
            (filterType === leftButtonValue && 'active')
          }`}
          onClick={() =>
            setFilterType(leftButtonValue ? leftButtonValue : leftButtonLabel)
          }
          variant='primary'>
          {leftButtonLabel}
        </Button>
        <Button
          className={`exclude-btn filter-type-btn ${
            (filterType === rightButtonLabel && 'active') ||
            (filterType === rightButtonValue && 'active')
          }`}
          onClick={() =>
            setFilterType(rightButtonValue ? rightButtonValue : rightButtonLabel)
          }
          variant='primary'>
          {rightButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default TypeToggler;
