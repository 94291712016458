import React, { FC, Fragment, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import PopUp from '..';
import { Form } from 'react-bootstrap';
import { closeModalAction } from '../../../actions/modal/modalAction';
import { checkPrefix } from '../../../utils/filterPrefixChecker';
import ActiveFilters from '../../filter-views/admin-filters/ActiveFilters';
import {
  disableFilterAction,
  enableFilterAction,
} from '../../../actions/viewsList/viewsListAction';
import {
  ADD_VIEW_FILTER_MUTATION,
  DELETE_VIEW_FILTER_MUTATION,
} from '../../../graphql/view';
import { useMutation } from '@apollo/react-hooks';
import plusIcon from '@iconify/icons-entypo/plus';
import Loader from '../../Loader';
import Icon from '@iconify/react';

const EnableDisableFilters: FC = () => {
  const [toast, setToast] = useState<any>('');
  const dispatch = useDispatch();

  const modalType = useSelector(
    (state: RootState) => state.modal.modalType,
    shallowEqual
  );
  const { selectedAccount } = useSelector((state: RootState) => state.accountList);
  const { selectedProperty } = useSelector((state: RootState) => state.properties);
  const { selectedView, viewFilters } = useSelector(
    (state: RootState) => state.viewsList
  );

  const [addViewFilterMutation, { loading: addViewFiltersLoading }] = useMutation(
    ADD_VIEW_FILTER_MUTATION,
    {
      onError: (err) => {
        err.graphQLErrors[0] &&
          setToast({ type: 'fail', message: err.graphQLErrors[0].message });
      },
    }
  );

  const [
    deleteViewFilterMutation,
    { loading: deleteViewFiltersLoading },
  ] = useMutation(DELETE_VIEW_FILTER_MUTATION, {
    onError: (err) => {
      err.graphQLErrors[0] &&
        setToast({ type: 'fail', message: err.graphQLErrors[0].message });
    },
  });

  const handleEnabledFilter = (filter: any): void => {
    deleteViewFilterMutation({
      variables: {
        accountId: selectedAccount.value,
        propertyId: selectedProperty.value,
        viewId: selectedView.value,
        profileFilterLinkId: `${selectedView.value}:${filter.id}`,
      },

      update: (_, result) => {
        setToast({
          type: 'success',
          message: 'View Disabled successfully',
        });
        dispatch(disableFilterAction(filter));
      },
    });
  };

  const handleDisabledFilter = (filter: any) => {
    addViewFilterMutation({
      variables: {
        accountId: selectedAccount.value,
        propertyId: selectedProperty.value,
        viewId: selectedView.value,
        filterId: filter.id,
      },

      update: (_, result) => {
        setToast({
          type: 'success',
          message: 'Filter Enabled Successfully',
        });
        dispatch(enableFilterAction(filter));
      },
    });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
  };

  const closeHandler = () => {
    dispatch(closeModalAction());
  };
  if (modalType !== 'enableDisableFilter') return null;

  return (
    <Fragment>
      {(addViewFiltersLoading || deleteViewFiltersLoading) && <Loader />}
      <PopUp modalTitle='Filter Details' closeHandler={closeHandler}>
        <Form onSubmit={submitHandler}>
          <div className='account-filters'>
            <div className='enabled-filters'>
              <h3 className='inner-heading'>Enabled Filters</h3>
              <ActiveFilters handleFilterClick={handleEnabledFilter} showIcon />
            </div>
            <div className='disabled-filters'>
              <h3 className='inner-heading'>Disabled Filters</h3>
              <ul>
                {selectedView &&
                  viewFilters.inactive.map((filter: any) => {
                    const { name, prefix } = checkPrefix(
                      filter.name,
                      selectedProperty.label
                    );
                    if (!prefix) return null;
                    return (
                      <li
                        key={filter.id}
                        className={!prefix ? 'not-from-amdeas' : ''}
                        onClick={() => handleDisabledFilter(filter)}>
                        <Icon icon={plusIcon} style={{ marginRight: 8 }} />
                        {name}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </Form>
      </PopUp>
    </Fragment>
  );
};

export default EnableDisableFilters;
