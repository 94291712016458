import React, { FC, useEffect, useState } from 'react';
import Chart from 'react-google-charts';
interface Props {
  data: any;
}
const GeoChart: FC<Props> = ({ data }): JSX.Element => {
  const [countries, setCountries] = useState([['Country', 'Popularity']]);
  useEffect(() => {
    const tempCountry =
      data &&
      data.users.map((user: any, i: number) => {
        return [user.propertyName, user.count + data.newUsers[i].count];
      });
    tempCountry && setCountries([...countries, ...tempCountry]);
  }, [data]);
  return (
    <div>
      <Chart
        width={'100%'}
        height={'300px'}
        chartType='GeoChart'
        data={countries}
        // Note: you will need to get a mapsApiKey for your project.
        // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
        mapsApiKey='YOUR_KEY_HERE'
        rootProps={{ 'data-testid': '1' }}
      />
    </div>
  );
};

export default GeoChart;
