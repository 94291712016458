import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import './style.css';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { openModalAction } from '../../actions/modal/modalAction';

import CustomToast from '../CustomToast';
import AddOrUpdateProperty from '../popUp/AddOrUpdateProperty';

const PropertyDropdown = () => {
  const dispatch = useDispatch();
  const [toast, setToast] = useState({ type: '', message: '' });

  const {
    accountList: { selectedAccount },
    properties: { selectedProperty },
  } = useSelector((state) => state, shallowEqual);

  // Modal
  const propertyHandler = (type) => {
    if (!selectedAccount)
      return setToast({ type: 'fail', message: 'Select An Account First' });
    if (type === 'add') {
      return dispatch(openModalAction('addProperty'));
    }
    if (selectedAccount && type === 'edit' && !selectedProperty)
      return setToast({ type: 'fail', message: 'Select A Property First' });
    if (type === 'edit') {
      dispatch(openModalAction('editProperty'));
    }
  };

  return (
    <>
      <CustomToast
        header={toast.type}
        message={toast.message}
        setToast={setToast}
      />
      <Dropdown>
        <Dropdown.Toggle alignright='true' className='viewDetailDropdown'>
          <FontAwesomeIcon icon={faEllipsisV} />
        </Dropdown.Toggle>

        <Dropdown.Menu className='dropdownMenu'>
          <Dropdown.Item as='button' onClick={() => propertyHandler('add')}>
            Add Property
          </Dropdown.Item>
          <Dropdown.Item as='button' onClick={() => propertyHandler('edit')}>
            Edit Property
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <AddOrUpdateProperty />
    </>
  );
};

export default PropertyDropdown;
