import React, { FC } from 'react';
import AdminFilters from './admin-filters';
import './style.css';
import AdminViews from './admin-views';

const AdminPanel: FC = (): JSX.Element => (
  <div className='filterviews'>
    <AdminViews />
    <AdminFilters />
  </div>
);

export default AdminPanel;
