export const variants = {
  collapsed: {
    opacity: 0,
    // x: 50,
    transitionEnd: {
      display: 'none',
    },
  },
  expanded: {
    opacity: 1,
    transition: { delay: 0.05 },
    // x: 0,
    display: 'inline-block',
  },
};
export const logoVariants = {
  collapsed: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
    y: -100,
  },
  expanded: {
    opacity: 1,
    display: 'inline-block',
    y: 0,
  },
};
