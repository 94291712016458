import React, { FC } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { checkPrefix } from '../../../utils/filterPrefixChecker';
import minusIcon from '@iconify/icons-entypo/minus';
import Icon from '@iconify/react';

interface Props {
  handleFilterClick: (filter: any) => void;
  showIcon?: boolean;
  searchedFilters?: any;
  searchText?: string;
}

const ActiveFilters: FC<Props> = ({
  handleFilterClick,
  showIcon,
  searchedFilters,
  searchText,
}): JSX.Element => {
  const {
    viewsList: { viewFilters },
    properties: { selectedProperty },
  } = useSelector((state: RootState) => state, shallowEqual);
  return (
    <ul>
      {searchText
        ? searchedFilters.map((filter: any) => {
            const { name, prefix } = checkPrefix(
              filter.name,
              selectedProperty.label
            );
            return (
              <li
                key={filter.id}
                className={`active-filter ${!prefix ? 'not-from-amdeas' : ''}`}
                onClick={() => handleFilterClick({ ...filter, isActive: true })}
              >
                {showIcon && <Icon icon={minusIcon} className='minus-icon' />}
                {name}
              </li>
            );
          })
        : viewFilters.active.map((filter: any) => {
            const { name, prefix } = checkPrefix(
              filter.name,
              selectedProperty.label
            );
            return (
              <li
                key={filter.id}
                className={`active-filter ${!prefix ? 'not-from-amdeas' : ''}`}
                onClick={() => handleFilterClick({ ...filter, isActive: true })}
              >
                {showIcon && <Icon icon={minusIcon} className='minus-icon' />}
                {name}
              </li>
            );
          })}
    </ul>
  );
};

export default ActiveFilters;
