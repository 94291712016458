import React, { FC, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavItem from './NavItem';
import './style.css';
import googleAnalytics from '@iconify/icons-cib/google-analytics';
import googlesearchconsoleIcon from '@iconify/icons-simple-icons/googlesearchconsole';
import googleAds from '@iconify/icons-cib/google-ads';
import userSettingsFill from '@iconify/icons-ri/user-settings-fill';
import bxsDashboard from '@iconify/icons-bx/bxs-dashboard';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuExpand } from '../../actions/menu/menuAction';
import { RootState } from '../../reducers';
import { Icon } from '@iconify/react';
import bxsBarChartSquare from '@iconify/icons-bx/bxs-bar-chart-square';
import { Link, useLocation } from 'react-router-dom';

import bxChevronDown from '@iconify/icons-bx/bx-chevron-down';
import bxChevronUp from '@iconify/icons-bx/bx-chevron-up';

import settingsIcon from '@iconify/icons-codicon/settings';
import previewIcon from '@iconify/icons-whh/preview';
import { motion } from 'framer-motion';
import baselineDelete from '@iconify/icons-whh/delete';
import { logoVariants, variants } from './variants';

const SidePanel: FC = (): JSX.Element => {
  const location = useLocation();
  const menuExpand = useSelector((state: RootState) => state.menu.expand);
  const dispatch = useDispatch();
  const [analyticsToggle, setAnalyticsToggle] = useState(() => {
    if (['/setup', '/audit', '/analytics/trash'].includes(location.pathname))
      return true;
    return false;
  });

  const handleClick = (e: any) => {
    console.log(e);
  };
  return (
    <div className={`leftSidebar ${menuExpand ? 'maxSize' : 'minSize'}`}>
      <div className={`site-menu ${menuExpand ? '' : 'min'}`}>
        <div className='site-logo'>
          <Link to='/'>
            <Icon icon={bxsBarChartSquare} className='logo-icon' />
            <motion.span
              className='logo-text'
              variants={logoVariants}
              initial={menuExpand ? 'expanded' : 'collapsed'}
              animate={menuExpand ? 'expanded' : 'collapsed'}>
              Amdeas
            </motion.span>
          </Link>
        </div>
        <div className='menu-lists'>
          <NavItem
            linkTo='/'
            icon={bxsDashboard}
            title='Dashboard'
            onClick={handleClick}
          />
          <div className='label'>
            <label>products</label>
          </div>
          <div className='nav-toggle'>
            <button
              onClick={() => setAnalyticsToggle(!analyticsToggle)}
              className={`nav-toggle-btn ${analyticsToggle ? 'up' : 'down'}`}>
              <span className='icon-title'>
                <Icon icon={googleAnalytics} className='nav-toggle-icon' />
                <motion.span
                  variants={variants}
                  initial={menuExpand ? 'expanded' : 'collapsed'}
                  animate={menuExpand ? 'expanded' : 'collapsed'}>
                  Analytics
                </motion.span>
              </span>
              {analyticsToggle ? (
                <Icon icon={bxChevronUp} />
              ) : (
                <Icon icon={bxChevronDown} />
              )}
            </button>

            <Collapse in={analyticsToggle}>
              <div className='analytics-collapse-menu'>
                <NavItem linkTo='/setup' icon={settingsIcon} title='Setup' />
                <NavItem linkTo='/audit' icon={previewIcon} title='Audit' />
                <NavItem
                  linkTo='/analytics/trash'
                  icon={baselineDelete}
                  title='Trash'
                />
              </div>
            </Collapse>
          </div>

          <NavItem
            linkTo='/search-console'
            icon={googlesearchconsoleIcon}
            title='Search Console'
          />
          <NavItem linkTo='/google-ads' icon={googleAds} title='Google Ads' />
          <NavItem
            linkTo='/user-management'
            icon={userSettingsFill}
            title='User Management'
          />
        </div>
      </div>
      <div onClick={() => dispatch(setMenuExpand())} className='minimize'>
        <FontAwesomeIcon icon={menuExpand ? faChevronLeft : faChevronRight} />
      </div>
    </div>
  );
};
export default SidePanel;
