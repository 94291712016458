import {
  AccountListActionTypes,
  GET_ACCOUNT_LIST,
  SET_SELECTED_ACCOUNT,
} from '../actions/accountList/types';

export interface AccountList {
  id: string;
  kind: string;
  selfLink: string;
  name: string;
  permissions: {
    effective: string[];
  };
  created: string;
  updated: string;
  childLink: {
    type: string;
    href: string;
  };
}

interface AccountListState {
  accountList: null | AccountList[];
  selectedAccount: string;
}

const initialState: any = {
  accountList: null,
  selectedAccount: '',
};

export default (state = initialState, action: AccountListActionTypes) => {
  switch (action.type) {
    case GET_ACCOUNT_LIST:
      return {
        ...state,
        accountList: action.payload,
      };
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.payload,
      };
    default:
      return state;
  }
};
