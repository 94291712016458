import React, { FC, useState, useEffect } from 'react';
import { Modal, Button, Image, Row } from 'react-bootstrap';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setUserAction } from '../../actions/user/userAction';
import { RootState } from '../../reducers';
import Loader from '../Loader';

const Login: FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // get all account from redux which is grabbed from api
  const { loading, data } = useQuery(FETCH_USER_QUERY);
  const user = useSelector((state: RootState) => state.user.user);
  useEffect(() => {
    if (!loading && data) {
      setShow(false);
      dispatch(setUserAction(data.user));
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    !loading && !user ? setShow(true) : setShow(false);
  }, [loading, user]);

  return loading ? (
    <Loader />
  ) : (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
      className='login-modal'>
      <Modal.Body className='login-body'>
        <Row className='login-image-container'>
          <Image src='/image/login-img.png' />
        </Row>
        <h1>Sign In</h1>
        <p>Make a login with your google accounts</p>
        <a href={`${process.env.REACT_APP_API_HOST_URL}/api/auth/google`}>
          <Button className='login-button'>
            <div className='login-google-img'>
              <Image src='/image/google-icon.png' />
            </div>
            <span>Sign in with google</span>
          </Button>
        </a>
      </Modal.Body>
    </Modal>
  );
};
export default Login;

const FETCH_USER_QUERY = gql`
  query {
    user {
      _id
      username
      picture
      email
    }
  }
`;
