import { EXPAND, MenuActionTypes } from '../actions/menu/types';

interface MenuState {
  expand: boolean;
}

const initialState: MenuState = {
  expand: true,
};

export default (state = initialState, action: MenuActionTypes) => {
  switch (action.type) {
    case EXPAND:
      return { ...state, expand: !state.expand };

    default:
      return state;
  }
};
